<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            Clone fees
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                  v-model="additional_fee.clone_id_from"
                  label="Select a clone fee from"
                  :error="$v.additional_fee.clone_id_from.$error"
                  @change="onChangeFee"
                  :items="studentFees"
                  item-text="title"
                  item-value="id"
                  outlined
                  dense
              ></v-select>
              <span class="text-danger" v-if="$v.additional_fee.clone_id_from.$error">This field is required</span>
            </v-col>

            <v-col cols="12">
              <v-select
                  v-model="additional_fee.clone_id_to"
                  label="Select a clone fee to"
                  :error="$v.additional_fee.clone_id_to.$error"
                  :items="cloneStudentFees"
                  item-text="title"
                  item-value="id"
                  outlined
                  dense
              ></v-select>
              <span class="text-danger" v-if="$v.additional_fee.clone_id_to.$error">This field is required</span>
            </v-col>

          </v-row>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
                class="btn btn-standard cancel-btn"
                depressed
                @click="closeDialog"
            >Cancel
            </v-btn>
            <v-btn
                class="text-white ml-2 btn btn-primary"
                depressed
                @click="createAndUpdate"
                :loading="isBusy"
            >Clone
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import AdditionalFeeService from "@/core/services/fee/addtionalFee/AdditionalFeeService";

const additionalFeeService = new AdditionalFeeService();
export default {
  name: "clone-fee",
  props: ['studentFees'],
  validations: {
    additional_fee: {
      clone_id_from: {required},
      clone_id_to: {required},
    }
  },
  data() {
    return {
      dialog: false,
      isBusy: false,
      cloneStudentFees: [],
      additional_fee: {
        clone_id_from: '',
        clone_id_to: '',
      },
    };
  },
  computed: {
    userId() {
      return this.$route.params.id ? this.$route.params.id : false;
    }
  },

  methods: {

    openCloneDialog() {
      this.dialog = true;
      this.resetForm();
    },

    onChangeFee() {
      this.cloneStudentFees = [];
       this.studentFees.forEach(fee => {
         if(fee.id != this.additional_fee.clone_id_from && !fee.is_additional_student_fees) {
           this.cloneStudentFees.push(fee);
        }
       })
    },

    createAndUpdate() {
      this.$v.$touch();
      if (this.$v.additional_fee.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        this.isBusy = true;
        additionalFeeService.clone(this.userId, this.additional_fee).then(response => {
          if(response.data.msg) {
            this.$snotify.error(response.data.msg);
          }
          if(response.data.status == "OK") {
            this.$snotify.success("Information added");
            this.$emit('refresh');
            this.closeDialog()
          }
          this.isBusy = false;
        }).catch(() => {
          this.isBusy = false;
          this.$snotify.error("Something went wrong");
        })
      }
    },

    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },

    resetForm() {
      this.isBusy = false;
      this.$v.$reset();
      this.cloneStudentFees = [];
      this.additional_fee = {
        clone_id_from: '',
        clone_id_to: '',
      }

    }
  }
};
</script>
