<template>
    <v-app>
        <v-dialog non-persistent v-model="dialog" max-width="600">
            <v-card>

              <v-toolbar dark>
                <v-card-title class="headline">
                  Notes
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="hideModal">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
                <v-card-text>
                    <v-row>

                        <v-col cols="12">
                            <v-text-field
                                    outlined
                                    dense
                                    v-model="note.title"
                                    label="Title"
                            >
                            </v-text-field>
                            <span class="text-danger" v-if="$v.note.title.$error"> Title is required</span>
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                    outlined
                                    dense
                                    v-model="note.type"
                                    :items="types"
                                    label="Type"
                            >
                            </v-select>
                            <span class="text-danger" v-if="$v.note.type.$error"> This field is required</span>
                        </v-col>
<!--                        <v-col cols="12">-->
<!--                            <v-switch v-model="note.action_required" label="Needs Followup"></v-switch>-->
<!--                        </v-col>-->
                        <v-col cols="12" v-if="note.action_required">
                            <v-select
                                    outlined
                                    dense
                                    v-model="note.action_title"
                                    :items="action_title"
                                    label="Action Title"
                            >
                            </v-select>

                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                    outlined
                                    dense
                                    v-model="note.description"
                                    label="Description"
                            >

                            </v-textarea>
                        </v-col>


<!--                        <v-col cols="12">-->
<!--                            <v-menu-->
<!--                                    v-model="fallow_up_date_menu"-->
<!--                                    :close-on-content-click="false"-->
<!--                                    :nudge-right="40"-->
<!--                                    transition="scale-transition"-->
<!--                                    offset-y-->
<!--                                    min-width="auto"-->
<!--                            >-->
<!--                                <template v-slot:activator="{ on, attrs }">-->
<!--                                    <v-text-field-->
<!--                                            v-model="note.follow_up_date"-->
<!--                                            label="Follow Up Date"-->
<!--                                            prepend-inner-icon="mdi-calendar"-->
<!--                                            prepend-icon=""-->
<!--                                            readonly-->
<!--                                            outlined-->
<!--                                            dense-->
<!--                                            v-bind="attrs"-->
<!--                                            v-on="on"-->
<!--                                    ></v-text-field>-->
<!--                                </template>-->
<!--                                <v-date-picker-->
<!--                                        v-model="note.follow_up_date"-->
<!--                                        @input="fallow_up_date_menu = false"-->
<!--                                ></v-date-picker>-->
<!--                            </v-menu>-->
<!--                        </v-col>-->
                    </v-row>


                    <div class="col-12 text-right mt-4">
                        <v-btn  depressed   class="btn btn-standard cancel-btn"
                               @click="hideModal">
                            Cancel
                        </v-btn>
                        <v-btn :loading="isBusy"    class="text-white ml-2 btn btn-primary"
                               depressed
                               @click="createAndUpdate">
                            Save
                        </v-btn>
                    </div>
                </v-card-text>

            </v-card>
        </v-dialog>
    </v-app>
</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import NotesService from "@/core/services/user/student/notes/NotesService";

    const notesService = new NotesService();
    export default {
        name: "notes-create-and-update",
        validations: {
            note: {
                title: {required},
                type: {required},
            }
        },
        data() {
            return {
                dialog: false,
                isBusy: false,
                edit: false,
                added_date_menu: false,
                fallow_up_date_menu: false,
                user: null,
                action_title: ['Absent', 'Discipline'],
                types: ['note', 'parent'],
                note: {
                    title: null,
                    type: 'note',
                    description: null,
                    action_title: null,
                    date_added: null,
                    follow_up_date: null,
                    added_by: null,
                    user_id: null,
                    department_id: null,
                    grade_id: null,
                    academic_class_id: null,
                    action_required: false,
                    is_active: true
                },
                fees: [],
            };
        },
        computed: {
            userId() {
                return this.$route.params.id ? this.$route.params.id : null;
            },
            currentUser() {
                return this.$store.getters.currentUser;
            },
        },
        methods: {
            get(id) {
                notesService.show(this.userId, id).then(response => {
                    this.edit = true;
                    this.note = response.data.notes;
                });
            },
            getNote() {
                notesService.paginate().then(response => {
                    this.fees = response.data.data;

                });
            },
            showModal(id = null, user = null) {
                if (id) {
                    this.get(id);
                }
                if (user) {
                    this.user = user;
                }
                this.getNote();
                this.dialog = true;
            },
            hideModal() {
                this.resetNote();
            },

            createAndUpdate() {

                this.note.user_id = this.user.id;
                this.note.grade_id = this.user.setting ? this.user.setting.grade_id:null;
                // this.note.academic_class_id = this.user.setting ? this.user.setting.class_id : null;
                this.note.added_by = this.currentUser.id;
                this.$v.$touch();
                if (this.$v.note.$error) {
                    setTimeout(() => {
                        this.$v.$reset();
                    }, 3000);
                } else {
                    if (this.edit) {
                        this.update();
                    } else {
                        this.create();
                    }
                }
            },

            create() {
                this.isBusy = true;
                notesService.store(this.userId, this.note).then(response => {
                    this.resetNote()
                    this.dialog = false;
                    this.isBusy = false;
                    this.$snotify.success("Information added");
                    this.$emit("refresh");
                });
            },
            update() {
                this.isBusy = true;
                notesService.update(this.userId, this.note.id, this.note).then(response => {
                    this.resetNote()
                    this.dialog = false;
                    this.isBusy = false;
                    this.$snotify.success("Information updated");
                    this.$emit("refresh");
                });
            },
            resetNote() {
                this.edit = false;
                this.dialog = false;
                this.$v.$reset()
                this.note = {
                  title: null,
                  type: 'note',
                  description: null,
                  action_title: null,
                  date_added: null,
                  follow_up_date: null,
                  added_by: null,
                  user_id: null,
                  department_id: null,
                  grade_id: null,
                  academic_class_id: null,
                  action_required: false,
                  is_active: true
                }
            }
        }
    };
</script>
