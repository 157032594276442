<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card ">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage fee</h4>
              </div>
              <div class="breadcrumb-right" v-if="isAccessible('admin,finance')">
<!--                <v-btn v-if="isAccessible('admin,finance')" @click="exportStudentFees()"-->
<!--                  class="btn btn-primary mx-1" dark>-->
<!--                  <i class="fas fa-file-excel"></i>-->
<!--                 Export-->
<!--                </v-btn>-->
                <v-btn v-if="isAccessible('admin,finance') && !is_generated" @click="openGenerateFeesForStudent()"
                  class="btn btn-primary mx-1" dark>
                  <i class="fa fa-plus"></i>
                  Generate fee
                </v-btn>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                  <v-col cols="12" md="4">
                    <v-menu
                        ref="menuDate"
                        v-model="menuStartDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="search.start_date"
                            label="View from date"
                            :error="$v.search.start_date.$error"
                            readonly
                            outlined
                            dense
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="search.start_date"
                          outlined
                          dense
                          no-title
                          @input="menuStartDate = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-menu
                        ref="menuDate"
                        v-model="menuEndDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="search.end_date"
                            label="View up to date"
                            :error="$v.search.end_date.$error"
                            readonly
                            outlined
                            dense
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="search.end_date"
                          outlined
                          dense :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"

                          no-title
                          @input="menuEndDate = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

              </div>
             </div>

            <div class="col-3 text-right text-white">
              <v-card flat class="border bg-primary text-white" v-if="user.setting && user.setting.total_pending">
                <v-card-title class="justify-center">
                  Total pending
                </v-card-title>
                <v-card-text class="justify-center text-center">
                  <div class="text-center headline">
                    Rs. {{ user.setting.total_pending }}
                  </div>
                </v-card-text>
              </v-card>
            </div>

            <div class="col-9 text-right text-white">
              <v-card>
                <v-card-title class="justify-left">
                  <v-row>
                    <v-col cols="6" class="text-left">
                      <h3>Discount</h3>
                    </v-col>
                    <v-col cols="6">
                      <v-btn @click="updateStudentDiscount" dark>Save</v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text class="justify-center text-center" v-if="user.setting">
                  <v-row class="mx-2 my-2">
                    <v-col cols="12" md="4">
                        <v-switch
                            outlined
                            dense
                            :label="user.setting.discount_enabled ? 'Enable Discount' : 'Disable Discount'"
                            v-model="user.setting.discount_enabled"
                            @change="onChangeDiscount"
                            clearable
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" md="4" v-if="user.setting.discount_enabled">
                        <v-select
                            outlined
                            dense
                            :items="['fixed', 'percentage']"
                            label="Discount type"
                            v-model="user.setting.discount_type"
                            @change="user.setting.fee_discount_received == ''"
                            clearable
                        ></v-select>
                    </v-col>

                    <v-col cols="12" md="4" v-if="user.setting.discount_type == 'fixed' || user.setting.discount_type == 'percentage'">
                        <v-text-field
                            v-if="user.setting.discount_type == 'fixed'"
                            outlined
                            dense
                            label="Discount amount"
                            type="number"
                            v-model="user.setting.fee_discount_received"
                        >
                        </v-text-field>
                        <v-text-field
                            v-if="user.setting.discount_type == 'percentage'"
                            outlined
                            dense
                            label="Discount percentage"
                            type="number"
                            v-model="user.setting.fee_discount_received"
                        >
                        </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
            <div class="table-responsive">
              <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Info</th>
                    <th class="px-3 wrap-column">Amount</th>
                    <th class="px-3 wrap-column">Invoice date</th>
                    <th class="px-3 wrap-column">Due date</th>
                    <th class="px-3 wrap-column">Cleared</th>
                    <th class="px-3 wrap-column">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="fees.length > 0" v-for="(fee, index) of fees" :key="index">

                    <td class="px-3 wrap-column">
                      {{ fee.title }}
                        <i v-if="!fee.is_cleared"  class="fas fa-circle"
                          :class="fee.is_current_installment ? 'dot-active' : 'dot-warning'"></i>
                    </td>

                    <td class="px-3 wrap-column">
                      <div>
                        <strong>Amount:</strong> Rs. {{ fee.amount }}
                      </div>
                      <div class="mt-3" v-if="fee.discount_amount">
                        <strong>Discount amount: </strong>
                        <span>
                          Rs. {{ fee.discount_amount }}<br>
                        </span>
                      </div>
                    </td>

                    <td class="px-3 wrap-column">{{ fee.formatted_invoice_date }}</td>

                    <td class="px-3 wrap-column">{{ fee.due_date_text }}</td>

                    <td class="px-3 wrap-column">
                      <i class="fas fa-check" v-if="fee.is_cleared" style="color: #00b300"> </i>
                      <i class="fas fa-ban" style="color: red" v-else></i>
                    </td>

                    <td>
                      <b-dropdown size="sm" variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret
                        right no-flip>
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i>
                            </span>
                          </slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <b-dropdown-text tag="div" class="navi-item" v-if="!fee.is_cleared && !fee.invoice_id">
                            <a class="navi-link" @click="createInvoice(fee.student_id, fee.id)">
                              <span class="navi-text">Create an invoice</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item" v-if="fee.invoice_id">
                            <a class="navi-link" @click="sendInvoice(fee.student_id, fee.id)">
                              <span class="navi-text">{{fee.is_send_invoice ? 'Send again invoice' : 'Send an invoice'}}</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item" v-if="fee.invoice_id">
                            <a class="navi-link" @click="previewInvoice(fee.invoice_url)">
                              <span class="navi-text">View an invoice</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item" v-if="!fee.is_cleared && fee.invoice_id">
                            <a class="navi-link" @click="makeFullPayment(fee)">
                              <span class="navi-text">Make full payment</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item" v-if="!fee.is_cleared && fee.invoice_id">
                            <a class="navi-link" @click="makePartialPayment(fee)">
                              <span class="navi-text">Make partial payment</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item" v-if="!fee.is_cleared && !fee.invoice_id">
                            <a class="navi-link" @click="openInstallmentForm(fee)">
                              <span class="navi-text">Create sub installment</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item" v-if="!fee.is_cleared && !fee.invoice_id">
                            <a class="navi-link" v-if="!fee.discount_amount" @click="applyDiscount(fee.id)">
                              <span class="navi-text">Apply Discount</span>
                            </a>
                            <a class="navi-link" v-else @click="cancelDiscount(fee.id)">
                              <span class="navi-text">Cancel discount</span>
                            </a>
                          </b-dropdown-text>

                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>

                    </td>
                  </tr>
                  <tr v-if="fees.length == 0">
                    <td colspan="7" class="text-center"><strong>Data not available</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <generate-fee ref="fee-generate" :user_id="user.id" :dialog="generate_fee_dialog" :setting="setting"
           :is_generated="is_generated" :program_fee="program_fee"  @refresh_list="getFees"></generate-fee>

          <installment v-if="sub_fee.id" :user_id="user.id" :dialog="installment_dialog" :fee="sub_fee"
            @refresh="closeDialog"></installment>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import {API_URL} from "@/core/config";
import {required} from "vuelidate/lib/validators";
import GenerateFee from "@/view/pages/student/profile/fee/GenerateFee";
import Installment from "@/view/pages/student/profile/fee/Installment";
import ProgramService from "@/core/services/level/program/ProgramService";
import ProgramFeeService from "@/core/services/fee/programFee/ProgramFeeService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import StudentFeeService from "@/core/services/user/student/fee/StudentFeeService";
import ConfigService from "@/core/services/config/ConfigService";
import FinancialHistoryService from "@/core/services/finance/history/FinancialHistoryService";
import StudentSettingService from "@/core/services/user/student/StudentSettingService";

const historyService = new FinancialHistoryService();
const programService = new ProgramService();
const programFee = new ProgramFeeService();
const gradeLevelService = new GradeLevelService();
const feeService = new StudentFeeService();
const configService = new ConfigService();
const studentSettingService = new StudentSettingService();

export default {
  name: "fees",
  props: ['user'],
  components: {
    GenerateFee,
    Installment
  },
  validations: {
    search: {
      start_date: {required},
      end_date: {required},
    }
  },
  data() {
    return {
      fees: [],
      loading: false,
      installment_dialog: false,
      is_generated: false,
      payment_dialog: false,
      generate_fee_dialog: false,
      dialog: false,
      isBusy: false,
      program_fee: {},
      setting: {},
      sub_fee: {},
      gradesLevels: [],
      programYearLength: 0,
      error: false,
      menuStartDate: false,
      menuEndDate: false,
      search: {
        start_date:'',
        end_date: '',
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  mounted() {
    if (this.user) {
      this.setting = this.user.setting || null;
      if (this.setting) {
        this.getFees();
        this.getStudentFee()
        this.getGradeLevel()
      }
    }
  },
  methods: {
    isStudentFeeGenerated(){
      feeService.isStudentFeeGenerated(this.user.id, this.program_fee.program_id, this.program_fee.id).then(response=>{
        this.is_generated = response.data
      })
    },
    searchFee() {
      this.page = 1;
      this.getFees();
    },

    exportStudentFees() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        let url = API_URL + `user/${this.user.id}/student-fee/get/export-excel`
        if (this.search) {
          let query = new URLSearchParams(this.search).toString();
          if (query)
            url = url + '?' + query;
        }
        window.open(url)
      }
    },

    openGenerateFeesForStudent() {
      this.$refs['fee-generate'].openDialog();
    },

    onChangeDiscount() {
      this.user.setting.discount_type = '';
      this.user.setting.fee_discount_received = '';
    },

    openInstallmentForm(fee) {
      this.installment_dialog = true
      this.sub_fee = fee;
    },

    getStudentFee() {
      programFee.getStudentProgramFee(this.user.id).then(response => {
        if (!response.data.status) {
          this.$snotify.error(response.data.msg)
        }
        if(response.data.fee) {
          this.program_fee = response.data.fee;
          this.isStudentFeeGenerated();
        }
      }).catch(() => {})
    },

    getGradeLevel() {
      gradeLevelService.getByProgram(this.setting.program_id).then(response => {
        this.gradesLevels = response.data;
        this.programYearLength = this.gradesLevels.length
      });
    },

    getFees() {
      this.payment_dialog = false;
      this.generate_fee_dialog = false;
      this.loading = true;
      this.selected = [];
      feeService.all(this.user.id).then(response => {
        this.fees = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },

    closeDialog() {
      this.sub_fee = {};
      this.getFees()
      this.dialog = false
    },

    isAccessible(roles) {
      let flag = [];
      let type = this.currentUser.type;
      let userRoles = configService.getRoles(type);
      if (roles != null && roles != undefined && userRoles != null && userRoles != undefined) {
        roles = roles.split(",");
        for (let t of roles) {
          t = t.trim();
          if (userRoles.includes(t)) {
            flag.push(true);
          } else {
            flag.push(false);
          }
        }
        if (flag.includes(true)) {
          return true;
        }
      }
      return false;
    },

    updateStudentDiscount() {
      studentSettingService.updateDiscount(this.user.id, this.user.setting.id, this.user.setting).then(response => {
          if(response.data.status == "OK") {
            this.$snotify.success("Invoice create successfully");
            this.getFees();
          }
        }).catch(() => {})
    },

    previewInvoice(url) {
      window.open(url, '_blank');
    },

    sendInvoice(studentId, id) {
      this.$confirm({
        message: `Are you sure you want to send invoice this item ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            feeService.sendInvoice(studentId, id).then(response => {
              if(response.data.status == "OK") {
                this.$snotify.success("Invoice has been sent successfully");
                this.getFees();
              }
              if(response.data.msg) {
                this.$snotify.error(response.data.msg);
              }
            }).catch(() => {})
          }
        }
      });
    },

    createInvoice(studentId, id) {
      this.$confirm({
        message: `Are you sure you want to create invoice this item ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            feeService.createInvoice(studentId, id).then(response => {
              if(response.data.status == "OK") {
                this.$snotify.success("Invoice create successfully");
                this.getFees();
              }
            }).catch(() => {})
          }
        }
      });
    },

    makeFullPayment(fee) {
      this.$confirm({
        message: `Are you sure you want to make full payment this item ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.isBusy = true
            let data = {
              'payment_status': 'pending',
              'type': 'income',
              'title': 'Academic Fee',
              'student_fee_id': fee.id,
              'invoice_id': fee.invoice_id,
              'financial_transaction_head_id': fee.financial_transaction_head_id,
              'financial_payment_type_id': fee.financial_payment_type_id,
              'payer_user_id': fee.student_id,
              'total_amount': fee.remaining_amount,
              'is_full_paid': 1,
            };
            historyService.createQuote(fee.student_id, data).then((response) => {
              this.getFees();
              if(response.data.status == "OK") {
                let history = response.data.history;
                if(history && !history.is_payment_processed) {
                  this.updatePaymentProcessed(fee, history);
                } else {
                  this.$tabs.open({name: "finance-history-type-update", params: {type: history.type, id: history.id}});
                }
              }
            }).catch(() => {
              this.$snotify.error("Can't create payment")
            })
          }
        }
      });
    },

    makePartialPayment(fee) {
      this.$confirm({
        message: `Are you sure you want to make partial payment this item ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.isBusy = true
            let data = {
              'payment_status': 'pending',
              'type': 'income',
              'title': 'Academic Fee',
              'student_fee_id': fee.id,
              'invoice_id': fee.invoice_id,
              'payer_user_id': fee.student_id,
              'total_amount': fee.remaining_amount,
              'is_full_paid': 0,
            };
            historyService.createQuote(fee.student_id, data).then((response) => {
              this.getFees();
              if(response.data.status == "OK") {
                let history = response.data.history;
                if(history && !history.is_payment_processed) {
                  this.updatePaymentProcessed(fee, history);
                } else {
                  this.$tabs.open({name: "finance-history-type-update", params: {type: history.type, id: history.id}});
                }
              }
            }).catch(() => {
              this.$snotify.error("Can't create payment")
            })
          }
        }
      });
    },

    updatePaymentProcessed(fee, history) {
      feeService.update(fee.student_id, fee.id, {is_payment_processed: 1}).then((res) => {
        if(res.data.status == "OK") {
          this.$tabs.open({name: "finance-history-type-update", params: {type: history.type, id: history.id}});
        }
      }).catch(() => {
        this.$snotify.error("Can't update payment processed")
      })
    },

    applyDiscount(feeId) {
      this.$confirm({
        message: `You wish to apply discount on this fee. Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            feeService.applyDiscount(this.user.id, feeId).then(response => {
              if (!response.data.status)
                this.$snotify.error(response.data.msg)
              else {
                this.$snotify.success("Information updated")
                this.getFees();
              }
            })
          }
        }
      });
    },

    cancelDiscount(feeId) {
      this.$confirm({
        message: `You wish to cancel discount on this fee. Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            feeService.cancelDiscount(this.user.id, feeId).then(response => {
              if (!response.data.status)
                this.$snotify.error(response.data.msg)
              else {
                this.$snotify.success("Information updated")
                this.getFees();
              }
            })
          }
        }
      });
    },
  }
};
</script>
