<template>
  <v-app>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>  Manage student parents information</h4>
              </div>
<!--              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn v-if="checkIsAccessible('user', 'create')" @click="createParentInformation()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add student
                  </v-btn>&nbsp;&nbsp;
                </div>
              </div>-->
            </div>
          </div>
          <div class="card-body">
            <div class="row" >
              <div class="col-12">
                <span class="font-weight-medium">Father information</span>
                <div class="card-toolbar" v-if="!father_information">
                  <v-btn v-if=" checkIsAccessible('user', 'create')" @click="createParentInformation('father')" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add
                  </v-btn>&nbsp;&nbsp;
                  <a v-if=" checkIsAccessible('user', 'create')" @click="linkParentInformation('father')" class="mt-4 " style="color: #fff">
                    Link father profile
                  </a>&nbsp;&nbsp;
                </div>
                <table v-if="father_information!=null" class="table table-bordered">
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                        First name
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{father_information.first_name}}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                        Middle name
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{father_information.middle_name || '-' }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                        Last name
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{father_information.last_name || '-' }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                       Email
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                         <a v-if="father_information.email" class="font-weight-bold mb-1" :href="'mailto:'+father_information.email">
                            {{father_information.email}} <span v-if="father_information.email" class="badge"
                                                               :class="father_information.is_email_verified ? 'badge-success' : 'badge-danger'">
                                                    {{ father_information.is_email_verified ? 'Verified' : 'Not verified' }}
                                                </span>
                          </a>
                        <span v-if="!father_information.email">
                          N/A
                        </span>

                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                       Profession
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold" v-if="father_information.profession">
                       {{ father_information.profession_text || "-" }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                       Phone
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{ father_information.phone ||  "N/A" }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                       Mobile
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{ father_information.mobile ||  "N/A" }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                       Account verified
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                       <span class="badge"
                             :class="father_information.is_login_verified ? 'badge-success' : 'badge-danger'">
                                                    {{ father_information.is_login_verified ? 'Verified' : 'Not verified' }}
                          </span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" >
                      <div class="text-center">
                        <span @click="editParentInformation(father_information,'father')" class="font-weight-medium mr-2 cursor-pointer">
                          <i class="fas fa-edit"  style="color: #00b300"></i>
                        </span>
                        <span @click="_delete(father_information.id)" class="font-weight-medium ml-4 cursor-pointer">
                          <i class="fas fa-trash" style="color: red;"></i>
                        </span>
                        <span title="Unlink father information" @click="_delete(father_information.id)" class="font-weight-medium ml-4  cursor-pointer">
                          <i class="fas fa-unlink" style="color: cadetblue;"></i>
                        </span>
                        <span title="Change password" @click="changePassword(father_information)" class="font-weight-medium ml-4  cursor-pointer">
                          <i class="fas fa-user-shield" style="color: cadetblue;"></i>
                        </span>
                      </div>
                    </td>

                  </tr>
                </table>
              </div>
              <v-divider></v-divider>
              <div class="col-12">
                <span class="font-weight-medium">Mother information</span>
                <div class="card-toolbar" v-if="!mother_information">
                  <v-btn v-if="checkIsAccessible('user', 'create')" @click="createParentInformation('mother')" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add
                  </v-btn>&nbsp;&nbsp;
                  <a v-if=" checkIsAccessible('user', 'create')" @click="linkParentInformation('mother')" class="mt-4" style="color: #fff">
                    Link mother profile
                  </a>&nbsp;&nbsp;
                </div>
                <table v-if="mother_information" class="table table-bordered">
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                        First name
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{mother_information.first_name}}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                        Middle name
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{mother_information.middle_name || '-' }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                        Last name
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{mother_information.last_name || '-' }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                       Email
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                         <a v-if="mother_information.email" class="font-weight-bold mb-1" :href="'mailto:'+mother_information.email">
                            {{mother_information.email}} <span v-if="mother_information.email" class="badge"
                                                               :class="mother_information.is_email_verified ? 'badge-success' : 'badge-danger'">
                                                    {{ mother_information.is_email_verified ? 'Verified' : 'Not verified' }}
                                                </span>
                          </a>
                        <span v-if="!mother_information.email">
                          N/A
                        </span>

                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                       Profession
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{ mother_information.profession_text || "-" }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                       Phone
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{ mother_information.phone ||  "N/A" }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                       Mobile
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{ mother_information.mobile ||  "N/A" }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                       Account verified
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                       <span class="badge"
                             :class="mother_information.is_login_verified ? 'badge-success' : 'badge-danger'">
                                                    {{ mother_information.is_login_verified ? 'Verified' : 'Not verified' }}
                          </span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" >
                      <div class="text-center">
                        <span title="Edit mother information" @click="editParentInformation(mother_information,'mother')" class="font-weight-medium mr-2 cursor-pointer">
                         <i class="fas fa-edit"  style="color: #00b300"></i>
                        </span>
                        <span title="Delete mother information" @click="_delete(mother_information.id)" class="font-weight-medium ml-4  cursor-pointer">
                          <i class="fas fa-trash" style="color: red;"></i>
                        </span>
                        <span title="Unlink mother information" @click="unlinkUser(mother_information.id)" class="font-weight-medium ml-4  cursor-pointer">
                          <i class="fas fa-unlink" style="color: cadetblue;"></i>
                        </span>

                        <span title="Change password" @click="changePassword(mother_information)" class="font-weight-medium ml-4  cursor-pointer">
                          <i class="fas fa-user-shield" style="color: cadetblue;"></i>
                        </span>
                      </div>
                    </td>

                  </tr>
                </table>
              </div>
              <v-divider></v-divider>
              <div class="col-12">
                <span class="font-weight-medium">Guardian information</span>
                <div class="card-toolbar" v-if="!guardian_information">
                  <v-btn v-if="checkIsAccessible('user', 'create')" @click="createParentInformation('guardian')" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add
                  </v-btn>&nbsp;&nbsp;
                  <a v-if=" checkIsAccessible('user', 'create')" @click="linkParentInformation('guardian')" class="mt-4" style="color: #fff">
                    Link guardian profile
                  </a>
                </div>
                <table v-if="guardian_information" class="table table-bordered">
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                        First name
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{guardian_information.first_name}}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                        Middle name
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{guardian_information.middle_name || '-' }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                        Last name
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{guardian_information.last_name || '-' }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                       Email
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                         <a v-if="guardian_information.email" class="font-weight-bold mb-1" :href="'mailto:'+guardian_information.email">
                            {{guardian_information.email}}    <span v-if="guardian_information.email" class="badge"
                                                                    :class="guardian_information.is_email_verified ? 'badge-success' : 'badge-danger'">
                                                    {{ guardian_information.is_email_verified ? 'Verified' : 'Not verified' }}
                                                </span>
                          </a>
                        <span v-if="!guardian_information.email">
                          N/A
                        </span>

                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                       Profession
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{ guardian_information.profession_text || "-" }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                       Phone
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{ guardian_information.phone ||  "N/A" }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                       Mobile
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                       {{ guardian_information.mobile ||  "N/A" }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                       Account verified
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                       <span class="badge"
                             :class="guardian_information.is_login_verified ? 'badge-success' : 'badge-danger'">
                                                    {{ guardian_information.is_login_verified ? 'Verified' : 'Not verified' }}
                          </span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" >
                      <div class="text-center">
                        <span @click="editParentInformation(guardian_information,'mother')" class="font-weight-medium mr-2 cursor-pointer">
                         <i class="fas fa-edit"  style="color: #00b300"></i>
                        </span>
                        <span @click="_delete(guardian_information.id)" class="font-weight-medium ml-4  cursor-pointer">
                          <i class="fas fa-trash" style="color: red;"></i>
                        </span>
                        <span title="Unlink guardian information" @click="unlinkUser(guardian_information.id)" class="font-weight-medium ml-4  cursor-pointer">
                          <i class="fas fa-unlink" style="color: cadetblue;"></i>
                        </span>
                        <span title="Change password" @click="changePassword(guardian_information)" class="font-weight-medium ml-4  cursor-pointer">
                          <i class="fas fa-user-shield" style="color: cadetblue;"></i>
                        </span>
                      </div>
                    </td>

                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-or-update ref="create-update-parent-information" :user="user" @refresh="getStudentParentInformation"></create-or-update>
    <link-parental-information ref="link-parent-information" :user="user" @refresh="getStudentParentInformation"></link-parental-information>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            Change password for parent
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-text-field :error="$v.password.$error" outlined v-model="password" dense label="Password">

          </v-text-field>
          <span class="text-danger" v-if="!$v.password.minLength">Password should be more 8 character</span>
        </v-card-text>
        <v-card-actions>
          <v-col cols="12">
            <div class="float-right">
              <v-btn depressed class="btn btn-standard cancel-btn" @click="closeDialog">Cancel</v-btn>
              <v-btn depressed :loading="changeLoading" class="btn btn-primary text-white ml-2"
                     @click.prevent="changeParentPassword">Change password
              </v-btn>
            </div>
          </v-col>


        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>
<script>
import CreateOrUpdate from  "./CreateOrUpdate.vue";
import LinkParentalInformation from "@/view/pages/student/profile/parents/LinkParentalInformation.vue";
import {minLength, required} from "vuelidate/lib/validators";
import StudentParentService from "@/core/services/user/student/StudentParentService";

const studentParent = new StudentParentService();
export default {
  props:['user'],
  components:{
    CreateOrUpdate,
    LinkParentalInformation
  },
  validations: {
    password: { required, minLength: minLength(8) }
  },
  data() {
    return {
      loading:false,
      dialog:false,
      changeLoading:false,
      father_information:null,
      mother_information:null,
      guardian_information:null,
      selectParent: null,
      password:null,
    }
  },
  methods:{
    changePassword(parent) {
      this.dialog = true;
      this.selectParent = parent;
    },
    changeParentPassword() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        studentParent.changePassword(this.selectParent.id, {password:this.password}).then((response) => {
          if(response.data.status == "OK") {
            this.$snotify.success("Password has been changed");
            this.closeDialog();
          }
        }).catch(() => {})
      }

    },
    closeDialog() {
      this.dialog = false;
      this.password = null;
      this.selectParent = null;
      this.$v.$reset();
    },
    getStudentParentInformation(){

      this.loading=true;
      studentParent
          .getStudentParentInformation(this.user.id)
          .then((response) => {
            if(response.data && response.data.father_information){
              this.father_information=response.data.father_information;
            }else{
              this.father_information = null;
            }
            if(response.data && response.data.mother_information) {
              this.mother_information = response.data.mother_information;
            }else{
              this.mother_information = null;
            }
            if(response.data && response.data.guardian_information) {
              this.guardian_information = response.data.guardian_information;
            }else{
              this.guardian_information = null;
            }

          })
          .catch(error => {

          })
          .finally(()=>{
            this.loading = false;
          })
    },
    createParentInformation(type){
      this.$refs['create-update-parent-information'].createParentInformation(type);
    },
    linkParentInformation(type){
      this.$refs['link-parent-information'].linkParentInformation(type);
    },
    editParentInformation(item,type){
      this.$refs['create-update-parent-information'].editParentInformation(type,item);
    },
    unlinkUser(id){
      this.$confirm(
          {
            message: `Are you sure you want to unlink parent information of students?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
              if (confirm) {

                studentParent
                    .unlinkStudentParent(id,{'user_id':this.user.id})
                    .then(response => {
                      this.$snotify.success("Parents information unlinked");
                      this.getStudentParentInformation();
                    })
                    .catch(error => {

                    })
                    .finally(()=>{

                    });
              }
            }
          }
      )
    },
    _delete(id) {
      this.$confirm(
          {
            message: `Are you sure you want to delete parent information of students?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
              if (confirm) {

                studentParent
                    .deleteStudentParentInformation(id)
                    .then(response => {
                      this.$snotify.success("Parents information deleted");
                      this.getStudentParentInformation();
                    })
                    .catch(error => {

                    })
                    .finally(()=>{

                    });
              }
            }
          }
      )
    }
  },
  mounted() {
    this.getStudentParentInformation();
  }
}
</script>
