<template>
  <v-app>
    <v-dialog
        v-model="dialog"
        scrollable
        max-width="900"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{log.title}}</span>
        </v-card-title>
        <v-card-subtitle class="ml-1">
          <span class="font-size-md" v-if="log.admin_user"> By {{log.admin_user[0].first_name}} {{log.admin_user[0].last_name}}</span><span class="font-size-md"> on {{log.formatted_log_date}}</span>
          <v-divider></v-divider>
        </v-card-subtitle>
        <v-card-text>
          <div v-if="log.log_type =='create' && log.compared_data">
            <ul v-for="(item, index) in log.compared_data" :key="index">
              <li class="mb-2 pl-2 py-1">
                <span class="" style="font-weight:bold; font-size:14px;">
                  {{item.field}} : 
                </span>
                <span v-if="item.field == 'Country'" class="" style="font-size:14px;">
                  &nbsp;{{item.changed ? item.changed.name : '-'}}
                </span>
                <span v-if="item.field == 'State'" class="" style="font-size:14px;">
                  &nbsp;{{item.changed ? item.changed.name : '-'}}
                </span>
                <span v-if="item.field != 'State' && item.field != 'Country'" class="" style="font-size:14px;">
                  &nbsp;{{item.changed ? item.changed : '-'}}
                </span>
              </li>
            </ul>
          </div>

          <div v-if="log.log_type !='create' && log.compared_data">
            <ul v-for="(item) in log.compared_data">
              <li class="mb-2 pl-2 py-1">
                <code style="font-size: 14px; font-weight: bold;" class="ms-0 mr-1">                  
                    {{item.field}}
                </code>
                <span class="" style="font-size:14px;">
                  changed from
                </span>
                <code style="font-size: 14px; font-weight: bold;" v-if="item.original" class="ms-0">
                  <span v-if="item.field != 'Status'">
                    {{item.original }}
                  </span>
                  <span v-if="item.field == 'Status'">
                    {{item.original ? item.original : 'Inactive' }}
                  </span>
                </code>
                
                <code style="font-size: 14px; font-weight: bold;" v-else class="ms-0">
                  -
                </code>
                
                <span 
                  class="" 
                  style="font-size:14px;"
                >
                  &nbsp; to
                </span>
                
                <span v-if="item.changed">
                  <code v-if="item.field != 'Status'" style="font-size:14px; font-weight: bold;">
                    {{item.changed }}
                  </code>
                  <code v-if="item.field == 'Status'" style="font-size:14px; font-weight: bold;">
                    {{item.changed ? 'Active' : 'Inactive' }}
                  </code>
                </span>
                <span v-else>
                  <code v-if="item.field == 'Status'" style="font-size:14px; font-weight: bold;">
                    {{item.changed ? 'Active' : 'Inactive' }}
                  </code>
                  <code v-else style="font-size:14px; font-weight: bold;">
                    -
                  </code>
                </span>

              </li>
            </ul>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="btn btn-danger text-white"
              text
              @click="closeDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>

export default {
  name: "LogDetail",
  data(){
    return{
      dialog: false,
      log: {},
    }
  },
  methods:{
    showLogDetail(log){
      this.log = log;
      this.dialog = true;
    },
    closeDialog(){
      this.dialog = false;
    }
  }
}
</script>

<style scoped>

</style>