<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card ">
          <!--begin::Header-->
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Additional fees
                </h4>
              </div>
              <div class="breadcrumb-right">
                <v-btn @click="openCloneDialog()" :loading="isBusy" class="mt-4 btn btn-primary  mr-1" dark>
                  <i class="fa fa-clone"></i>
                  Clone fee
                </v-btn>
                <v-btn @click="openDialog()" :loading="isBusy" class="mt-4 btn btn-primary  mr-1" dark>
                  <i class="fa fa-plus"></i>
                  Add fee
                </v-btn>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="4">
                  <v-select
                      v-model="search.student_fee_id"
                      :items="studentFees"
                      item-text="title"
                      item-value="id"
                      label="Select a fees"
                      v-on:keyup.enter="searchAdditionalFee()"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="8" class="text-right">
                  <v-btn
                      @click="searchAdditionalFee()"
                      class="btn btn-primary btn-search w-35"
                      :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive">
              <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr>
                    <th class="px-3 wrap-column">Payment</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="additionalFees.length > 0" v-for="(additionalFee, index) of additionalFees" :key="index">

                    <td class="px-3 wrap-column">
                      <div class="mt-2">
                        <span class="font-weight-medium">{{ additionalFee.payment_type_title|| '' }}</span>
                      </div>
                      <div class="mt-2">
                        Rs. {{ additionalFee.total_fee }}
                      </div>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown size="sm" variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret
                          right no-flip>
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="openDialog(additionalFee.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteAdditionalFees(additionalFee.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-trash red--text"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="additionalFees.length == 0">
                    <td colspan="6" class="text-center">
                      <strong>Data not available</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination v-if="additionalFees.length > 0" class="pull-right mt-7" @input="getAdditionalFee"
                v-model="page" :total-rows="total" :per-page="perPage" first-number last-number></b-pagination>
            </div>
          </div>
        </div>
        <clone-fee ref="fee-clone" :additionalFee_id="additionalFee_id" @refresh="getAdditionalFee" :student-fees="studentFees"></clone-fee>
        <create-and-update ref="additionalFee" :additionalFee_id="additionalFee_id" :student-fees="studentFees"
          @refresh="getAdditionalFee"></create-and-update>
      </div>
    </div>
  </v-app>
</template>

<script>
import CloneFee from "../fee/CloneFee.vue";
import CreateAndUpdate from "./CreateAndUpdate";
import AdditionalFeeService from "@/core/services/fee/addtionalFee/AdditionalFeeService";
import StudentFeeService from "@/core/services/user/student/fee/StudentFeeService";

const feeService = new StudentFeeService();
const additionalFeeService = new AdditionalFeeService();

export default {
  name: "Additional-Fee",
  components: {
    CloneFee,
    CreateAndUpdate
  },
  data() {
    return {
      isBusy: false,
      loading: false,
      additionalFees: [],
      studentFees: [],
      additionalFee_id: null,
      page: null,
      total: null,
      perPage: null,
      search: {
        student_fee_id: ''
      }
    };
  },
  computed: {
    userId() {
      return this.$route.params.id ? this.$route.params.id : false;
    }
  },
  mounted() {
    this.getFees();
  },
  methods: {
    searchAdditionalFee() {
      this.page = 1;
      this.getAdditionalFee();
    },
    openCloneDialog() {
      this.$refs['fee-clone'].openCloneDialog();
    },
    getFees() {
      feeService.all(this.userId).then(response => {
        this.studentFees = response.data.data;
        if(this.studentFees.length > 0) {
          this.search.student_fee_id = this.studentFees[0].id;
        }
        this.getAdditionalFee()
      }).catch(() => {});
    },
    getAdditionalFee() {
      this.loading = true;
      additionalFeeService.paginate(this.userId, this.search, this.page).then(response => {
        this.loading = false;
        this.additionalFees = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      }).catch(() => {
        this.loading = false;
      });
    },
    openDialog(id) {
      this.additionalFee_id = id;
      this.$refs["additionalFee"].showModal(id);
    },
    closeDialog(id) {
      this.additionalFee_id = id;
      this.$refs["additionalFee"].hideModal();
      this.getAdditionalFee();
      this.additionalFee_id = null;
    },
    deleteAdditionalFees(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            additionalFeeService.delete(this.userId, id).then(response => {
              this.$snotify.success("Information deleted");
              this.getAdditionalFee();
            });
          }
        }
      });
    }
  }
};
</script>
