<template>
    <v-app>
      <div class="row">
        <div class="col-12">
          <div class="card ">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>Leave requests</h4>
                </div>
                <div class="breadcrumb-right" v-if="checkIsAccessible('leave-request', 'create')">
                  <div class="card-toolbar">
                    <v-btn @click="createLeaveRequest()" class="mt-4 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                      Add leave request
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col md="3" cols="12">
                    <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="auto"
                        offset-y
                        transition="scale-transition"
                        v-model="startDate"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            append-icon="mdi-calendar"
                            clearable
                            dense   v-on:keyup.enter="__get()"
                            label="Start date"
                            outlined
                            readonly
                            v-bind="attrs"
                            v-model="search.start_date"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          @input="startDate = false"
                          v-model="search.start_date"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="auto"
                        offset-y
                        transition="scale-transition"
                        v-model="endDate"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            append-icon="mdi-calendar"
                            clearable
                            dense
                            label="End date"
                            outlined
                            readonly v-on:keyup.enter="__get()"
                            v-bind="attrs"
                            v-model="search.end_date"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          @input="endDate = false"
                          v-model="search.end_date"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-select
                      outlined
                      dense
                      label="Requested by"
                      v-model="search.requested_by"
                      :items="requestedByValues"
                      clearable
                      item-text="name"
                      item-value="value"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="3" class="text-right">
                    <v-btn
                        @click="__get()"
                        class="btn btn-primary btn-search"
                        :loading="loadingData"
                    >
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>

                </div>
              </div>
              <div class="">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="loadingData"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loadingData"
                    type="table-row-divider@25"
                >
                </v-skeleton-loader>
                <table class="table" v-if="!loadingData">
                  <thead>
                  <tr>
                    <th class="px-3 wrap-column" >Title</th>
                    <th class="px-3 wrap-column" >Category</th>
                    <th class="px-3 wrap-column" >Status</th>
                    <th class="px-3 text-center">Options</th>
                  </tr>
                  </thead>
                  <tr v-show="leave_requests.length > 0" :key="index" v-for="(leave, index) of leave_requests">
                    <td class="wrap-column" scope="row">
                      <a @click="__viewDetail(leave)" class="text-primary font-weight-bolder text-hover-primary mb-1" href="#">
                        {{leave.title }}</a> &nbsp;
                      <i :class="leave.is_active?'dot-active':'dot-inactive'"
                         class="fas fa-circle"></i>
                      <div>
                        <small>
                          <span class="font-weight-medium">
                            {{leave.leave_start_date | moment("Do MMMM YYYY")}}
                          </span>
                        </small>
                        <small v-if="leave.leave_end_date">
                          <span class="font-weight-medium">
                           - {{leave.leave_end_date | moment("Do MMMM YYYY")}}
                          </span>
                        </small>
                      </div>
                      <div class="text-secondary">
                        <span class="font-weight-bold">Requested by :</span><span class="font-weight-medium" v-if="leave.requested_by_name"> {{leave.requested_by_name}}</span><span class="font-weight-medium"> ({{leave.requested_by_text}})</span>
                      </div>
                    </td>

                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                        {{leave.category}}
                      </span>
                    </td>


                    <td class="wrap-column pt-3">
                      <template v-if="leave.status">
                        <span  :class="leavestatus(leave.status)"
                                                        class="badge text-capitalize">
                                                      {{leave.status ? leave.status : 'Pending'}}
                                                </span>
                        <div class="mt-2" v-if="leave.processed_by">
                        by {{ leave.processed_user }}
                        </div>
                      </template>

                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text class="navi-item" tag="div" v-if="leave.status=='pending' && checkIsAccessible('leave-request', 'edit')">
                              <a @click="__detail(leave,'approved')" class="navi-link"
                                 href="#">
                                <span class="navi-icon">
                                  <i class=" far fa-check-square text-success"></i>
                                </span>
                                <span class="navi-text"> Approve request</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text class="navi-item" tag="div" v-if="leave.status=='pending' && checkIsAccessible('leave-request', 'edit')">
                              <a @click="__detail(leave,'declined')" class="navi-link"
                                 href="#">
                                <span class="navi-icon">
                                  <i class=" fas fa-window-close text-danger"></i>
                                </span>
                                <span class="navi-text"> Decline request </span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('leave-request', 'edit')">
                              <a class="navi-link" @click="editLeaveRequest(leave.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('leave-request', 'delete')">
                              <a class="navi-link" @click.prevent="__delete(leave.id)">
                                  <span class="navi-icon">
                                     <i class="fas fa-trash"></i>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>

                  </tr>
                  <tr v-if="leave_requests.length == 0">
                    <td colspan="4" class="text-center">
                      <strong>Data not available</strong>
                    </td>
                  </tr>
                </table>
                <b-pagination
                    v-if="leave_requests.length > 0"
                    class="pull-right mt-7"
                    :per-page="perPage"
                    :total-rows="total"
                    @input="__get"
                    first-number
                    last-number
                    v-model="page"
                ></b-pagination>
              </div>
            </div>
            <template>
              <v-row>
                <v-dialog
                    max-width="800px"
                    scrollable
                    v-model="leave_detail_dialog"
                >
                  <v-card v-if="leave_detail">
                    <v-toolbar dark>
                      <v-card-title class="headline">
                        Leave request detail
                        <hr>
                      </v-card-title>

                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon  @click="__reset">
                          <i class="fas fa-times"></i>
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>

                    <v-card-text>
                      <div class="row">
                        <div class="col-md-6">
                          <h4>{{leave_detail.user_full_name}} <br/> {{leave_detail.user_type}}</h4>
                          <div class="">
                            <strong>
                              Start Date :  </strong> {{leave_detail.leave_start_date | moment("Do MMMM YYYY")}}

                            <p v-if="leave_detail.leave_end_date">
                              <strong>End Date : </strong> {{leave_detail.leave_end_date | moment("Do MMMM YYYY")}}
                            </p>
                          </div>
                          <h4>{{leave_detail.title}}</h4>
                          <p v-html="leave_detail.description"></p>
                          <v-row v-if="leave_detail.attachment_1 || leave_detail.attachment_2 || leave_detail.attachment_3">
                            <v-col cols="12">
                              Attachments
                            </v-col>
                            <v-col cols="12">
                              <v-list-item-group
                                  color="primary"
                              >
                                <v-list-item
                                    v-if="leave_detail.attachment_1"
                                >
                                  <v-list-item-icon>
                                    <v-icon> mdi-file</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <a :href="leave_detail.attachment_one_path" target="_blank">Attachment
                                        - {{leave_detail.attachment_1}}</a>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="leave_detail.attachment_2"
                                >
                                  <v-list-item-icon>
                                    <v-icon> mdi-file</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <a :href="leave_detail.attachment_two_path" target="_blank">Attachment
                                        - {{leave_detail.attachment_2}}</a>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="leave_detail.attachment_2"
                                >
                                  <v-list-item-icon>
                                    <v-icon> mdi-file</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <a :href="leave_detail.attachment_three_path" target="_blank">Attachment
                                        - {{leave_detail.attachment_2}}</a>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="col-md-6">

                          <v-row>

                            <v-col cols="12">
                              <label>Remarks on Approval/Disapproval</label>
                              <ckeditor :config="editorConfig" id="description"
                                        v-model="leave_detail.processed_comment"></ckeditor>

                            </v-col>
                            <v-col cols="12">
                              <v-select
                                  :items="statuses"
                                  dense
                                  item-text="title"
                                  item-value="value"
                                  label="Approval Status"
                                  outlined
                                  v-model="leave_detail.status"
                              >

                              </v-select>

                            </v-col>


                          </v-row>
                        </div>
                      </div>

                    </v-card-text>

                    <v-card-actions>
                      <v-divider></v-divider>
                      <v-col class="text-right" cols="12">
                        <v-btn @click="__reset" class="btn btn-standard cancel-btn"
                               depressed>
                          Cancel
                        </v-btn>
                        <v-btn :loading="isBusy" @click="__update"
                               class="text-white ml-2 btn btn-primary"
                               depressed>
                          Save
                        </v-btn>
                      </v-col>

                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog max-width="800px" v-model="detail_dialog">
                  <v-card v-if="leave_detail">
                    <v-toolbar dark>
                      <v-card-title class="headline">
                        Leave request status
                        <hr>
                      </v-card-title>

                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon  @click="detail_dialog=false">
                          <i class="fas fa-times"></i>
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>

                    <v-card-text style="height: 500px;">
                      <v-container>
                        <div class="">


                          <span class="badge badge-warning p-2" v-if="leave_detail.status=='pending'">{{leave_detail.status}}</span>
                          <span class="badge badge-success p-2" v-if="leave_detail.status=='approved'">{{leave_detail.status}}</span>
                        </div>
                        <div class="text-secondary h6">
                          <span class="font-weight-bold ">Title : </span>  <span class="font-weight-medium"> {{leave_detail.title}} </span>
                        </div>
                        <div class="text-secondary h6">
                          <span class="font-weight-bold ">Full name : </span>  <span class="font-weight-medium"> {{leave_detail.user_full_name}} </span><span v-if="leave_detail.user_type">({{leave_detail.user_type}})</span>
                        </div>

                        <div class="mt-5 mb-5">
                          <div class="text-secondary h6">
                            <span class="font-weight-bold">Start date : </span> : <span class="font-weight-medium">{{leave_detail.leave_start_date | moment("Do MMMM YYYY")}}</span>
                          </div>
                          <div class="text-secondary h6">
                            <span class="font-weight-bold">End date : </span> : <span class="font-weight-medium">{{leave_detail.leave_end_date | moment("Do MMMM YYYY")}}</span>
                          </div>
                        </div>
                        <div class="text-secondary h6 mt-2" v-if="leave_detail.status">
                          <span class="font-weight-bold ">Status : </span>  <span class="badge badge-warning p-2" v-if="leave_detail.status=='pending'">{{leave_detail.status}}</span>
                          <span class="badge badge-success p-2" v-if="leave_detail.status=='approved'">{{leave_detail.status_text}}</span>
                          <span class="badge badge-danger p-2" v-if="leave_detail.status=='declined'">{{leave_detail.status_text}}</span>
                          <span class="badge badge-danger p-2" v-if="leave_detail.status=='canceled'">{{leave_detail.status_text}}</span>
                        </div>
                        <hr>
                        <div class="text-secondary">
                          <p class="h6 font-weight-bold">Description</p>
                          <p class="font-weight-regular subtitle-1" v-html="leave_detail.description"></p>
                        </div>

                        <v-row class="mt-5" v-if="leave_detail.attachment_1 || leave_detail.attachment_2 || leave_detail.attachment_3">
                          <v-col cols="12">
                            Attachments
                          </v-col>
                          <v-col cols="12">
                            <v-list-item-group
                                color="primary"
                            >
                              <v-list-item
                                  v-if="leave_detail.attachment_1"
                              >
                                <v-list-item-icon>
                                  <v-icon> mdi-file</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <a :href="leave_detail.attachment_one_path" target="_blank">Attachment
                                      - {{leave_detail.attachment_1}}</a>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                  v-if="leave_detail.attachment_2"
                              >
                                <v-list-item-icon>
                                  <v-icon> mdi-file</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <a :href="leave_detail.attachment_two_path" target="_blank">Attachment
                                      - {{leave_detail.attachment_2}}</a>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                  v-if="leave_detail.attachment_2"
                              >
                                <v-list-item-icon>
                                  <v-icon> mdi-file</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <a :href="leave_detail.attachment_three_path" target="_blank">Attachment
                                      - {{leave_detail.attachment_2}}</a>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-col>
                        </v-row>
                        <div class="text-secondary" v-if="leave_detail.processed_comment">
                          <p class="h6 font-weight-bold">Approved/Declined remarks</p>
                          <p class="font-weight-medium subtitle-1" v-html="leave_detail.processed_comment"></p>
                        </div>
                        <div class="text-secondary" v-if="leave_detail.processed_date">
                          <p class="h6 font-weight-bold">Approved/Declined date</p>
                          <p class="font-weight-medium subtitle-1" v-html="leave_detail.processed_date"></p>
                        </div>
                        <div class="text-secondary" v-if="leave_detail.processed_user">
                          <p class="h6 font-weight-bold"> Approved/Declined By</p>
                          <p class="font-weight-medium subtitle-1" v-html="leave_detail.processed_user"></p>
                        </div>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn   class="mr-2 cancel-btn"
                               text
                               x-large
                               @click="detail_dialog = false">
                        Cancel
                      </v-btn>
                    </v-card-actions>

                  </v-card>
                </v-dialog>
              </v-row>
            </template>
            <create-or-update :user_id="user.id" ref="leave-request" @refresh="__get"></create-or-update>
        </div>
        </div>
      </div>
    </v-app>
</template>
<script>
    import LeaveRequestService from "@/core/services/leave/leave-request/LeaveRequestService";
    import LeaveCategoryService from "@/core/services/leave/leave-category/LeaveCategoryService";
    import CreateOrUpdate from "./CreateOrUpdate.vue"
    const leaveRequestService = new LeaveRequestService();
    const leaveCategoryService = new LeaveCategoryService();
    export default {
        props:['user'],
        name: "leave-category",
        display: "Table",
        order: 8,
        components: {
          CreateOrUpdate
        },
        data() {
            return {
                statuses: [
                    {title: 'Approved', value: "approved"},
                    {title: 'Decline', value: "declined"},
                    {title: 'Pending', value: "pending"},
                ],
                user_type: [
                    {title: 'Student', value: "student"},
                    {title: 'Teacher', value: "teacher"},
                    {title: 'Staff', value: "staff"},
                ],
                leave_catgories: [],

                editorConfig: {
                  versionCheck: false,
                    toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
                },
                leave_requests: [],
                page: null,
                leave_detail: null,
                attrs: false,
                loadingData: false,
                loading: false,
                isBusy: false,
                startDate: false,
                endDate: false,
                leave_detail_dialog: false,
                detail_dialog: false,
                perPage: null,
                total: null,
                search: {
                    status: "",
                    requested_by: "",
                    start_date: "",
                    end_date: "",
                    approved_by: ""
                },
                requestedByValues:[
                  {
                    name: 'Admin',
                    value: 'admin'
                  },
                  {
                    name: 'Student',
                    value: 'student'
                  },
                  {
                    name: 'Teacher',
                    value: 'teacher'
                  },
                  {
                    name: 'Parent',
                    value: 'parent'
                  },
                ]
            };
        },
        mounted() {
            this.search.user_id = this.user_id;
            this.__get();
            this.__getLeaveCategories();

        },
        computed: {
            user_id() {
                return this.$route.params.id ? this.$route.params.id : false;
            },
        },
        methods: {
            __viewDetail(leave) {
              this.detail_dialog = true;
              this.leave_detail = leave;

            },
            getApplicable_users(users) {
                return users.split(',');
            },
            leavestatus(status) {
                if (status) {
                    switch (status) {
                        case 'pending':
                            return  'badge-warning';
                            break;
                        case 'approved':
                            return  'badge-success';
                            break;
                        case 'declined':
                            return  'badge-danger';
                            break;

                    }
                } else {
                    return 'badge-warning'
                }
            },
            __get() {
                this.loadingData = true;
                leaveRequestService
                    .paginate(this.search)
                    .then(response => {
                        this.leave_requests = response.data.data;
                        this.page = response.data.meta.current_page;
                        this.total = response.data.meta.total;
                        this.perPage = response.data.meta.per_page;
                    })
                    .catch(error => {
                        // console.log(error);
                    }).finally(() => {
                    this.loadingData = false;

                });
            },

            __detail(leave,status='pending') {
                this.leave_detail_dialog = true;
                this.leave_detail = leave;
                this.leave_detail.status = status;

            },
            __reset() {
                this.leave_detail_dialog = false;
                this.leave_detail = {};

            },

            __getLeaveCategories() {
                leaveCategoryService
                    .paginate()
                    .then(response => {
                        this.leave_catgories = response.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },

            resetFilter() {
                this.search = {};
                this.search.status = "pending";
                this.__get();
            },
            createLeaveRequest(){
              this.$refs['leave-request'].createLeaveRequest();
            },
            editLeaveRequest(id){
              this.$refs['leave-request'].editLeaveRequest(id);
            },
          __delete(id) {
            this.$confirm({
              message: `Are you sure?`,
              button: {
                no: "No",
                yes: "Yes"
              },
              /**
               * Callback Function
               * @param {Boolean} confirm
               */
              callback: confirm => {
                if (confirm) {
                  leaveRequestService
                      .delete(id)
                      .then(response => {
                        this.$snotify.success('Successfully deleted')
                        this.__get();
                      })
                      .catch(error => {
                        //console.log(error);
                      });
                }
              }
            });
          },
          __update() {
            let fd = this.leave_detail;
            this.isBusy = true;
            leaveRequestService
                .update(this.leave_detail.id, fd)
                .then(response => {
                  this.isBusy = false;
                  this.$snotify.success("Information updated");
                  this.__reset();
                  this.__get();
                })
                .catch(error => {
                  this.isBusy = false;
                  // console.log(error);
                }).finally(() => {
              this.isBusy = false;
            });
          },
        }
    };
</script>
<style lang="css">
.badge-danger {
  color: #ffffff !important;
  background-color: #F64E60 !important;
}
</style>
