<template>
  <v-app>
    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header py-5 px-5">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">Grades</h3>

        </div>

      </div>
      <!--end::Header-->
      <!--begin::Form-->
      <v-skeleton-loader
          v-if="loading"
          v-bind="attrs"
          type=" table, table-tfoot"
      ></v-skeleton-loader>

      <div class="card-body px-11 py-12" v-if="!loading && grades && grades.length">

        <div v-for="(grade, index) of grades" :key="index" class="mb-5 ">
          <div class="row" v-b-toggle="'collapse-'+index" style="background-color:#e7f0f3">


            <div class="col-md-3 col-lg-3 col-sm-12">
              <b>Academic year:</b> {{ grade ? grade.academic_year.title : null }} <br>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12">
              <b>Program :</b> {{ grade ? grade.program.title : null }} <br>
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12">
              <b>Grade :</b> {{ grade ? grade.grade.title : null }} <br>
            </div>

            <div class="col-md-2 col-lg-3 col-sm-12">
              <b>Start date :</b> {{ grade ? grade.start_date : null }} <br>
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12">
              <b>Completion date :</b> {{ grade && grade.is_complete ? grade.complete_date : 'Running' }}
              <br>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12">
              <b>Status :</b> <span class="font-weight-bold text-capitalize">{{ grade.status }}</span>
              <br>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12">
              <v-select
                  outlined
                  dense
                  v-model="grade.status"
                  :items="status"
                  item-text="title"
                  item-value="value"
                  label="Status"
              >

              </v-select>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12">
              <v-btn depressed :loading="isBusy" @click="updateGrades(grade)"
                     class="text-white ml-2 btn btn-primary ">
                Save
              </v-btn>

            </div>


          </div>

<!--          <div class="row">
            <div class="col-md-12 col-lg-12">
              <b-collapse :visible="checkFirstAccordin(index)" v-bind:id="'collapse-'+index">
                <b-card>
                  <table class="table"
                         v-if="grade.compulsory_courses_info || grade.optional_courses_info">
                    <thead>
                    <tr>
                      <td>Course title</td>
                      <td>Course code</td>
                      <td>Course type</td>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(compulsoryCourse,index2) of grade.compulsory_courses_info"
                        :key="`compulsory-${index2}`">
                      <td>{{ compulsoryCourse.title }}</td>
                      <td>{{ compulsoryCourse.subject_code }}</td>
                      <td class="badge badge-primary">Compulsory</td>
                    </tr>

                    <tr v-for="(optionalCourse,index3) of grade.optional_courses_info"
                        :key="`optional-${index3}`">
                      <td>{{ optionalCourse.title }}</td>
                      <td>{{ optionalCourse.subject_code }}</td>
                      <td class="badge badge-warning">Optional</td>
                    </tr>

                    </tbody>
                  </table>
                </b-card>
              </b-collapse>
            </div>

          </div>-->
        </div>
      </div>
      <div class="card-body" v-show="!loading && grades && grades.length<1">
        <h3 class="text-warning">No grade record found</h3>
        <p>Grade list will build up as student progress their class with different subject.</p>
      </div>


      <!--end::Form-->
    </div>
  </v-app>

</template>

<script>
import {required} from "vuelidate/lib/validators";
import UserService from "@/core/services/user/UserService";
import JobPositionService from "@/core/services/job-position/JobPositionService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import StudentSettingService from "@/core/services/user/student/StudentSettingService";
import ConfigService from "@/core/services/config/ConfigService";

const studentSettingService = new StudentSettingService();
const userService = new UserService();
const positionService = new JobPositionService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService()
const enrollmentService = new UserEnrollmentService();
const configService = new ConfigService();
export default {
  name: "Grades",
  props: ['user', 'userId'],
  validations() {
    return {
      user: this.rules,
    };
  },
  data() {
    return {
      attrs: '',
      isBusy: false,
      loading: false,
      sizeExceed: false,
      programs: [],
      grades: [],
      academicYears: [],
      gradesLevels: [],
      academic_classes: [],
      levels: [],
      levelId: null,
      menudob: false,
      menu2: false,
      menu3: false,
      menu: false,
      date: new Date().toISOString().substr(0, 10),
      studentSetting: {
        academic_year_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        class_id: null,
        payment_frequency: 'Monthly',

      },

      status: [
        {
          title: "Complete",
          value: 'complete'
        },
        {
          title: "Current",
          value: 'current'
        },
        {
          title: "Leaved",
          value: 'leaved'
        },
        {
          title: "Failed",
          value: 'failed'
        },
        {
          title: "Switched",
          value: 'switched'
        },
      ],

    };
  },

  mounted() {
    this.getGrades()

    // this.userId ? this.getUser(this.userId) : "";


  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : false;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    rules() {
      let rule = {
        title: {required},
        first_name: {required},
        last_name: {required},
        type: {required}
      };
      if (!this.id) {
        rule.email = {required};
        rule.phone = {required};
      }
      return rule;
    }
  },
  methods: {
    getUser(id) {
      userService.show(id).then(response => {
        this.user = response.data.user;
        this.getGrades();
        this.user.is_active ? (this.user.is_active = true) : false;
        this.user.is_email_verified
            ? (this.user.is_email_verified = true)
            : false;
        if (this.user.setting) {
          this.studentSetting = this.user.setting
          this.edit = true;

        }


      });
    },

    getGrades() {
      this.loading = true;
      studentSettingService.getGrades(this.userId).then(response => {
        this.grades = response.data.data;
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
      });
    },

    save(date) {
      this.$refs.menu2.save(date);
    },
    convertAdToBs() {
      let data = {
        dob: this.user.dob
      };
      enrollmentService
          .convertAdToBs(data)
          .then(response => {
            this.user.dob_bs = response.data.dob;
          })
          .catch(error => {
            //console.log(error);
          });
    },
    redirectTo() {
      this.$router.push({name: "user"});
    },
    isAccessible(roles) {
      let flag = [];
      let type = this.currentUser.type;
      let userRoles = configService.getRoles(type);
      if (roles != null && roles != undefined && userRoles != null && userRoles != undefined) {
        roles = roles.split(",");
        for (let t of roles) {
          t = t.trim();
          if (userRoles.includes(t)) {
            flag.push(true);
          } else {
            flag.push(false);
          }
        }
        if (flag.includes(true)) {
          return true;
        }
      }
      return false;
    },
    updateGrades(grade) {
      this.isBusy = true,
          studentSettingService.updateGrade(this.user.id, grade.id, grade).then(response => {
            this.$snotify.success("Student Record Changed");
            this.getGrades();
          }).catch(error => {
            this.$snotify.error("Something went Wrong");
          }).finally(() => {
            this.isBusy = false;
          });
    },

    createOrUpdate() {
      if (this.studentSetting.id) {
        this.updateSetting();
      } else {
        this.saveStudent();
      }
    },
    saveStudent() {
      this.isBusy = true;
      studentSettingService
          .store(this.user.id, this.studentSetting)
          .then(response => {
            this.isBusy = false;
            this.getUser(this.id)
            this.$snotify.success("Student Record added");
            // this.$router.push({name: "students"});
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
          });
    },

    checkFirstAccordin(index) {
      return index == 0 ? true : false;
    },

    updateSetting() {
      this.isBusy = true;
      studentSettingService
          .update(this.user.id, this.studentSetting.id, this.studentSetting)
          .then(response => {
            this.isBusy = false;
            this.getUser(this.id)
            this.$snotify.success("Student Record updated");
            // this.$router.push({name: "students"});
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
          });

    },


  }
};
</script>
<style scoped>
.form-control {
  border: none !important;
}
</style>
