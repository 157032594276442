<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card ">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>
                                    Manage scholarship
                                </h4>

                            </div>
                            <div class="breadcrumb-right" v-if="isAccessible('admin,finance')">
                                <v-btn v-if="isAccessible('admin,finance')" class="mt-4 btn btn-primary"
                                    style="color: #fff"
                                    @click="assignScholarship(user.id)"
                                >
                                    <i class="fa fa-plus"></i>
                                    Assign scholarship
                                </v-btn>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="">
                            <v-skeleton-loader v-if="loading" type="table-thead">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>

                            <table class="table" v-if="!loading">
                                <thead>
                                    <tr class="px-3 wrap-column">
                                        <th class="px-3 wrap-column">Scholarship</th>
                                        <th class="px-3 wrap-column">Remarks</th>
                                        <th class="px-3 wrap-column">Granted date</th>
                                        <th class="pr-3 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="studentScholarships.length > 0" v-for="(item, index) of studentScholarships" :key="item.id">
                                        <td class="px-3 wrap-column">
                                            {{ item.scholarship_name ? item.scholarship_name : 'NA' }}
                                            <div v-if="item.program_name" class="mt-2">
                                                <strong>
                                                    Program:
                                                </strong>
                                                {{ item.program_name }}
                                            </div>

                                            <div v-if="item.academic_year_name" class="mt-2">
                                                <strong>
                                                    Academic year:
                                                </strong>
                                                {{ item.academic_year_name }}
                                            </div>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            <div v-if="item.remarks" v-html="item.remarks"></div>
                                            <div v-else>NA</div>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            {{ item.granted_date_formatted ? item.granted_date_formatted : '-'}}
                                        </td>
                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link" @click="deleteStudentScholarship(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-delete"></i>
                                                            </span>
                                                            <span class="navi-text"> Delete </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr v-if="studentScholarships.length == 0">
                                        <td class="text-center px-3" colspan="4">Data not available</td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination
                                v-show="studentScholarships.length > 0"
                                @input="getStudentScholarships"
                                class="pull-right mt-7"
                                v-model="page"
                                :total-rows="total"
                                :per-page="perPage"
                                first-number
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-and-update ref="scholarship" @refresh="getStudentScholarships"></create-and-update>
    </v-app>
</template>
<script>
import ConfigService from "@/core/services/config/ConfigService";
import StudentScholarshipService from "@/core/services/scholarship/student-scholarship/StudentScholarshipService";
import CreateAndUpdate from "./CreateAndUpdate";

const configService = new ConfigService();
const studentScholarship = new StudentScholarshipService();

export default {
  props: ['user'],
  components: { CreateAndUpdate },
  data() {
      return {
          loading: false,
          search: {
              student_id: null
          },
          studentScholarships: [],
          page: null,
          perPage: null,
          total: null,
      };
  }, computed: {
      currentUser() {
          return this.$store.getters.currentUser;
      },

  },
  mounted() {
      if (this.user) {
          this.search.student_id = this.user.id;
      }
      this.getStudentScholarships();
  },
  methods: {
      closeDialog() {
          this.getStudentScholarships()
          this.dialog = false
      },

      isAccessible(roles) {
          let flag = [];
          let type = this.currentUser.type;
          let userRoles = configService.getRoles(type);
          if (roles != null && roles != undefined && userRoles != null && userRoles != undefined) {
              roles = roles.split(",");
              for (let t of roles) {
                  t = t.trim();
                  if (userRoles.includes(t)) {
                      flag.push(true);
                  } else {
                      flag.push(false);
                  }
              }
              if (flag.includes(true)) {
                  return true;
              }
          }
          return false;
      },

      assignScholarship(userId) {
          const programId = this.user.setting ? this.user.setting.program_id : null;
          const academicYearId = this.user.setting ? this.user.setting.academic_year_id : null;
          this.$refs['scholarship'].showModal(userId, programId, academicYearId);
      },

      getStudentScholarships() {
          this.loading = true;
          studentScholarship
              .paginate(this.search, this.page)
              .then(response => {
                  this.studentScholarships = response.data.data;
                  this.page = response.data.meta.current_page;
                  this.total = response.data.meta.total;
                  this.perPage = response.data.meta.per_page;
                  this.loading = false;
              })
              .catch(error => {
                  this.loading = false;
              });
      },

      deleteStudentScholarship(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            studentScholarship
                .delete(id)
                .then(response => {
                  this.$snotify.success("Scholarship removed.");
                  this.getStudentScholarships();
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    },
  }
};
</script>
<style scoped>
    .wrap-column{
        max-width: 270px !important;
        white-space: pre-wrap;
    }
</style>
