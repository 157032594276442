import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class StudentParentService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/user`;

      }

      paginate(userId) {
            let url = `${this.#api}/${userId}/parent`;
            return apiService.get(url);
      }

      update(userId,parentId, data) {
            let url = `${this.#api}/${userId}/parent/${parentId}`
            return apiService.put(url, data);

      }

      store(userId, data) {
            let url = `${this.#api}/${userId}/parent`
            return apiService.post(url, data);

      }

      show(userId, parentId) {
            let url = `${this.#api}/${userId}/parent/${parentId}`
            return apiService.get(url);
      }

      delete(userId, parentId) {
            let url = `${this.#api}/${userId}/parent/${parentId}`
            return apiService.delete(url);
      }
      createStudentParent(data){
            let url = `${API_URL}user/create/student-parent-information`;
            return apiService.post(url, data);
      }
      updateStudentParent(id,data){
            let url = `${API_URL}user/update/${id}/student-parent-information`;
            return apiService.post(url, data);
      }
     linkStudentParent(id,data){
            let url = `${API_URL}user/link/${id}/student-parent-information`;
            return apiService.post(url, data);
      }
        unlinkStudentParent(id,data){
            let url = `${API_URL}user/unlink/${id}/student-parent-information`;
            return apiService.post(url, data);
        }
      getStudentParentInformation(userId){
            let url = `${API_URL}user/get/${userId}/student-parent-information`;
            return apiService.get(url);
      }
      deleteStudentParentInformation(id,data){
            let url = `${API_URL}user/delete/${id}/student-parent-information`;
            return apiService.delete(url, data);
      }
      searchAllParentsInformation(data={}){

            let url = `${API_URL}user/search/all/parents-information`;
            let param = {params: data};
            return apiService.query(url, param);
      }
      searchParentsInformation(data={}){

            let url = `${API_URL}user/search/parents-information`;
            let param = {params: data};
            return apiService.query(url, param);
      }
      checkDuplicateEmail(data={}){
          let url = `${API_URL}user/parent-information/check-duplicate-email`;
          return apiService.post(url,data);
      }

      changePassword(id, data){
          let url = `${API_URL}user/parent-information/${id}/change-password`;
          return apiService.post(url, data);
      }
}
