<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="600">

      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            Additional fees
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>

            <v-col cols="12">
              <v-select
                  v-model="additional_fee.student_fee_id"
                  label="Select a fee"
                  :items="studentFees"
                  item-text="title"
                  item-value="id"
                  outlined
                  dense
              ></v-select>
              <span class="text-danger" v-if="$v.additional_fee.student_fee_id.$error"> Heading  is required</span>
            </v-col>

            <v-col cols="12">
              <v-select v-model="additional_fee.financial_transaction_head_id" label="Transaction heading"
                        :items="financial_heads"
                        item-text="title" item-value="id"
                        outlined dense
                        @change="getPaymentType(additional_fee.financial_transaction_head_id)"></v-select>
              <span class="text-danger" v-if="$v.additional_fee.financial_transaction_head_id.$error"> Heading  is required</span>
            </v-col>

            <v-col cols="12">
              <v-select v-model="additional_fee.financial_payment_type_id" label="Payment type"
                        @change="getPaymentTypeById"
                        :items="financial_payment_types" item-text="title" item-value="id"
                        outlined dense></v-select>
              <span class="text-danger" v-if="$v.additional_fee.financial_payment_type_id.$error"> Payment Type is required</span>
            </v-col>

            <v-col cols="12">
<!--              <label for="fee">Total fee</label>-->
              <v-text-field
                  outlined
                  dense
                  type="number"
                  v-model="additional_fee.total_fee"
                  prefix="Rs."
                  label="Total fee"
              >

              </v-text-field>
              <span class="text-danger" v-if="$v.additional_fee.total_fee.$error">Total fee is required and must be numeric</span>
            </v-col>

<!--            <v-col cols="12">-->
<!--              <v-switch-->
<!--                  v-model="additional_fee.is_recurring"-->
<!--                  label="Recurring payment"-->
<!--              >-->
<!--              </v-switch>-->
<!--            </v-col>-->
            <v-col cols="6" v-if="additional_fee.is_recurring">
              <v-select
                  :items="recurring_types"
                  label="Recurring type"
                  item-value="value"
                  item-text="title"
                  outlined
                  dense
                  v-model="additional_fee.recurring_type"
              >
              </v-select>
            </v-col>
            <v-col cols="6" v-if="additional_fee.is_recurring">
              <v-text-field
                  outlined
                  dense
                  v-model="additional_fee.recurring_frequency"
                  label="No. of repayments"
              >
              </v-text-field>
            </v-col>

<!--            <v-col cols="12">-->
<!--              <v-menu-->
<!--                  v-model="menu2"-->
<!--                  :close-on-content-click="false"-->
<!--                  :nudge-right="40"-->
<!--                  transition="scale-transition"-->
<!--                  offset-y-->
<!--                  min-width="auto"-->
<!--              >-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <v-text-field-->
<!--                      v-model="additional_fee.recurring_start_from"-->
<!--                      label="Billing start date"-->
<!--                      prepend-inner-icon="mdi-calendar"-->
<!--                      readonly-->
<!--                      v-bind="attrs"-->
<!--                      outlined dense-->
<!--                      v-on="on"-->
<!--                  ></v-text-field>-->
<!--                </template>-->
<!--                <v-date-picker-->
<!--                    v-model="additional_fee.recurring_start_from"-->
<!--                    @input="menu2 = false"-->
<!--                ></v-date-picker>-->
<!--              </v-menu>-->
<!--            </v-col>-->

            <v-col cols="4">
              <v-switch
                  v-model="additional_fee.is_active"
                  label="Status"
              >
              </v-switch>
            </v-col>
          </v-row>


          <div class="col-12 text-right mt-4">
            <v-btn
                class="btn btn-standard cancel-btn"
                depressed
                @click="closeDialog"
            >Cancel
            </v-btn>
            <v-btn
                class="text-white ml-2 btn btn-primary"
                depressed
                @click="createAndUpdate"
                :loading="isBusy"
            >{{edit ? "Update" : "Save"}}
            </v-btn>

          </div>
        </v-card-text>

      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import {numeric, required} from "vuelidate/lib/validators";
import AdditionalFeeService from "@/core/services/fee/addtionalFee/AdditionalFeeService";
import FinancialTransactionHeadsService from "@/core/services/finance/FinancialTransactionHeadsService";
import FinancialPaymentTypeService from "@/core/services/finance/finance-payment-type/FinancialPaymentTypeService";

const financialTransactionHeadsService = new FinancialTransactionHeadsService();
const paymentTypeService = new FinancialPaymentTypeService();
const additionalFeeService = new AdditionalFeeService();
export default {
  name: "additional-fee-create-and-update",
  props: ['studentFees'],
  validations: {
    additional_fee: {
      student_fee_id: {required},
      financial_transaction_head_id: {required},
      financial_payment_type_id: {required},
      total_fee: {required, numeric},
    }
  },
  data() {
    return {
      dialog: false,
      menu2: false,
      isBusy: false,
      edit: false, financial_heads: [],
      financial_payment_types: [],
      recurring_types: [
        {title: 'Daily', value: 'daily'},
        {title: 'Weekly', value: 'weekly'},
        {title: 'Bi-weekly', value: 'by-weekly'},
        {title: 'Monthly', value: 'monthly'},
        {title: 'Semester', value: 'semester'},
        {title: 'Yearly', value: 'yearly'},
      ],
      additional_fee: {
        total_fee: null,
        is_recurring: null,
        student_cancellable: null,
        is_active: true,
        financial_transaction_head_id: null,
        financial_payment_type_id: null,
        recurring_type: null,
        recurring_frequency: null,
        recurring_start_from: null,
      },
    };
  },
  computed: {
    userId() {
      return this.$route.params.id ? this.$route.params.id : false;
    }
  },
  mounted() {
    this.getAllActiveTransactionHead();
  },
  methods: {

    get(id) {
      additionalFeeService.show(this.userId, id).then(response => {
        this.edit = true;
        this.additional_fee = response.data.additionalFee;
        this.getPaymentType(this.additional_fee.financial_transaction_head_id)
      });
    },
    showModal(id = null) {
      this.resetForm();
      if (id) {
        this.get(id);
      }
      this.dialog = true;
    },

    getAllActiveTransactionHead() {
      financialTransactionHeadsService
          .allActiveByType('additional_fee')
          .then(response => {
            this.financial_heads = response.data;
          })
          .catch(error => {
            // console.log(error);
          });
    },


    getPaymentType(headingId) {
      paymentTypeService
          .allActiveByTransactionHead(headingId)
          .then(response => {
            this.financial_payment_types = response.data
          })
          .catch(error => {
            // console.log(error);
          });
    },
    getPaymentTypeById() {
      paymentTypeService
          .show(this.additional_fee.financial_payment_type_id)
          .then(response => {
            let paymentType = response.data.paymentType;
            this.additional_fee.total_fee = paymentType.amount
            this.calculateTotal();
          })
          .catch(error => {
            // console.log(error);
          });
    },
    createAndUpdate() {
      this.$v.$touch();
      if (this.$v.additional_fee.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.update();
        } else {
          this.create();
        }
      }
    },

    create() {
      this.isBusy = true;
      additionalFeeService.store(this.userId, this.additional_fee).then(response => {
        if(response.data.status == "OK") {
          this.$snotify.success("Information added");
          this.$emit('refresh');
          this.closeDialog()
        }
        if(response.data.status == "Already exist") {
          this.$snotify.error("Already, this fee has been included.");
        }
      }).catch(error => {
        this.$snotify.error("Something went wrong");
      }).finally(this.isBusy = false);
    },
    update() {
      this.isBusy = true;
      additionalFeeService.update(this.userId, this.additional_fee.id, this.additional_fee).then(response => {
        this.resetForm()
        this.$snotify.success("Information updated");
        this.$emit('refresh')
      }).catch(error => {
        this.$snotify.error("Something went wrong");
        // console.log(error)
      }).finally(this.isBusy = false);
    },

    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },

    resetForm() {
      this.isBusy = false;
      this.edit = false;
      this.$v.$reset();
      this.additional_fee = {
        total_fee: null,
        is_recurring: null,
        student_cancellable: null,
        is_active: true,
        financial_transaction_head_id: null,
        financial_payment_type_id: null,
        recurring_type: null,
        recurring_frequency: null,
        recurring_start_from: null,
      }

    }
  }
};
</script>
