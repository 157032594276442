<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="900" scrollable>
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
          Link {{ type }} information
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="12" v-if="parentStatus=='ERROR'">
                <v-alert
                    outlined
                    color="deep-orange" type="warning"
                    prominent
                    border="left">
                  Sorry we couldn't find any students.
                </v-alert>
              </v-col>
              <v-col md="12" v-if="parentStatus=='SUCCESS' && parentDetail">
                <div class="text-secondary subtitle-1">
                  <span class="font-weight-bold">Student name :</span> <span class="font-weight-medium">{{parentDetail.full_name}}</span> <span class="font-weight-medium" v-if="parentDetail.personal_id">({{ parentDetail.personal_id }} )</span>
                </div>
                <div class="text-secondary subtitle-1">
                  <span class="font-weight-bold">Email :</span> <span class="font-weight-medium">{{parentDetail.email || 'N/A'}}</span>
                </div>
                <div class="text-secondary subtitle-1">
                  <span class="font-weight-bold">Phone :</span> <span class="font-weight-medium"> {{ parentDetail.phone || 'N/A' }}</span>
                </div>
                <div class="text-secondary subtitle-1">
                  <span class="font-weight-bold">Mobile :</span> <span class="font-weight-medium"> {{ parentDetail.mobile || 'N/A' }}</span>
                </div>
              </v-col>
              <v-col md="12" v-if="search_by_email && !search_by_phone">
                <v-text-field v-on:keyup.enter="searchParentInformation()" dense outlined v-model="search_parent_information.email" :error="$v.search_parent_information.email.$error" label="Search by email"></v-text-field>
                <span class="text-danger" v-if="$v.search_parent_information.email.$error">This information is required</span>
              </v-col>
              <v-col md="12" v-if="search_by_phone && !search_by_email">
                <v-text-field v-on:keyup.enter="searchParentInformation()" dense outlined v-model="search_parent_information.phone" :error="$v.search_parent_information.phone.$error" label="Search by phone"></v-text-field>
                <span class="text-danger" v-if="$v.search_parent_information.phone.$error">This information is required</span>
              </v-col>
              <v-col md="12">
                <v-checkbox @click="changeSearchOption" v-model="is_email">
                  <template v-slot:label>
                    <div>{{is_email ? 'Search by phone' : 'Search by email'}}</div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              class="cancel-btn"
              x-large
              @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn
                 color="btn btn-primary"
                 depressed
                 :loading="parentInformationLoading"
                 @click="searchParentInformation()"
          >
            <v-icon small elevation="2" outlined>fas fa-search</v-icon> Search
          </v-btn>
          <v-btn v-if="parentStatus=='SUCCESS' && parentDetail"
                 class="text-white ml-2 btn btn-primary"
                 depressed
                 @click="linkUserParent"
                 :searchLoading="linkLoading"> Link
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import StudentParentService from "@/core/services/user/student/StudentParentService";
import {required,requiredIf} from "vuelidate/lib/validators";
const studentParent=new StudentParentService();
export default {
  props:['user'],
  validations:{
    search_parent_information:{
      email: {
        required: requiredIf(function(nestedModel){
          return this.is_email;
        })
      },
      phone: {
        required: requiredIf(function(nestedModel){
          return !this.is_email;
        })
      },
    }
  },
  data(){
    return{
      parentInformationLoading:false,
      linkLoading:false,
      parentsLoading:false,
      dialog:false,
      type:null,
      parents:[],
      parentInformationSearch:null,
      search_parent_information:{
        email:null,
        phone:null,
        type:null,
      },
      search_by_email:true,
      search_by_phone:false,
      is_email:true,
      parentDetail:null,
      parentStatus:null,

    }
  },
  methods:{
    openDialog(){
      this.dialog=true;
    },
    closeDialog(){
      this.dialog=false;
      this.resetData();
    },
    resetData(){
      this.$v.$reset();
      this.type=null;
      this.search_parent_information={
        email:null,
        phone:null,
        type:this.type,
      };
      this.search_by_email=true;
      this.search_by_phone=false;
      this.is_email=true;
      this.parentDetail=null;
      this.parentStatus=null;
    },
    linkParentInformation(type) {
      this.type=type;
      this.openDialog();
      this.changeSearchOption();
    },

    searchParentInformation(){
      this.search_parent_information.type=this.type;
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.parentInformationLoading = true;
        studentParent
            .searchParentsInformation(this.search_parent_information)
            .then(response => {
              this.parentStatus = response.data.status;
              if (this.parentStatus == 'ERROR') {
                this.parentDetail = null;
              }
              if (this.parentStatus == 'SUCCESS') {
                this.parentDetail = response.data.parent;
              }
            })
            .catch((err) => {
            })
            .finally(() => {
              this.parentInformationLoading = false;
            });
      }
    },
    changeSearchOption(){
      if(this.is_email){
        this.search_by_phone=false;
        this.search_by_email=true;
        this.search_parent_information.phone=null;
      }
      if(!this.is_email){
        this.search_by_phone=true;
        this.search_by_email=false;
        this.search_parent_information.email=null;
      }
    },
    linkUserParent(){
      this.linkLoading=true;
      studentParent
          .linkStudentParent(this.parentDetail.id,{user_id:this.user.id})

          .then(response => {
            this.closeDialog();
            this.$emit('refresh');
            this.$snotify.success("Parent has been linked successfully");
          })
          .catch((err) => {
          })
          .finally(() => {
            this.linkLoading=false;
          });
    },


  },
  watch:{

  },
}
</script>