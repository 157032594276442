<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        {{ `${edit ? 'Update' : "Add"} parent meet` }}
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="hideModal">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>

                    <div class="row">
                        <v-col cols="12">
                            <v-text-field
                            outlined
                            dense
                            :error="$v.studentParentsVisitLog.title.$error"
                            v-model="studentParentsVisitLog.title"
                            >
                            <template v-slot:label>
                                Title
                                <span class="text-danger">*</span>
                            </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.studentParentsVisitLog.title.$error"
                            >This information is required</span
                            >
                        </v-col>
                    </div>

                    <div class="row">
                        <v-col cols="12" md="12">
                            <v-select
                            :items="meeting_types"
                            item-text="title"
                            item-value="value"
                            v-model="studentParentsVisitLog.meeting_type"
                            outlined
                            dense
                            :error="$v.studentParentsVisitLog.meeting_type.$error"
                            placeholder="Meeting type"
                            >
                            <template v-slot:label>
                                Meeting type
                                <span class="text-danger">*</span>
                            </template>
                            </v-select>
                            <span
                            class="text-danger"
                            v-if="$v.studentParentsVisitLog.meeting_type.$error"
                            >This information is required</span
                            >
                        </v-col>

                        <v-col cols="12" v-if="studentParentsVisitLog.meeting_type == 'online'">
                            <v-text-field
                            outlined
                            dense
                            placeholder="Meeting link"
                            :error="$v.studentParentsVisitLog.online_meeting_link.$error"
                            v-model="studentParentsVisitLog.online_meeting_link"
                            >
                            <template v-slot:label>
                                Meeting link
                                <span class="text-danger">*</span>
                            </template>
                            </v-text-field>
                            <span
                            class="text-danger"
                            v-if="$v.studentParentsVisitLog.online_meeting_link.$error"
                            >This information is required</span
                            >
                        </v-col>

                        <v-col
                            cols="12"
                            v-if="studentParentsVisitLog.meeting_type == 'outside_premise'"
                        >
                            <v-text-field
                            outlined
                            dense
                            :error="$v.studentParentsVisitLog.outside_premise_location.$error"
                            placeholder="Premise location"
                            v-model="studentParentsVisitLog.outside_premise_location"
                            >
                            <template v-slot:label>
                                Premise location
                                <span class="text-danger">*</span>
                            </template>
                            </v-text-field>
                            <span
                            class="text-danger"
                            v-if="$v.studentParentsVisitLog.outside_premise_location.$error"
                            >This information is required</span
                            >
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-datetime-picker
                            outlined
                            dense
                            ref="dateTime"
                            label="Datetime requested to visit"
                            :text-field-props="textFieldProps"
                            :datePickerProps="datePickerProps"
                            v-model="studentParentsVisitLog.datetime_requested_to_visit"
                            >
                            </v-datetime-picker>
                            <span
                            class="text-danger"
                            v-if="$v.studentParentsVisitLog.datetime_requested_to_visit.$error"
                            >This information is required</span
                            >
                        </v-col>

                        <v-col cols="12" md="12">
                            <label>
                                Request for visit reason  <span class="text-danger">*</span>
                            </label>
                            <ckeditor
                                v-model="studentParentsVisitLog.request_for_visit_reason"
                                :config="editorConfig"
                            ></ckeditor>
                            <span class="text-danger" v-if="$v.studentParentsVisitLog.request_for_visit_reason.$error">This information is required</span>
                            <div class="text-danger" v-if="errors.request_for_visit_reason">{{ errors.request_for_visit_reason[0] }}</div>
                        </v-col>
                    </div>

                    <div class="row">
                    <div class="col-12 text-right">
                        <v-btn class="text-gray btn btn-standard" depressed @click="hideModal"
                        >Cancel
                        </v-btn>
                        <v-btn
                            class="text-white ml-2 btn btn-primary"
                            depressed
                            @click="createOrUpdate"
                            :loading="isBusy"
                            v-if="checkIsAccessible('parent-meet', 'create') || checkIsAccessible('parent-meet', 'edit')"
                        >Save
                        </v-btn>
                    </div>
                    </div>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import StudentParentsVisitLogsService from "@/core/services/student-parents-visit-logs/StudentParentsVisitLogsService";
import UserService from "@/core/services/user/UserService";
import UserParentService from "@/core/services/user/UserParentService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";

const user = new UserService();
const userParent = new UserParentService();
const parentMeet = new StudentParentsVisitLogsService();
const academicClass = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService()
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();


export default {
  validations: {
    studentParentsVisitLog: {
        title: { required },
        user_type: { },
        user_id: {required},
        meeting_type: { required },
        online_meeting_link: { required: requiredIf(function(){
                if(this.studentParentsVisitLog.meeting_type == 'online')
                    return true;
                return false;
            })
        },
        outside_premise_location: { required: requiredIf(function(){
                if(this.studentParentsVisitLog.meeting_type == 'outside_premise')
                    return true;
                return false;
            })
        },
        datetime_requested_to_visit: { required },
        request_for_visit_reason: {required}
    },
  },
  data() {
    return {
      dialog: false,
      isBusy: false,
      menu: false,
      menu1: false,
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
      },
      studentParentsVisitLog: {
        user_type: "student",
        user_parent_id: null,
        user_id: null,
        datetime_requested_to_visit: null,
      },
      errors: [],
      attendedByUserSearch: "",
      isAttendedByUserLoading: false,
      attendedByUsers: [],
      textFieldProps: {
        outlined: true,
        dense: true,
      },
      datePickerProps: {
        min: ''
      },
      timePickerProps: {
        min: ''
      },
      meeting_types: [
        {
          title: "Online",
          value: "online",
        },
        {
          title: "In premise",
          value: "in_premise",
        },
        {
          title: "Outside premise",
          value: "outside_premise",
        },
      ],
      currentDateTime: new Date(),
      edit: false,
      userId: null,
      studentParentsVisitLogId: null,
    };
  },

  methods: {
    showModal(userId, id = null) {
        this.resetForm();
        if (id) {
            this.edit = true
            this.getCategory(id)
        }else{
            this.edit = false;
            this.studentParentsVisitLog.user_id = userId;
        }
        this.setMinDate()
        this.setMinTime()
        let today = new Date();
        this.dialog = true;
    },
    hideModal() {
        this.dialog = false;
    },
    createOrUpdate() {
      this.$v.studentParentsVisitLog.$touch();
      if (this.$v.studentParentsVisitLog.$error) {
        setTimeout(() => {
          this.$v.studentParentsVisitLog.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateStudentParentsVisitLog();
        } else {
          this.createStudentParentsVisitLog();
        }
      }
    },
    updateStudentParentsVisitLog() {
      this.isBusy = true;
      parentMeet
        .update(this.studentParentsVisitLog.user_id, this.studentParentsVisitLog)
        .then((response) => {
          this.isBusy = false;
          this.$emit('refresh');
          this.$snotify.success("Information updated");
          this.hideModal();
        })
        .catch((error) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },
    createStudentParentsVisitLog() {
      this.isBusy = true;

      this.formatRequestedDateTime();
      this.studentParentsVisitLog.status = 'requested';

      parentMeet
        .store(this.studentParentsVisitLog)
        .then((response) => {
          this.isBusy = false;
          this.$emit('refresh');
          this.hideModal();
          this.$snotify.success("Information added");
        })
        .catch((error) => {
          this.isBusy = false;
          this.errors = error.errors;
          this.$snotify.error("Something Went Wrong");
        });
    },
    resetForm() {
      this.$v.studentParentsVisitLog.$reset();
      this.studentParentsVisitLog = {
        user_type: "student",
        user_parent_id: null,
        user_id: null,
        datetime_requested_to_visit: null,
      };
      if(this.$refs.dateTime){
        this.$refs.dateTime.date = null;
      }
    },
    formatRequestedDateTime() {
      if (this.studentParentsVisitLog.datetime_requested_to_visit) {
        var fullDate = new Date(this.studentParentsVisitLog.datetime_requested_to_visit);
        const year = fullDate.getFullYear();
        const month = parseInt(fullDate.getMonth()) + parseInt(1);
        const day = fullDate.getDate();
        const hour = fullDate.getHours();
        const minute = fullDate.getMinutes();

        const entryDateTime =
          year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + "00";
        this.studentParentsVisitLog.datetime_requested_to_visit = null;
        this.studentParentsVisitLog.datetime_requested_to_visit = entryDateTime;
      }
      return;
    },

    setMinDate(){
      this.datePickerProps.min = new Date().toJSON().slice(0, 10);
    },

    setMinTime(){
      let today = new Date();
      this.timePickerProps.min = today.getHours() + ":" + today.getMinutes()
    }
  }
};
</script>
