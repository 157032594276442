<template>
  <v-app>
    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header py-0 px-5 px-5">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            General information
          </h3>
        </div>
        <div class="card-toolbar" v-if="currentUser.type.includes('admin') || currentUser.type.includes('coordinator')">
          <v-btn
              v-if="checkIsAccessible('user', 'edit')"
              depressed
              :loading="isBusy"
              class="btn btn-primary text-white mr-2"
              @click="createOrUpdate()"
          >
            Save
          </v-btn>
          <!--          <button type="reset" class="btn btn-secondary" @click="cancel()">-->
          <!--            Cancel-->
          <!--          </button>-->
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Form-->
      <div v-if="loading">
        <v-skeleton-loader
            v-bind="attrs"
            type="list-item-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
            v-bind="attrs"
            type="list-item-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
            v-bind="attrs"
            type="list-item-three-line"
        ></v-skeleton-loader>
      </div>
      <div v-else>
        <form class="form">
          <!--begin::Body-->
          <div class="card-body">
            <div class="row">
              <label class="col-xl-3"></label>
              <div class="col-lg-9 col-xl-6">

                <!--                        <h5 class="font-weight-bold mb-6">General Info</h5>-->
                <!--                        <div class="d-flex align-items-center justify-content-between mb-2"-->
                <!--                             v-if="checkUserIncludes(user.type,'student')">-->
                <!--                          <span class="font-weight-bold mr-2">Symbol No.</span>-->
                <!--                          <a class=" text-hover-primary">{{-->
                <!--                              user.setting && user.setting.symbol_no ?-->
                <!--                                  user.setting.symbol_no : 'N/A'-->
                <!--                            }}</a>-->
                <!--                        </div>-->
                <!--                        <div class="d-flex align-items-center justify-content-between mb-2"-->
                <!--                             v-if="checkUserIncludes(user.type,'student')">-->
                <!--                          <span class="font-weight-bold mr-2">Board Reg. No.</span>-->
                <!--                          <a class=" text-hover-primary">{{-->
                <!--                              user.setting && user.setting.board_registration_number ?-->
                <!--                                  user.setting.board_registration_number : 'N/A'-->
                <!--                            }}</a>-->
                <!--                        </div>-->
                <!--                        <div class="d-flex align-items-center justify-content-between mb-2"-->
                <!--                             v-if="checkUserIncludes(user.type,'student') && user.setting && user.setting.midas_id">-->
                <!--                          <span class="font-weight-bold mr-2">Midas ID</span>-->
                <!--                          <a class=" text-hover-primary">{{-->
                <!--                              user.setting ?-->
                <!--                                  user.setting.midas_id ? user.setting.midas_id : 'N/A' : 'N/A'-->
                <!--                            }}</a>-->
                <!--                        </div>-->
                <!--                        <div class="d-flex align-items-center justify-content-between mb-2">-->
                <!--                          <span class="font-weight-bold mr-2">Email</span>-->
                <!--                          <a class=" text-hover-primary">{{ user.email ? user.email : 'N/A' }}</a>-->
                <!--                        </div>-->
                <!--                        <div class="d-flex align-items-center justify-content-between mb-2">-->
                <!--                          <span class="font-weight-bold mr-2">Phone</span>-->
                <!--                          <span class="">{{ user.phone ? user.phone : 'N/A' }}</span>-->
                <!--                        </div>-->
                <!--                        <div class="d-flex align-items-center justify-content-between mb-2"-->
                <!--                             v-if="checkUserIncludes(user.type,'student')">-->
                <!--                          <span class="font-weight-bold mr-2">Father Name</span>-->
                <!--                          <span class="">{{-->
                <!--                              user.father && user.father.length > 0 ? user.father[0]['full_name'] : 'N/A'-->
                <!--                            }}</span>-->
                <!--                        </div>-->
                <!--                        <div class="d-flex align-items-center justify-content-between mb-2"-->
                <!--                             v-if="checkUserIncludes(user.type,'student')">-->
                <!--                          <span class="font-weight-bold mr-2">Mother Name</span>-->
                <!--                          <span class="">{{-->
                <!--                              user.mother && user.mother.length > 0 ? user.mother[0]['full_name'] : 'N/A'-->
                <!--                            }}</span>-->
                <!--                        </div>-->
                <!--                        <div class="d-flex align-items-center justify-content-between mb-2"-->
                <!--                             v-if="checkUserIncludes(user.type,'student')">-->
                <!--                          <span class="font-weight-bold mr-2">Local Guardian</span>-->
                <!--                          <span class="">{{-->
                <!--                              user.guardian && user.guardian.length > 0 ? user.guardian[0]['full_name'] : 'N/A'-->
                <!--                            }}</span>-->
                <!--                        </div>-->
                <!--                        <div class=" mb-2">-->
                <!--                          <div class="font-weight-bold mr-2">Permanent Address</div>-->
                <!--                          <div class="" >-->
                <!--                            {{-->
                <!--                              user.permanent_address && user.permanent_address.length > 0 ? user.permanent_address[0]['full_address'] :-->
                <!--                                  'N/A'-->
                <!--                            }}-->
                <!--                          </div>-->

                <!--                        </div>-->
                <!--                        <div class=" mb-2">-->
                <!--                          <div class="font-weight-bold mr-2">Temporary Address</div>-->
                <!--                          <div class="">-->
                <!--                            {{-->
                <!--                              user.temporary_address && user.temporary_address.length > 0 ? user.temporary_address[0]['full_address'] :-->
                <!--                                  'N/A'-->
                <!--                            }}-->
                <!--                          </div>-->
                <!--                        </div>-->
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-left"
              >Photo</label
              >
              <div class="col-lg-9 col-xl-6">
                <div class="image-input image-input-outline" id="kt_profile_avatar">
                  <div
                      class="ad"
                  >
                    <img class="image-input-wrapper" v-if="getUserImagePath(user)" :src="getUserImagePath(user)" alt="">
                    <img class="image-input-wrapper" v-else
                         src="https://images.unsplash.com/photo-1509114397022-ed747cca3f65?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80"
                         alt="">
                  </div>

                  <label v-if="currentUser.type.includes('admin') || currentUser.type.includes('coordinator')"
                         class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                         data-action="change"
                         data-toggle="tooltip"
                         title=""
                         data-original-title="Change avatar"
                  >
                    <i class="bi bi-upload"></i>
                    <input
                        type="file"
                        name="profile_avatar"
                        accept=".png, .jpg, .jpeg"
                        @change="onFileChange($event)"

                    />
                    <input type="hidden" name="profile_avatar_remove"/>
                  </label>
                  <span
                      class="btn btn-default"
                      data-action="cancel"
                      data-toggle="tooltip"
                      title="Cancel avatar"
                  >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
                  <span v-if="currentUser.type.includes('admin') || currentUser.type.includes('coordinator')"
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title="Remove avatar"
                        @click="data_photo = null"
                  >
                <i class="bi bi-trash"></i>
              </span>
                </div>
                <span class="form-text text-muted"
                >Allowed file types: png, jpg, jpeg.</span
                >
              </div>
            </div>
            <div class="form-group row" v-if="currentUser.type.includes('admin') || currentUser.type.includes('coordinator')">
              <label class="col-xl-3 col-lg-3 col-form-label text-left"
              >First name</label
              >
              <div class="col-lg-9 col-xl-6">
                <v-text-field outlined dense
                              label="First name"
                              v-model="user.first_name"
                              :error="$v.user.first_name.$error"
                >
                </v-text-field>
                <span class="text-danger" v-if="$v.user.first_name.$error">This information is required</span>
                <!--              <input-->
                <!--                  ref="name"-->
                <!--                  v-model="user.first_name"-->
                <!--                  class="form-control form-control-lg form-control-solid"-->
                <!--                  type="text"/>-->
                <!--              v-bind:value="currentUserPersonalInfo.name"-->

              </div>
            </div>
            <div class="form-group row" v-if="currentUser.type.includes('admin') || currentUser.type.includes('coordinator')">
              <label class="col-xl-3 col-lg-3 col-form-label text-left"
              >Middle name</label
              >
              <!--                        {{currentUser.type}}-->
              <div class="col-lg-9 col-xl-6">
                <v-text-field outlined dense
                              label="Middle name"
                              v-model="user.middle_name"
                >
                </v-text-field>
                <!--              <input-->

                <!--                  v-model="user.middle_name"-->
                <!--                  ref="middle_name"-->
                <!--                  class="form-control form-control-lg form-control-solid"-->
                <!--                  type="text"/>-->
                <!--              v-bind:value="currentUserPersonalInfo.surname"-->

              </div>
            </div>
            <div class="form-group row" v-if="currentUser.type.includes('admin') || currentUser.type.includes('coordinator')">
              <label class="col-xl-3 col-lg-3 col-form-label text-left"
              >Last name</label
              >
              <div class="col-lg-9 col-xl-6">
                <v-text-field outlined dense
                              label="Last name"
                              v-model="user.last_name"
                              :error="$v.user.last_name.$error"
                >
                </v-text-field>
                <span class="text-danger" v-if="$v.user.last_name.$error">This information is required</span>
                <!--              <input-->

                <!--                  v-model="user.last_name"-->
                <!--                  ref="surname"-->
                <!--                  class="form-control form-control-lg form-control-solid"-->
                <!--                  type="text"/>-->
                <!--              v-bind:value="currentUserPersonalInfo.surname"-->

              </div>
            </div>
            <!--          <div class="row" v-if="currentUser.type.includes('admin') || currentUser.type.includes('coordinator')">-->
            <!--            <label class="col-xl-3"></label>-->
            <!--            <div class="col-lg-9 col-xl-6">-->
            <!--              <h5 class="font-weight-bold mt-10 mb-6">Contact Info</h5>-->
            <!--            </div>-->
            <!--          </div>-->
            <div class="form-group row" v-if="currentUser.type.includes('admin') || currentUser.type.includes('coordinator')">
              <label class="col-xl-3 col-lg-3 col-form-label text-left"
              >Phone</label
              >
              <div class="col-lg-9 col-xl-6">
                <v-text-field outlined dense
                              label="Phone"
                              append-icon="fas fa-phone"
                              v-model="user.phone"
                >
                </v-text-field>

              </div>
            </div>
            <div class="form-group row" v-if="currentUser.type.includes('admin') || currentUser.type.includes('coordinator')">
              <label class="col-xl-3 col-lg-3 col-form-label text-left"
              >Mobile</label
              >
              <div class="col-lg-9 col-xl-6">
                <v-text-field outlined dense
                              label="Mobile"
                              append-icon="fas fa-mobile"
                              v-model="user.mobile"
                              @blur="validatePhone"
                >
                </v-text-field>
                <span class="text-danger" v-if="phone_exists">Number already exists</span>


              </div>
            </div>
            <div class="form-group row" v-if="currentUser.type.includes('admin') || currentUser.type.includes('coordinator')">
              <label class="col-xl-3 col-lg-3 col-form-label text-left"
              >Email</label
              >
              <div class="col-lg-9 col-xl-6">
                <v-text-field outlined dense
                              label="Email"
                              append-icon="fas fa-envelope"
                              v-model="user.email"
                              :error="$v.user.email.$error"
                >
                </v-text-field>
                <!--                  @blur="validateEmail"-->
                <span class="text-danger" v-if="email_exists">Email already exists </span>

                <span class="text-danger" v-if="$v.user.email.$error">Email is not valid</span>
<!--                <span class="form-text text-muted"-->
<!--                >We'll never share your email with anyone else.</span-->
<!--                >-->
              </div>
            </div>

            <!--          <div class="form-group row" v-if="currentUser.type.includes('admin') || currentUser.type.includes('coordinator')">-->
            <!--            <label class="col-xl-3 col-lg-3 col-form-label text-left"-->
            <!--            >Description</label-->
            <!--            >-->
            <!--            <div class="col-lg-9 col-xl-6">-->
            <!--              <v-textarea outlined dense-->
            <!--                            label="Description"-->
            <!--                            v-model="user.email"-->
            <!--              >-->
            <!--              </v-textarea>-->
            <!--              <span class="form-text text-muted"-->
            <!--              >We'll never share your email with anyone else.</span-->
            <!--              >-->
            <!--            </div>-->
            <!--          </div>-->
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label text-left">Communication</label>
              <div class="col-lg-9 col-xl-6">
                <div class="checkbox-inline">
                  <label class="checkbox">
                    <input
                        type="checkbox"
                        v-model="user.notification_email"
                        ref="email_com"
                    /><span></span> Email</label
                  >
                  <label class="checkbox">
                    <input
                        type="checkbox"
                        v-model="user.notification_sms"
                        ref="sms_com"
                    /><span></span> SMS</label
                  >
                  <label class="checkbox">
                    <input
                        type="checkbox"
                        v-model="user.notify_parent"
                        ref="parent_com"
                    /><span></span> Notify parent</label
                  >
                  <label class="checkbox">
                    <input
                        type="checkbox"
                        v-model="user.notify_other"
                        @change="user.notification_other_email = []"
                        ref="notify_other"
                    /><span></span> Notify other</label
                  >
                </div>

                <div class="mt-4" v-if="user.notify_other">
                  <v-combobox
                      label="Other email"
                      v-model="user.notification_other_email"
                      chips
                      deletable-chips
                      small-chips
                      multiple
                      outlined
                      dense
                  ></v-combobox>
                  <span class="text-danger">**Please enter to add email</span>
                </div>
              </div>
            </div>
          </div>
          <!--end::Body-->
        </form>
      </div>

      <!--end::Form-->
    </div>
  </v-app>
</template>

<script>
// import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
import UserService from "@/core/services/user/UserService";
import {required, email} from "vuelidate/lib/validators";
import {ref} from 'vue';

const userService = new UserService();

export default {
  name: "StudentGeneralInformation",
  props:['user'],
  validations() {
    return {
      user: this.rules,
    };
  },
  data() {
    return {
      attrs: '',
      isBusy: false,
      loading: false,
      phone_exists: false,
      email_exists: false,
      default_photo: "media/users/blank.png",
      current_photo: null,
      studentSetting: null,
      data_photo: null,
    };
  },
  methods: {
    validatePhone() {
      // 'user/validate-email'
      let data = {
        mobile: this.user.mobile
      }
      if (this.user.mobile.length > 6) {
        userService.validatePhone(data).then(res => {
          if (res.data.status == 'OK' && res.data.user) {
            this.phone_exists = true;
            this.$snotify.error('Mobile number already in use')
          } else {
            this.phone_exists = false;
          }
        })
      }


    },
    validateEmail() {
      // 'user/validate-email'
      let data = {
        email: this.user.email
      }
      if (this.user.email.length > 5) {
        userService.validateEmail(data).then(res => {
          // console.log(res)
          if (res.data.status == 'OK' && res.data.user) {
            this.email_exists = true;
            this.$snotify.error('Email Already Exists Please Try New Email ')
          } else {
            this.email_exists = false;
          }
        })
      }

    },
    getUser(id) {
      userService.show(id).then(response => {
        this.user = response.data.user;
        if (this.user.image_path && this.user.image_path.real) {
          this.data_photo = this.user.image_path.real;
        }
        this.studentSetting = this.user.setting
        this.loading = false;
      });
    },
    getUserImagePath(user){
      if (user.image_path && user.image_path.real) {
        return this.user.image_path.real;
      }
    },
    saveStudent(fd) {
      this.isBusy = true;
      userService
          .createByAdmin(fd)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Information added");
            this.$router.push({name: "students"});
            this.$emit('refresh')
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
          });
    },

    createOrUpdate() {
      this.$v.user.$touch();
      if (this.$v.user.$error) {
        setTimeout(() => {
          this.$v.user.$reset();
        }, 3000);
      } else {
        delete this.user.permanent_address;
        delete this.user.temporary_address;
        delete this.user.father;
        delete this.user.mother;
        delete this.user.guardian;
        delete this.user.password;
        delete this.user.setting
        if (this.user.middle_name == null && this.user.middle_name == "null"){
          delete this.user.middle_name
        }
        let fd = this.generateFd();
        if (this.user.id) {
          this.updateStudent(fd);
        } else {
          this.saveStudent(fd);
        }
      }
    },
    updateStudent(fd) {
      this.isBusy = true;
      // delete this.user.email;
      // delete this.user.phone;
      userService
          .update(this.user.id, fd)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Information updated");
            this.$emit('refresh')
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
          });
    },

    cancel() {
      // this.$refs.name.value = this.currentUserPersonalInfo.name;
      // this.$refs.surname.value = this.currentUserPersonalInfo.surname;
      // this.$refs.company_name.value = this.currentUserPersonalInfo.company_name;
      // this.$refs.phone.value = this.currentUserPersonalInfo.phone;
      // this.$refs.email.value = this.currentUserPersonalInfo.email;
      // this.$refs.company_site.value = this.currentUserPersonalInfo.company_site;
      // this.current_photo = this.currentUserPersonalInfo.photo;
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.user.photo = file;
      this.data_photo = URL.createObjectURL(file)

    },



    generateFd() {
      let fd = new FormData();
      for (let key in this.user) {
        if (
            key == "photo" &&
            this.user["photo"] &&
            this.user["photo"] != null
        ) {
          if (typeof this.user["photo"] == "object") {
            fd.append("photo", this.user[key]);
          }
        } else if (key == "notification_email" && this.user["notification_email"]) {
          fd.append("notification_email", this.user.notification_email ? 1 : 0);
        } else if (key == "notification_sms") {
          fd.append("notification_sms", this.user.notification_sms ? 1 : 0);
        } else if (key == "notify_parent") {
          fd.append("notify_parent", this.user.notify_parent ? 1 : 0);
        } else if (key == "com_pref_postal") {
          fd.append("com_pref_postal", this.user.com_pref_postal ? 1 : 0);
        } else if (key == "notify_other") {
          fd.append("notify_other", this.user.notify_other ? 1 : 0);
        } else if (key == "middle_name") {
          fd.append("middle_name", this.user.middle_name);
        } else {
          if (key != "id" && this.user[key]) {
            fd.append(key, this.user[key]);
          }
        }
      }
      return fd;
    }

  },
  computed: {
    id(){
      if (this.currentUser.type == 'admin')
        return this.$route.params.id ? this.$route.params.id : null;
      else
        return this.currentUser.id;
    },

    rules() {
      let rule = {
        first_name: {required},
        last_name: {required},
        email: {email},

      };
      if (!this.id) {
        rule.email = {required};
        rule.phone = {required};
      }
      if (this.user.account_type != 'student')
        rule.email = {required, email}
      return rule;
    },
    photo() {
      return this.current_photo == null
          ? this.default_photo
          : this.current_photo;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
  }
};
</script>
