<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        Notify parent
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="cancelForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-select
                                :items="parentTypes"
                                item-text="name"
                                itme-value="value"
                                outlined
                                v-model="selectedParentType"
                                @change="handleParentTypeSelected"
                                dense
                                label="Select parent to email"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="12" v-if="selectedParentType == 'other'">
                            <v-text-field
                                outlined
                                dense
                                v-model="otherEmail"
                                label="Enter email address"
                            >
                            </v-text-field>
                            <span class="text-danger" v-if="!validEmail">Enter a valid email address.</span>
                        </v-col>

                        <v-col cols="12" md="12" v-if="selectedParentType != 'other'">
                            <div class="border p-3">
                                <h5 v-if="selectedParent">
                                    {{ selectedParent.full_name }} - 
                                    {{ selectedParent.email }}
                                </h5>
                                <h5 v-else>
                                    No records found
                                </h5>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        class="cancel-btn"
                        x-large
                        @click="hideModal">
                        Cancel
                    </v-btn>
                    <v-btn
                        color="btn btn-primary"
                        dark 
                        large
                        :loading="loading"
                        :disabled="disableSubmitButton"
                        @click="sendMail()"
                    >
                        Send 
                    </v-btn>
                    </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import StudentHealthRecordService from "@/core/services/user/student/student-health-record/StudentHealthRecordService";
    import StudentParentService from "@/core/services/user/student/StudentParentService";
 
    const studentHealthRecord = new StudentHealthRecordService();
    const studentParent = new StudentParentService();

    export default {
        data(){
            return {
                dialog: false,
                loading: false,
                father_information: '',
                mother_information: '',
                guardian_information: '',
                parentTypes:[
                    {
                        name: 'Father',
                        value: 'father'
                    },
                    {
                        name: 'Mother',
                        value: 'mother'
                    },
                    {
                        name: 'Guardian',
                        value: 'guardian'
                    },
                    {
                        name: 'Other',
                        value: 'other'
                    },
                ],
                selectedParentType: '',
                selectedParent: '',
                userId: '',
                studentHealthRecordId: '',
                otherEmail: '',
                validEmail: false,
            }
        },
        computed:{
            disableSubmitButton(){
                if((this.selectedParentType == 'father' || this.selectedParentType == 'mother' || this.selectedParentType == 'guardian') && this.selectedParent){
                    return false;
                }
                if(this.selectedParentType == 'other' && this.otherEmail){
                    return false;
                }
                return true;
            }
        },
        methods:{
            showModal(userId, studentHealthRecordId){
                this.dialog = true;
                this.resetForm();
                this.userId = userId;
                this.studentHealthRecordId = studentHealthRecordId;
                this.getStudentParentInformation();
            },
            hideModal(){
                this.dialog = false;
            },
            resetForm(){
                this.father_information = '';
                this.mother_information = '';
                this.guardian_information = '';
                this.selectedParentType = '';
                this.selectedParent = '';
                this.userId = '';
                this.studentHealthRecordId = '';
                this.otherEmail = '';
                this.validEmail = false;
            },
            cancelForm(){
                this.resetForm();
                this.hideModal();
            },
            getStudentParentInformation(){
                this.loading=true;
                studentParent
                .getStudentParentInformation(this.userId)
                .then((response) => {
                    if(response.data && response.data.father_information){
                        this.father_information=response.data.father_information;
                    }else{
                        this.father_information = null;
                    }
                    if(response.data && response.data.mother_information) {
                        this.mother_information = response.data.mother_information;
                    }else{
                        this.mother_information = null;
                    }
                    if(response.data && response.data.guardian_information) {
                        this.guardian_information = response.data.guardian_information;
                    }else{
                        this.guardian_information = null;
                    }
                })
                .catch(error => {

                })
                .finally(()=>{
                    this.loading = false;
                })
            },
            handleParentTypeSelected(){
                if(this.selectedParentType == 'father'){
                    if(this.father_information){
                        this.selectedParent = this.father_information;
                    }else{
                        this.selectedParent = '';
                    }
                }
                if(this.selectedParentType == 'mother'){
                    if(this.mother_information){
                        this.selectedParent = this.mother_information;
                    }else{
                        this.selectedParent = '';
                    }
                }
                if(this.selectedParentType == 'guardian'){
                    if(this.guardian_information){
                        this.selectedParent = this.guardian_information;
                    }else{
                        this.selectedParent = '';
                    }
                }
                if(this.selectedParentType == 'other'){
                    this.selectedParent = '';
                }
            },
            sendMail(){
                let data = '';
                this.validEmail = false;

                if(this.selectedParentType == 'other'){
                    if(this.isValidEmail(this.otherEmail)){
                        data = {
                            email: this.otherEmail,
                            type: this.selectedParentType
                        };
                        this.validEmail = true;
                    }else{
                        this.validEmail = false;
                        return false;
                    }
                }else{
                    data = {
                        email: this.selectedParent.email,
                        type: this.selectedParentType
                    };
                }

                this.$confirm({
                    message: `Are you sure you want to send mail?`,
                    button: {
                        no: "No",
                        yes: "Yes",
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: (confirm) => {
                        if (confirm) {
                            this.loading = true;
                            studentHealthRecord
                                .notifyParent(data, this.studentHealthRecordId)
                                .then((res) => {
                                    this.loading = false;
                                    this.resetForm();
                                    this.hideModal();
                                    this.$snotify.success("Parent notified successfully.");
                                    this.$emit('refresh');
                                })
                                .catch((err) => {
                                    this.loading = false;
                                    this.$snotify.error("Something went wrong.");
                                })         
                        }
                    },
                });
            },
            isValidEmail(email) {
                return (/^[^@]+@\w+(\.\w+)+\w$/.test(email));
            }
        }
    }
</script>