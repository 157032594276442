<template>
  <v-app> <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header px-5 py-5">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            Documents
          </h3>
        </div>
        <div class="card-toolbar">
        </div>
      </div>

      <div class="container">
        <h5 class="text-primary mt-5">Documents uploaded</h5>
        <div class="row px-5" v-if="documents.length > 0">
            <v-col cols="12" md="12" sm="12" >
                <div class="rounded" style="border-bottom: 1px solid #dfebf8" v-for="(doc, i) of documents" :key="i">
                    <p class="p-2">
                        <a :href="doc.image_path['real']" target="_blank" style="color: #0a0a0a !important;"><i class="fa fa-link"></i> {{doc.photo_type}}</a>
                        <span class="float-right cursor-pointer" v-if="currentUser.type == 'admin'" @click="deletePhoto(doc)"><i
                                class="fas fa-trash "></i></span>
                    </p>
                </div>
            </v-col>
        </div>
        <div class="row px-5" v-else>
            <v-col cols="12" md="12" sm="12" class="text-red">
                No documents uploaded
            </v-col>
        </div>
        <div class="row p-2 px-4">
          <div class="col-md-12">
              <h5 class="text-primary mt-5 mb-5" v-if="user.type=='student'">Documents required for this program</h5>
            <div v-for="(dtype, index) of documents_types" :key="index">

              <div class="row" v-if="!uploadedDocuments.includes(dtype)">
                <div class="col-md-4 col-lg-4 col-sm-4">
                  <label for="" style="text-transform: capitalize">{{ dtype }}</label>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6">
                  <!-- Styled -->
                  <b-form-file v-model="file" accept="application/msword ,application/pdf, image/*, application/vnd/*"
                    @change="checkSize($event, index)" placeholder="Upload file"
                    drop-placeholder="Drop file here..."></b-form-file>
                  <!-- <div class="mt-3">Selected file: {{ file ? file.name : '' }}</div> -->

                </div>

                <div class="col-md-2 col-lg-2 col-sm-2">
                  <v-btn :disabled="file == null || file != undefined && selectedIndex != index" href="#"
                    class="btn btn-lg btn-secondary  " @click.prevent="uploadDocuments(dtype)">
                    <i class="fa fa-upload"></i>
                    Upload
                  </v-btn>
                </div>

              </div>
            </div>
            <span class="text-danger" v-if="sizeExceed">File size must be less than 20Mb</span>
            <div v-show="documents_types.length == 0 && user.type=='student'">
              <span class="font-weight-bold">
                Program files not configured to accept minimum required files. This can be setup under <a @click="manageProgram(user.setting.program_id)"
                  v-if="user && user.setting">program</a> settings.
              </span>

            </div>
            <br>
              <div class="row">
              <div class="col-md-12"><strong>Add additional file.</strong></div>
              <div class="col-md-9">
                <v-text-field
                    label="Enter document name to upload file"
                    outlined
                    dense
                    v-model="customDoc"
                >

                </v-text-field>
              </div>
              <div class="col-md-2">
                <v-btn
                    depressed
                    class="btn btn-primary mr-2 text-white"
                    @click="addCustomDoc()"
                >
                  Add document
                </v-btn>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

  </v-app>
</template>
<script>
import UserIndividualDocumentService from "@/core/services/user/student/UserIndividualDocumentService";
import ProgramService from "@/core/services/level/program/ProgramService";

const documentService = new UserIndividualDocumentService();
const programService = new ProgramService();

export default {
  name: "documents",
  props: ["user"],

  data() {
    return {
      file: null,
      type: null,
      sizeExceed: false,
      selectedIndex: null,
      customDoc: null,
      imageFiles: ['jpg', 'jpeg', 'png', 'gif'],
      documents: [],
      uploadedDocuments: [],
      neddedDocuments: [],
      documents_types: [],
      document: {
        image: null,
        type: null
      }
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    userId() {
      return this.$route.params.id ? this.$route.params.id : false;
    }
  },
  mounted() {
    this.getDocuments();
    this.getDocumentTypes();
  },
  methods: {
    manageProgram(programId) {
      this.$router.push({ name: "program-update", params: { id: programId } });
    },
    checkFileType(file) {
      let filePath = file
      let extension = null
      if (file) {
        if (filePath.includes('aws')) {
          filePath = file.split("thumb/")[1];
          filePath = filePath.split("?")[0];
          extension = filePath.split(".")[1];
        } else {
          filePath = file.split("thumb/")[1];
          extension = filePath.split(".")[1];
        }
        let image = (this.imageFiles.indexOf(extension) > -1)
        return image;
      }

    },
    checkList() {
      this.documents.forEach((ele) => {
        this.uploadedDocuments.push(ele.type);
      })
    },
    addCustomDoc() {
      if (this.customDoc)
        this.documents_types.push(this.customDoc);
      this.customDoc = null;
    },
    getDocuments() {
      documentService
        .paginate(this.userId)
        .then(response => {
          this.documents = response.data.data;
          this.checkList();
        })
        .catch(error => {
          //console.log(error);
        });
    },
    getDocumentTypes() {
      if(this.user.setting && this.user.setting.program_id) {
        programService.show(this.user.setting.program_id).then(response => {
          const { required_documents } = response.data.program
          if (required_documents)
            this.documents_types = required_documents
        })
      }

    },
    checkSize(file, index) {
      this.selectedIndex = index;
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      if (mbSize > 20) {
        this.sizeExceed = true;
      } else {
        this.sizeExceed = false;
      }
    },
    uploadDocuments(title) {
      let fd = new FormData();
      fd.append("image", this.file);
      fd.append("type", title)
      this.$bus.emit('toggleLoader');
      documentService
        .store(this.userId, fd)
        .then(response => {
          this.file = null;
          this.getDocuments();
          this.$bus.emit('toggleLoader');
        })
        .catch(error => {
          //console.log(error);
        });
    }, deletePhoto(doc) {

      this.$confirm({
        message: `Are you sure you want to delete this file?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            documentService
              .delete(this.userId, doc.id)
              .then(response => {
                this.file = null;
                this.getDocuments();
                this.$snotify.success("Information deleted");
              })
              .catch(error => {
                //console.log(error);
              });
          }
        }
      });

    }
  }
};
</script>
