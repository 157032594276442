<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Library history
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Library history
                </div>
              </div>

            </div>
          </div>
          <div class="card-body">
            <div class="">
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Info</th>
                    <th class="px-3 wrap-column">Issued by</th>
                    <th class="px-3 wrap-column">Issued info</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="book_histories.length > 0" v-for="(history, index) in book_histories" :key="index">
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                        <strong>Name: </strong> {{ history.book_title ? history.book_title : 'N/A' }}
                      </span>
                      <div class="mt-3">
                        <span class="font-weight-bold  font-size-sm">Author:</span> {{ history.book_author ?
                          history.book_author : 'N/A' }}
                      </div>

                      <div class="mt-3">
                          <span class="font-weight-bold  font-size-sm"> Publisher:</span> {{ history.book_publisher ?
                            history.book_publisher : 'N/A' }}
                      </div>

                      <div class="mt-3">
                          <span class="font-weight-bold  font-size-sm">Publication:</span> {{ history.book_publication ?
                            history.book_publication : 'N/A' }}
                      </div>
                    </td>


                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">{{ history.book_issued_by }}</span>
                      <div class="mt-3">
                        <span class="badge badge-primary" v-if="history.status && history.status == 'returned'">
                          {{ history.status_text }}
                        </span>
                        <span class="badge badge-warning" v-if="history.status && history.status == 'issued'">
                          Not returned
                        </span>
                      </div>
                    </td>

                    <td class="px-3 wrap-column">
                      <div class="text--secondary font-size-sm">
                        <span class="font-weight-bold  font-size-sm">Issue date:</span> {{ history.issue_date_text }}
                      </div>
                      <div class="text--secondary" v-if="history.due_return_date_text">
                        <span class="font-weight-bold  font-size-sm">Renew date:</span> {{ history.due_return_date_text }}
                      </div>
                      <div class="text--secondary" v-if="history.renew_date_text">
                        <span class="font-weight-bold  font-size-sm">Renew date:</span> {{ history.renew_date_text }}
                      </div>
                    </td>
                  </tr>
                  <tr v-show="book_histories.length == 0">
                    <td colspan="3" class="text-center">
                      <strong>No books issued for this user</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination :disabled="loading" v-if="book_histories.length > 0" class="pull-right mt-7"
                @input="getAllStudentLibraryHistory" v-model="page" :total-rows="total" :per-page="perPage" first-number
                last-number></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import BookService from "@/core/services/library-book/book/BookService";
const book = new BookService();
export default {
  name: "LibraryHIstory",
  props: ['user'],
  data() {
    return {
      book_histories: [],
      loading: false,
      page: 1,
      total: null,
      perPage: null
    }
  },
  methods: {
    getAllStudentLibraryHistory() {
      this.loading = true;
      book
        .getLibraryBookHistory(this.user.id)
        .then(response => {
          this.book_histories = response.data.data;
          this.loading = false;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch(error => {
          this.loading = false;
        });

    },
  },
  mounted() {
    this.getAllStudentLibraryHistory();
  }
}
</script>