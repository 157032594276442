<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Character certificate</h4>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="previewCertificate()" x-large text>
                    Preview certificate
                  </v-btn>
                  <v-btn
                    @click="openDialog()"
                    class="mt-1 btn btn-primary"
                    dark
                  >
                    <i class="fa fa-plus"></i>
                    Issue character certificate
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" v-if="duplicate_template_status">
            <v-col cols="12">
              <v-alert type="warning" dense border="left">
                Note: There is no template for character certificate
                <a @click="goToTemplatePage()"> add </a> template before issuing
                a certificate.
              </v-alert>
            </v-col>
          </div>
        </div>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-toolbar dark>
              <v-card-title class="headline">
                Issue character certificate
                <hr />
              </v-card-title>

              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog">
                  <i class="fas fa-times"></i>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col md="12">
                    <v-menu
                      v-model="issueDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="certificate.issued_date"
                          label="Issued date"
                          readonly
                          dense
                          outlined
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="certificate.issued_date"
                        @input="issueDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col md="12">
                    <v-textarea
                      label="Description"
                      v-model="certificate.description"
                      dense
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="btn btn-standard cancel-btn"
                depressed
                @click="closeDialog"
              >
                Cancel
              </v-btn>
              <v-btn
                class="text-white ml-2 btn btn-primary"
                depressed
                @click="__issued()"
                :loading="loading"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </v-app>
</template>
<script>
import CharacterCertificateService from "@/core/services/character-certificate/CharacterCertificateService";
import TemplatesService from "@/core/services/templates/TemplatesService";
const template = new TemplatesService();
const characterCertificate = new CharacterCertificateService();
export default {
  props: ["user"],
  data() {
    return {
      dialog: false,
      issueDateMenu: false,
      loading: false,
      certificate: {
        user_id: "",
        description: "",
        issued_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
      },
      duplicate_template_status: false,
    };
  },
  methods: {
    openDialog() {
      this.checkDuplicateTemplate();
    },
    checkDuplicateTemplate() {
      if (this.user && this.user.setting && this.user.setting.program_id) {
        let data = {
          program_id: this.user.setting.program_id,
          type: "character_certificate",
        };
        template
          .checkDuplicateByProgram(data)
          .then((response) => {
            if (response.data.status) {
              if (response.data.status == "ERROR") {
                this.duplicate_template_status = false;
                this.dialog = true;
              }
              if (response.data.status == "SUCCESS") {
                this.duplicate_template_status = true;
              }
            }
          })
          .catch((err) => {})
          .finally(() => {});
      }
    },
    previewCertificate() {
      if (this.user && this.user.setting && this.user.setting.program_id) {
        let data = {
          program_id: this.user.setting.program_id,
          type: "character_certificate",
        };
        template
          .checkDuplicateByProgram(data)
          .then((response) => {
            if (response.data.status) {
              if (response.data.status == "ERROR") {
                let data = {
                  user_id: this.user.id,
                };
                characterCertificate
                  .previewCharacterCertificate(data)
                  .then((resp) => {})
                  .catch((err) => {})
                  .finally(() => {});
              }
              if (response.data.status == "SUCCESS") {
                this.duplicate_template_status = true;
              }
            }
          })
          .catch((err) => {})
          .finally(() => {});
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    __issued() {
      this.certificate.user_id = this.user.id;
      this.loading = true;
      characterCertificate
        .issueCharacterCertificate(this.certificate)
        .then((response) => {
          this.closeDialog();
          this.loading = false;
        })
        .catch((err) => {})
        .finally(() => {});
    },
    goToTemplatePage() {
      this.$tabs.open({
        name: "character-certificate",
      });
    },
  },
};
</script>