<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card ">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Academic progress</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \   Academic progress
                </div>
              </div>

            </div>
          </div>
          <div class="card-body">
            <div class="">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

             <v-row>
               <v-col cols="12" md="12">
                 <attendance-chart :user="user"></attendance-chart>
               </v-col>
               <v-col cols="3" md="12">
                 <div class="row">
                   <div class="col-md-12">
                     <div class="card">
                       <div class="card-body academic_course_progress">
                         <h4>Academic progress</h4>
                         <v-treeview
                             activatable
                             color="info"
                             :items="items"
                         ></v-treeview>
                       </div>
                     </div>
                   </div>
                 </div>


               </v-col>


               <v-col cols="12">

                 <exam-performance :user="user"></exam-performance>
               </v-col>



             </v-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>

import AttendanceChart from "./AttendanceChart";
import ExamPerformance from "./ExamPerformance";
import LibraryTimeline from "./LibraryTimeline";
import SuggestionAndNotes from "./SuggestionAndNotes";
import StudentGradeService from "@/core/services/user/student/StudentGrade/StudentGradeService";
const studentGrade=new StudentGradeService();
export default {
  components: {SuggestionAndNotes, LibraryTimeline, ExamPerformance, AttendanceChart},
  props:['user'],
  data(){
    return{
      page: null,
      total: null,
      perPage: null,
      loading:false,
      activities:[],
      search:{
        user_id:'',
      },
      items: [

      ],

      books: [
        {
          title: 'Business studies',
          borrowed: 'Borrowed on: Jan 20, 2014',
          returned: 'Returned on:  Jan 24, 2014',
        },
        {
          title: 'Math',
          borrowed: 'Borrowed on: Jan 20, 2014',
          returned: 'Returned on:  Jan 24, 2014',
        },
        {
          title: 'Science',
          borrowed: 'Borrowed on: Jan 20, 2014',
          returned: 'Returned on:  Jan 24, 2014',
        },

      ],
    }
  },
  methods:{
    getStudentCustomizeGrade(){
      studentGrade
          .getStudentCustomizeGrade(this.user.id)
          .then(response => {
            this.items=response.data.list_student_grades;
          })
          .catch(error => {

          })
          .finally(() => {
            this.loading=false;
          })
    }
  },
  mounted() {
    this.getStudentCustomizeGrade();
  }
}
</script>