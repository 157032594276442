<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-5 px-5">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Role permission</h3>

      </div>
      <div class="card-toolbar">
        <v-btn
            depressed
            :loading="isBusy"
            class="btn btn-primary text-white mr-2"
            @click="saveUserRole()"
        >
          Save changes
        </v-btn>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <div v-if="isBusy">
      <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
      ></v-skeleton-loader>
    </div>
    <div class="card-body" v-else>
      <div class="form-group row">
        <v-col cols="12">
<!--          <pre>{{selected_role}}</pre>-->
          <v-select
            v-model="selected_role"
            :items="roles"
            item-value="name"
            item-text="name"
            label="User role"
            chips
            small-chips
            deletable-chips
            multiple
            outlined
            dense
          >
          </v-select>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
import RoleService from "@/core/services/user/role/RoleService";
import userService from "@/core/services/user/UserService";

const roleService = new RoleService();
const user = new userService();

export default {
  name: "RoleSetting",
  props: ['user'],
  data() {
    return {
      isBusy: false,
      attrs: {},
      roles: [],
      selected_role: [],
    }
  },
  mounted() {
    this.selected_role = this.user.type || [];
    this.getAllRoles();
  },
  methods: {
    getAllRoles() {
      this.isBusy = true;
      roleService.all().then(response => {
        this.isBusy = false;
        this.roles = response.data.data
      }).catch(error => {
        this.isBusy = false;
      })
    },
    saveUserRole() {
      let data = {roles: this.selected_role}
      user.assignRole(this.user.id, data).then(response => {
        this.$snotify.success("Information updated")
        this.selected_role=[]
        this.$emit('refresh')
      }).catch(error => {
        this.$snotify.error("Something went wrong. Please try again later")
      }).finally(() => {
        this.$emit('refresh')
      })
    }
  }
}
</script>

<style scoped>

</style>
