<template>
  <v-app>
    <v-dialog persistent v-model="dialog" max-width="700">

      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            Generate fees
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row v-if="!is_generated">
              <v-col cols="12" md="6">
                <v-select
                    v-model="fee.financial_transaction_head_id"
                    label="Transaction heading"
                    @change="getPaymentType(fee.financial_transaction_head_id)"
                    :error="$v.fee.financial_transaction_head_id.$error"
                    :items="financial_heads"
                    item-text="title"
                    item-value="id"
                    outlined
                    dense
                ></v-select>
                <span class="text-danger" v-if="$v.fee.financial_transaction_head_id.$error">This information  is required</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="fee.invoice_date"
                        label="Invoice send date"
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        :error="$v.fee.invoice_date.$error"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="fee.invoice_date"
                      no-title
                      :min="date"
                      @input="menuDate = false"
                  ></v-date-picker>
                </v-menu>
                <span class="text-danger" v-if="$v.fee.invoice_date.$error">This information  is required</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="fee.due_date"
                        label="Due date"
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        :error="$v.fee.due_date.$error"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="fee.due_date"
                      no-title
                      :min="date"
                      @input="menu = false"
                  ></v-date-picker>
                </v-menu>
                <span class="text-danger" v-if="$v.fee.due_date.$error">This information  is required</span>
              </v-col>

              <v-col cols="6">
                <v-select item-value="value" item-text="title" :items="frequency" v-model="fee.payment_frequency"
                          label="Payment frequency" outlined dense>

                  <template v-slot:append>
                    <v-tooltip left
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      Payment frequency is a division of total payment based upon provided options.<br>
                      For eg: If total fee is 12000 then the monthly fee will be 1000.
                    </v-tooltip>
                  </template>
                </v-select>
                <span class="text-danger" v-if="$v.fee.payment_frequency.$error">This information  is required</span>
              </v-col>

              <v-col cols="6">
                <v-select item-value="value" item-text="title" :items="fee.payment_frequency==='monthly'?[12]:[1,2,3,4,5,6,7,8,9,10,11,12]"
                          @change="onTimesSelect"
                          v-model="fee.payment_to_be_made" :label="`No of installment(s) ${fee.payment_frequency?(fee.payment_frequency):''}`" outlined dense>

                  <template v-slot:append>
                    <v-tooltip left
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      No of installments will divide the selected frequency into installments.<br>
                      For eg: If total fee is 12000, frequency is "Monthly" and no of installments is 12<br>
                      There will be twelve installments of 1000 each.
                    </v-tooltip>
                  </template>
                </v-select>
                <span class="text-danger"
                      v-if="$v.fee.payment_to_be_made.$error">This information  is required</span>
              </v-col>
            </v-row>
            <v-row v-if="program_fee && program_fee.total_fee > 0">
              <v-col cols="4" v-for="(installment, i) in installments" :key="i">
                <v-text-field v-model="installment.amount" outlined dense :label="`Installment ${i+1}`"></v-text-field>
                <span class="text-danger" v-if="$v.installments.$each[i].amount.$error">Installment {{ i + 1 }} is required</span>
              </v-col>
            </v-row>
            <v-row v-if="fee.payment_to_be_made && !program_fee.id">
              <v-col cols="12" class="text-center">
                <h2>No fee found.</h2>
              </v-col>
            </v-row>
          </v-container>
          <div class="col-12 text-center">
            <span class="text-danger font-weight-bold font-size-lg" v-if="is_generated">Fee has already been generated.</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="btn btn-standard cancel-btn"
              depressed
              @click="closeDialog"
          >Cancel
          </v-btn>
          <v-btn v-if="!is_generated"
                 class="text-white ml-2 btn btn-primary"
                 depressed
                 :disabled="fee.payment_to_be_made && !program_fee.id"
                 @click="createAndUpdate"
                 :loading="isBusy"
          >Generate now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import FinancialTransactionHeadsService from "@/core/services/finance/FinancialTransactionHeadsService";
import FinancialPaymentTypeService from "@/core/services/finance/finance-payment-type/FinancialPaymentTypeService";
import StudentFeeService from "@/core/services/user/student/fee/StudentFeeService";
import StudentScholarshipService from "@/core/services/scholarship/student-scholarship/StudentScholarshipService";
import ScholarshipService from "@/core/services/scholarship/ScholarshipService";

const feeService = new StudentFeeService();
const financialTransactionHeadsService = new FinancialTransactionHeadsService();
const paymentTypeService = new FinancialPaymentTypeService();
const studentScholarship = new StudentScholarshipService();
const scholarship = new ScholarshipService();

export default {
  name: "Generate-Fee",
  props: ['user_id', 'setting', 'is_generated', 'program_fee'],
  validations() {
    return {
      fee: this.feeRule,
      installments: this.installmentRule,
    }
  },
  data() {
    return {
      dialog: false,
      menu: false,
      menuDate: false,
      isBusy: false,
      edit: false,
      date: new Date().toISOString().substr(0, 10),
      scholarship: {},
      financial_heads: [],
      financial_payment_types: [],
      installments: [],
      fee: {
        financial_transaction_head_id: '',
        financial_payment_type_id: '',
        invoice_date: '',
        due_date: '',
        payment_frequency: '',
        payment_to_be_made: ''
      },
    };
  },
  computed: {
    feeRule() {
      return {
        financial_transaction_head_id: {required},
        invoice_date: {required},
        due_date: {required},
        payment_frequency: {required},
        payment_to_be_made: {required}
      };
    },
    installmentRule() {
      return {
        $each: {
          amount: {required}
        }
      };
    },
    frequency() {
      return [
        {
          title: 'Monthly',
          value: 'monthly'
        },
        {
          title: 'Quarterly',
          value: 'quarterly'
        },
        {
          title: 'Semester',
          value: 'semester'
        },
        {
          title: 'Yearly',
          value: 'yearly'
        },
      ];
    },
    userId() {
      return this.$route.params.id ? this.$route.params.id : '';
    },
  },

  methods: {
    openDialog() {
      this.dialog = true;
      this.getAllActiveTransactionHead();
      this.getStudentScholarships();
      this.resetForm();
    },

    getAllActiveTransactionHead() {
      financialTransactionHeadsService
          .allActiveByType('income')
          .then(response => {
            this.financial_heads = response.data;
          })
          .catch(() => {});
    },

    getStudentScholarships() {
      studentScholarship
          .getCurrentYearScholarship(this.userId)
          .then(response => {
            this.scholarship = response.data.data;
          })
          .catch(() => {});
    },

    getPaymentType(headingId) {
      paymentTypeService
          .allActiveByTransactionHead(headingId)
          .then(response => {
            this.financial_payment_types = response.data
          })
          .catch(() => { });
    },

    createAndUpdate() {
      this.$v.$touch();
      if (this.$v.fee.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        this.fee.installments = this.installments;
        this.fee.program_id = this.program_fee.program_id;
        this.fee.program_fee_id = this.program_fee.id;
        this.generateFeesForStudent()
      }
    },

    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },

    resetForm() {
      this.installments = []
      this.isBusy = false;
      this.$v.$reset()
      this.fee = {
        financial_transaction_head_id: '',
        financial_payment_type_id: '',
        invoice_date: '',
        due_date: '',
        payment_frequency: '',
        payment_to_be_made: ''
      }
    },

    generateFeesForStudent() {
      this.$confirm({
        message: `This will generates the fee of student. You wish to continue it?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            feeService.generateFeesForStudent(this.user_id, this.fee).then(response => {
              this.$snotify.success("Information updated");
              this.closeDialog();
              this.$emit('refresh_list');
              this.updateScholarship();
            })
          }
        }
      });
    },

    onTimesSelect() {
      this.installments = []
      let times = this.fee.payment_to_be_made;
      let fee = this.program_fee.total_fee
      if (this.program_fee.is_admission_fee_applicable)
        fee = parseInt(fee) - parseInt(this.program_fee.admission_fee)
      let i = 0;
      while (i != parseInt(times)) {
        let subAmount = parseFloat(fee / times).toFixed(2);
        let installment = {
          title: `Tuition fee ${i+1}`
        }
        this.scholarshipAmount(installment, subAmount);
        this.installments.push(installment)
        i++
      }
    },
    //
    // discountAmount(installment, subAmount) {
    //   if(this.setting && this.setting.discount_enabled) {
    //     if(this.setting.discount_type == 'fixed') {
    //       installment.amount = parseFloat( subAmount - this.setting.fee_discount_received).toFixed(2);
    //       installment.discount_percentage = 0;
    //       installment.discount_amount = this.setting.fee_discount_received;
    //     } else {
    //       installment.amount = parseFloat( subAmount * (((100 - this.setting.fee_discount_received)) / 100)).toFixed(2);
    //       installment.discount_percentage = this.setting.fee_discount_received;
    //       installment.discount_amount = subAmount * (this.setting.fee_discount_received / 100);
    //     }
    //   } else  {
    //     installment.amount = subAmount
    //     installment.discount_percentage = 0
    //     installment.discount_amount = 0
    //   }
    // },

    scholarshipAmount(installment, subAmount) {
      if(this.scholarship && installment && subAmount) {
        if(this.scholarship.remaining_students > 0) {
          if(this.scholarship.scholarship_type == 'fixed') {
            installment.amount = parseFloat( subAmount - this.scholarship.scholarship_amount).toFixed(2);
            installment.is_assigned_scholarship = 1;
            installment.scholarship_amount = parseFloat(this.scholarship.scholarship_amount);
          } else {
            installment.amount = parseFloat( subAmount * (((100 - this.scholarship.scholarship_amount)) / 100)).toFixed(2);
            installment.is_assigned_scholarship = 1;
            installment.scholarship_amount = parseFloat( subAmount * (this.scholarship.scholarship_amount / 100)).toFixed(2);
          }
        } else {
          this.$snotify.error("Scholarships are limited. They are not available currently.");
        }
      } else {
        installment.amount = parseFloat(subAmount).toFixed(2);
        installment.is_assigned_scholarship = 0;
        installment.scholarship_amount = 0;
      }
    },

    updateScholarship() {
      if(this.scholarship) {
          this.scholarship.remaining_students = this.scholarship.remaining_students - 1;
          scholarship
              .update(this.scholarship.id, this.scholarship)
              .then((response) => {
                if (response.data.status == "OK") {
                  this.$snotify.success("Update scholarship");
                }
              })
              .catch(() => {
              });
      }
    },
  }
};
</script>
