<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            {{ `${edit ? "Update" : "Add"} hostel` }}
            <hr />
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon @click="resetForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-row>

            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="hostelResident.hostel_id"
                outlined
                dense
                :items="hostels"
                item-text="title"
                item-value="id"
                @change="handelHostelChange"
                :error="$v.hostelResident.hostel_id.$error"
              >
                <template v-slot:label>
                  Hostel <span class="text-danger">*</span>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.hostelResident.hostel_id.$error"
                >This information is required</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="hostelResident.hostel_room_id"
                outlined
                dense
                :loading="hostelRoomLoading"
                :items="hostelRooms"
                item-text="title"
                item-value="id"
                :error="$v.hostelResident.hostel_room_id.$error"
              >
                <template v-slot:label>
                  Hostel room<span class="text-danger">*</span>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.hostelResident.hostel_room_id.$error"
                >This information is required</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="auto"
                offset-y
                transition="scale-transition"
                v-model="menu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    append-icon="mdi-calendar"
                    clearable
                    dense
                    :error="$v.hostelResident.entry_date.$error"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-model="hostelResident.entry_date"
                    v-on="on"
                  >
                    <template v-slot:label>
                      Entry date
                      <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  @input="menu = false"
                  v-model="hostelResident.entry_date"
                ></v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.hostelResident.entry_date.$error"
                >This information is required</span
              >
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="auto"
                offset-y
                transition="scale-transition"
                v-model="menu1"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    append-icon="mdi-calendar"
                    clearable
                    dense
                    :error="$v.hostelResident.exit_date.$error"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-model="hostelResident.exit_date"
                    v-on="on"
                  >
                    <template v-slot:label>
                      Exit date
                      <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  @input="menu1 = false"
                  v-model="hostelResident.exit_date"
                ></v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.hostelResident.exit_date.$error"
                >This information is required</span
              >
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="hostelResident.fee"
                outlined
                type="number"
                dense
                :error="$v.hostelResident.fee.$error"
              >
                <template v-slot:label> Fee<span class="text-danger">*</span> </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.hostelResident.fee.$error"
                >This information is required</span
              >
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                v-model="hostelResident.fee_frequency"
                outlined
                dense
                :items="feeFrequencies"
                item-text="name"
                item-value="value"
                :error="$v.hostelResident.fee_frequency.$error"
              >
                <template v-slot:label>
                  Fee frequency<span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.hostelResident.fee_frequency.$error"
                >This information is required</span
              >
            </v-col>

            <v-col cols="12" md="4">
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="auto"
                offset-y
                transition="scale-transition"
                v-model="menu2"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    append-icon="mdi-calendar"
                    clearable
                    dense
                    :error="$v.hostelResident.payment_start_date.$error"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-model="hostelResident.payment_start_date"
                    v-on="on"
                  >
                    <template v-slot:label>
                      Payment start date
                      <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  @input="menu2 = false"
                  v-model="hostelResident.payment_start_date"
                ></v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.hostelResident.payment_start_date.$error"
                >This information is required</span
              >
            </v-col>

            <v-col cols="12" md="3">
              Has discount ?
              <v-switch
                v-model="hostelResident.has_discount"
                :label="hostelResident.has_discount ? 'Yes' : 'No'"
              ></v-switch>
              <span class="text-danger" v-if="$v.hostelResident.has_discount.$error"
                >This information is required</span
              >
            </v-col>

            <v-col cols="12" class="mt-2" v-if="hostelResident.has_discount" md="5">
              <v-select
                v-model="hostelResident.discount_type"
                outlined
                dense
                :items="discountTypes"
                item-text="name"
                item-value="value"
                :error="$v.hostelResident.discount_type.$error"
              >
                <template v-slot:label>
                  Discount type<span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.hostelResident.discount_type.$error"
                >This information is required</span
              >
            </v-col>

            <v-col cols="12" class="mt-2" v-if="hostelResident.has_discount" md="4">
              <v-text-field
                v-model="hostelResident.discount_amount"
                outlined
                type="number"
                dense
                :error="$v.hostelResident.discount_amount.$error"
              >
                <template v-slot:label>
                  Discount amount<span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.hostelResident.discount_amount.$error"
                >This information is required</span
              >
            </v-col>

            <v-col cols="12">
                <label>
                    Status
                </label>
                <v-switch v-model="hostelResident.is_active" :label="hostelResident.is_active ? 'Active' : 'Inactive'"></v-switch>
                <span class="text-danger" v-if="$v.hostelResident.is_active.$error">This information is required</span>
            </v-col>

            <v-col cols="12">
              <div class="text-right float-right">
                <v-btn depressed @click="resetForm" class="text-gray btn btn-standard">
                  Cancel
                </v-btn>
                <!-- <v-btn 
                                    v-if="checkIsAccessible('gate-pass', 'create')" 
                                    depressed 
                                    :loading="isBusy"
                                    @click="createOrUpdate" 
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn> -->
                <v-btn
                  depressed
                  :loading="isBusy"
                  @click="createOrUpdate"
                  class="text-white ml-2 btn btn-primary"
                >
                  Save
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import HostelService from "@/core/services/hostel/HostelService";
import HostelRoomService from "@/core/services/hostel/hostel-room/HostelRoomService";
import HostelResidentService from "@/core/services/hostel/hostel-resident/HostelResidentService";
import UserService from "@/core/services/user/UserService";

const hostel = new HostelService();
const hostelRoom = new HostelRoomService();
const hostelResident = new HostelResidentService();
const user = new UserService();

export default {
  validations: {
    hostelResident: {
        hostel_id: { required },
        hostel_room_id: { required },
        entry_date: { required },
        exit_date: { required },
        fee: { required },
        fee_frequency: { required },
        payment_start_date: { required },
        has_discount: { required },
        discount_type:{ 
        required: requiredIf(function () {
                return this.hostelResident.has_discount;
            })
        },
        discount_amount: {
            required: requiredIf(function () {
                return this.hostelResident.has_discount;
            }),
        },
        is_active: {},
    },
  },
  data() {
    return {
      isBusy: false,
      hostelResident: {
        hostel_id: null,
        hostel_room_id: null,
        user_id: null,
        entry_date: null,
        exit_date: null,
        fee: null,
        fee_frequency: "",
        payment_start_date: null,
        has_discount: false,
        discount_type: "",
        discount_amount: null,
        is_active: true,
      },
      menu: false,
      menu1: false,
      menu2: false,
      feeFrequencies: [
        {
          name: "Weekly",
          value: "weekly",
        },
        {
          name: "Monthly",
          value: "monthly",
        },
        {
          name: "Quarterly",
          value: "quarterly",
        },
        {
          name: "Yearly",
          value: "yearly",
        },
      ],
      discountTypes: [
        {
          name: "Fixed",
          value: "fixed",
        },
        {
          name: "Percentage",
          value: "percentage",
        },
      ],
      hostels: [],
      hostelRooms: [],
      hostelRoomLoading: false,
      edit: false,
      isRenewing: false,
      dialog: false,
      hostelResidentId: null,
    };
  },
  methods: {
    handelHostelChange() {
      this.hostelRooms = [];
      this.hostelResident.hostel_room_id = null;
      if (this.hostelResident.hostel_id) {
        this.getHostelRooms();
      }
    },
    showModal(userId = null, hostelResidentId = null) {
      this.userId = userId;
      this.edit = false;
      if(hostelResidentId){
        this.edit = true;
        this.hostelResidentId = hostelResidentId;
        this.getHostelResidentInfo();
      }
      // this.getHostelInfo();
      this.getHostels();
      this.dialog = true;
    },
    hideModal() {
      this.dialog = false;
    },
    getHostelInfo() {
      hostelResident
        .getByStudent(this.userId)
        .then((res) => {
          if (res.data.status == "OK") {
            this.hostelResident = res.data.hostelResident;
            if (this.hostelResident.hostel_id) {
              this.getHostelRooms();
              this.edit = true;
            }
          } else {
            this.hostelResident = {
              hostel_id: null,
              hostel_room_id: null,
              user_id: null,
              entry_date: null,
              exit_date: null,
              fee: null,
              fee_frequency: "",
              payment_start_date: null,
              has_discount: false,
              discount_type: "",
              discount_amount: null,
              is_active: true,
            };
            this.edit = false;
          }
        })
        .catch((err) => {});
    },
    getHostels() {
      hostel
        .all()
        .then((res) => {
          this.hostels = res.data.hostels;
        })
        .catch((err) => {});
    },
    getHostelRooms() {
      this.hostelRoomLoading = true;
      hostelRoom
        .getByHostel(this.hostelResident.hostel_id)
        .then((res) => {
          this.hostelRooms = res.data;
          this.hostelRoomLoading = false;
        })
        .catch((err) => {});
    },
    createOrUpdate() {
      this.$v.hostelResident.$touch();
      if (this.$v.hostelResident.$error) {
        setTimeout(() => {
          this.$v.hostelResident.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateHostelResident();
        } else {
          this.saveHostelResident();
        }
      }
    },
    saveHostelResident() {
      this.isBusy = true;
      this.hostelResident.user_id = this.userId;
      hostelResident
        .store(this.hostelResident)
        .then((response) => {
          this.isBusy = false;
          this.edit = false;
          this.resetForm();
          this.$snotify.success("Information added");
        })
        .catch((error) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },

    updateHostelResident() {
      this.isBusy = true;
      hostelResident
        .update(this.hostelResident.id, this.hostelResident)
        .then((response) => {
          this.isBusy = false;
          this.$snotify.success("Information updated");
          this.edit = false;
          this.resetForm();
        })
        .catch((error) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },

    getHostelResidentInfo(){
        hostelResident
        .show(this.hostelResidentId)
        .then((res) => {
            this.hostelResident = res.data.hostelResident;
            this.getHostelRooms();
        })
    },

    renewHostelResident() {
      this.$confirm({
        message: `Are you sure you want to renew?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.isRenewing = true;
            hostelResident
              .renewStudent(this.hostelResident.id)
              .then((response) => {
                this.$snotify.success("Successfully renewed");
                this.getHostelInfo();
                this.isRenewing = false;
              })
              .catch((error) => {
                this.$snotify.error("Something Went Wrong");
                this.isRenewing = false;
              });
          }
        },
      });
    },

    resetForm() {
      this.$v.hostelResident.$reset();
      this.edit = false;
      this.hostelResident = {
        hostel_id: null,
        hostel_room_id: null,
        user_id: null,
        entry_date: null,
        exit_date: null,
        fee: null,
        fee_frequency: "",
        payment_start_date: null,
        has_discount: false,
        discount_type: "",
        discount_amount: null,
        is_active: true,
      };

      this.hideModal();
      this.users = [];
      this.hostels = [];
      this.hostelRooms = [];
      this.userSearch = "";
      this.hostelRoomLoading = false;
      this.$emit("refresh");
    },
  },
};
</script>
