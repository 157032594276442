<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card ">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Transport allocation</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \   Transport allocation
                </div>
              </div>

            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th style="max-width: 250px !important; white-space: pre-wrap;">Student full name</th>
                  <th style="max-width: 300px !important; white-space: pre-wrap;">Route</th>
                  <th >Stop</th>
                  <th>Start date</th>
                  <th >End date</th>
                  <th style="max-width: 200px !important; white-space: pre-wrap;">Status</th>
                  <th style="max-width: 200px !important; white-space: pre-wrap;">Status</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-show="transport_allocations.length > 0" v-for="(allocation,index) in transport_allocations" :key="index">
                  <td>
                      <span class="font-weight-medium">
                          {{allocation.full_name}}
                      </span>
                  </td>
                  <td>
                      <span class="font-weight-medium">
                          {{allocation.route_name}}
                      </span>
                  </td>
                  <td>
                      <span class="font-weight-medium">
                          {{allocation.stop_name}}
                      </span>
                  </td>
                  <td>
                      <span class="font-weight-medium">
                          {{allocation.start_date_format}}
                      </span>
                  </td>
                  <td>
                      <span class="font-weight-medium">
                          {{allocation.end_date_format}}
                      </span>
                  </td>
                  <td style="max-width: 200px !important; white-space: pre-wrap;">
                          <span class="badge  text-lg`"
                                v-bind:class="{ 'badge-primary': allocation.is_current, 'badge-warning': !allocation.is_current }"
                          >{{ allocation.is_current ? 'Current' : 'Not current' }}</span>
                  </td>
                  <td style="max-width: 200px !important; white-space: pre-wrap;">
                          <span class="badge text-lg`"
                                v-bind:class="{ 'badge-success': allocation.is_active, 'badge-danger': !allocation.is_active }"
                          >{{ allocation.is_active ? 'Active' : 'Inactive' }}</span>
                  </td>
                  <td class="pr-0 text-left">
                    <template>
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">


                          <b-dropdown-text tag="div" class="navi-item">
                            <a class="navi-link" @click.prevent="deleteAllocation(allocation.id)">
                                  <span class="navi-icon">
                                     <i class="fas fa-trash"></i>
                                  </span>
                              <span class="navi-text">Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </template>
                  </td>
                </tr>
                <tr v-show="transport_allocations.length==0">
                  <td colspan="8" class="text-center">
                    <strong>No data available to display.</strong>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import TransportAllocationService from "@/core/services/Transport/transport_allocations/TransportAllocationService";
const transportAllocation=new TransportAllocationService();
export default {
  props:['user'],
  data(){
    return{
      loading:false,
      transport_allocations:[],
      search:{

      },
      page: null,
      total: null,
      perPage: null
    }
  },
  methods:{
    getAllTransportAllocations(){
      this.loading=true;
      this.search.user_id=this.user.id;
      transportAllocation
          .paginate(this.search,this.page)
          .then(response => {
            this.transport_allocations=response.data.data;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.loading=false;
          });
    },
    deleteAllocation(allocationId){
      this.$confirm({
        message: `Are you sure you want to delete this? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            transportAllocation
                .delete(allocationId)
                .then((response) => {
                  this.getAllTransportAllocations();
                  this.$snotify.success("Transport allocation Information deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });}
        },
      });
    },
  },
  mounted() {
    this.getAllTransportAllocations();
  }
}
</script>