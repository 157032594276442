<template>
  <div>
    <div class="border-graph">
      <v-row>
        <v-col md="8">
          <h4>Attendance report by class</h4>
        </v-col>
        <v-col md="4" >
          <v-select label="Select a class"  v-model="academic_class_id"  class="mt-4 mr-2" item-value="id" item-text="customize_title" dense outlined :items="academic_class" @change="getAttendanceReport"></v-select>
        </v-col>
      </v-row>
      <apexchart type="bar" height="400" :options="chartOptions" :series="series"></apexchart>
    </div>

  </div>
</template>
<script>

import AcademicClassStudentService from "@/core/services/academic-class/class-student/AcademicClassStudentService";
import AttendeeService from "@/core/services/attendance/AttendeeService";

const academicClassStudent = new AcademicClassStudentService();
const attendee = new AttendeeService();
export default {
  name: "AttendanceReport",
  props:['user'],
  data() {
    return {
      academic_class_id: '',
      academic_class: [],
      series: [{
        name: 'Total class',
        data: []
      }, {
        name: 'Present days',
        data: []
      }],

      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Total class days'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return  + val + " days"
            }
          }
        }
      },


    }
  },
  methods:{
    getAllAcademicClass() {
      academicClassStudent.getByStudent(this.user.id).then(response => {
          this.academic_class = response.data.classess;
      }).catch(() => {})
    },
    getAttendanceReport()  {
      attendee.getAttendanceReportByCourse({student_id: this.user.id, class_id: this.academic_class_id}).then(response => {
        this.series = [{
          name: 'Total class',
          data: response.data.total_attendance
        }, {
          name: 'Present days',
          data: response.data.total_present
        }];
        this.chartOptions = {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: response.data.courses,
          },
          yaxis: {
            title: {
              text: 'Total class days'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " days"
              }
            }
          }
        }

      }).catch(() => {})
    }
  },
  mounted() {
    this.getAllAcademicClass();
  }
}
</script>