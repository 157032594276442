<template>
  <div>
    <div class="border-graph">
      <v-row>
        <v-col cols="12">
          <h4>Parent meet notes</h4>
        </v-col>
        <v-col md="6">          
          <v-list three-line>
            <template v-show="parentsVisitHistories.length > 0" v-for="(item, index) in parentsVisitHistories">
              <v-divider
              ></v-divider>

              <v-list-item>
                <!-- <v-list-item-avatar>
                  <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar> -->

                <!-- <v-list-item-content> -->
                  <v-list-item-title>
                    <div v-html="item"></div>
                  </v-list-item-title>
                <!-- </v-list-item-content> -->
              </v-list-item>

            </template>
            <template v-if="parentsVisitHistories.length == 0">
              <v-list-item>
                <v-list-item-title>
                  NA
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-col>

        <v-col md="6">
          <v-list three-line>
            <template v-show="noteHistories.length > 0" v-for="(item, index) in noteHistories">
              <v-divider
              ></v-divider>

              <v-list-item>
                <!-- <v-list-item-avatar>
                  <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar> -->

                <!-- <v-list-item-content> -->
                  <v-list-item-title> 
                    <p v-html="item"></p> 
                  </v-list-item-title>
                <!-- </v-list-item-content> -->
              </v-list-item>

            </template>
            
          </v-list>
        </v-col>
      </v-row>

    </div>

  </div>
</template>

<script>
import UserService from "@/core/services/user/UserService";

const userService = new UserService();
export default {
  props:['user'],
  data(){
    return{
      items: [
        { header: 'Today' },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: 'Brunch this weekend?',
          subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
          subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
        },
        { divider: true, inset: true },

      ],
      parentsVisitHistories: [],
      noteHistories: []
    }
  },
  methods:{
    getParentsVisitHistory(){
      userService
      .getParentsVisitHistory(this.user.id)
      .then((res) => {
        if(res && res.data && res.data.stats && res.data.stats.meets){
          this.parentsVisitHistories = res.data.stats.meets;
        }

        this.noteHistories = res.data.stats.note;
      })
      .catch((err) => {

      })
    }
  },
  mounted() {
    this.getParentsVisitHistory();
  }
}
</script>