<template>
  <v-app>

    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header py-5 px-5">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">Faculty</h3>
        </div>
        <div class="card-toolbar">
          <button
              type="submit"
              class="btn btn-primary text-white mr-2"
              @click.prevent="syncTeacher()"
              ref="kt_save_changes"
          >
            Save changes
          </button>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Form-->
      <div class="card-body">
        <v-row>
          <v-col cols="12">
            <v-select
                multiple
                :items="programs"
                item-value="id"
                item-text="title"
                v-model="program_ids"
                small-chips deletable-chips
                outlined
                dense clearable
                label="Select programs">

            </v-select>
          </v-col>
        </v-row>
        <!--end::Form-->
      </div>

    </div>
  </v-app>

</template>

<script>
import {mapGetters} from "vuex";
import UserService from "@/core/services/user/UserService";
import ProgramService from "@/core/services/level/program/ProgramService";
import TeacherService from "@/core/services/level/program/teacher/TeacherService";

const userService = new UserService();
const programService = new ProgramService();
const teacherService = new TeacherService();

export default {
  name: "TeacherUserFaculty",
  props: ['user'],
  validations: {},
  data() {
    return {
      studentSetting: {
        academic_year_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        class_id: null,
      },
      program_ids: [],
      departments: [],
      programs: [],
      roles: [],
      is_coordinator: false,
      selected_program: null,
    };
  },

  mounted() {
    this.getUserRole(this.user.type);
    this.getPrograms();
  },

  methods: {

    syncTeacher() {
      if (this.program_ids.length > 0) {
        let data = this.program_ids.map((program_id) => {
          return {
            program_id: program_id,
            user_id: this.user.id,
          }
        })
       teacherService.syncPrograms(this.user.id, data).then((res) => {
         this.$snotify.success("Record updated");
        }).catch(() => {});
      }
    },
    getAssignedPrograms() {
      teacherService.getAssignedProgram(this.user.id).then((res) => {
        this.program_ids = res.data.programs.map(program => {
          return program.id;
        })

      }).catch(() => {})
    },

    getPrograms() {
      programService.all().then(response => {
        this.programs = response.data;
      });
    },

    getUserRole(roles = []) {
      this.is_coordinator = roles && roles.length > 0 && roles.includes('coordinator');
      this.getAssignedPrograms()
    },
  },
  computed: {
    ...mapGetters(["currentUser"])
  }
};
</script>
