import Apiservice from '@/core/services/api.service';
import { API_URL } from '../../../../common/config'
const apiService = Apiservice;


export default class AdditionalFeeService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user`;

      }
      paginate(userId, data = {}, index = 1) {
            let url = `${this.#api}/${userId}/additional-fee`;
            if (index != null)
                  url = `${url}?page=${index}`;
            let param ={
                  params: data
            }
            return apiService.query(url,param);
      }

      update(userId,additionalFeeId, data) {
            let url = `${this.#api}/${userId}/additional-fee/${additionalFeeId}`
            return apiService.put(url, data);

      }

      store(userId, data) {
            let url = `${this.#api}/${userId}/additional-fee`
            return apiService.post(url, data);

      }

      show(userId, additionalFeeId) {
            let url = `${this.#api}/${userId}/additional-fee/${additionalFeeId}`
            return apiService.get(url);
      }

      delete(userId, additionalFeeId) {
            let url = `${this.#api}/${userId}/additional-fee/${additionalFeeId}`
            return apiService.delete(url);
      }

      clone(userId, data) {
            let url = `${this.#api}/${userId}/additional-fee/get/clone`
            return apiService.post(url, data);
      }
}
