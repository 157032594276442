<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>Dashboard</h4>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <v-row>
                            <!-- <v-col cols="12" md="3">        
                                <a href="#" class="card bg-dark hoverable card-xl-stretch mb-5 mb-xl-8">
                                    <div class="card-body">
                                        <i class="ki-duotone text-white fs-2x ms-n1 fas fa-file-pdf">
                                        <span class="path1"></span></i>        
                                
                                        <div class="text-white fw-bold fs-2 mb-2 mt-5">           
                                            {{ userDocumentsCount }}                   
                                        </div>
                                
                                        <div class="fw-semibold text-white">
                                        Documents uploaded        
                                        </div>
                                    </div>
                                </a>
                            </v-col> -->

                            <!-- user badges -->
                            <v-col cols="12">

                                <span class="badge"
                                    :class="userDetail.is_login_verified ? 'badge-success' : 'badge-danger'">
                                    <i class="text-white fa"
                                        :class="userDetail.is_login_verified ? 'fa-check-circle' : 'fa-times-circle'"></i>
                                    {{ userDetail.is_login_verified ? 'Login verified' : 'Login not verified' }}
                                </span>

                                <span class="badge" :class="userDetail.is_online ? 'badge-success' : 'badge-danger'">
                                    <i class="fa text-white"
                                        :class="userDetail.is_online ? 'fa-check-circle' : 'fa-times-circle'"></i>
                                    Online portal {{ userDetail.is_online ? 'enabled' : 'disabled' }}
                                </span>
                                <span class="badge badge-success text-lg" v-if="user.enable_library">
                                    <i class="fa fa-check-circle text-white"></i> Library access
                                </span>
                                <span class="badge badge-danger text-lg" v-else>
                                    <i class="fa fa-times-circle text-white"></i> Library access
                                </span>

                                <span class="badge badge-danger"
                                    v-if="userDetail.enable_library && userDetail.is_library_membership_expired">
                                    <i class="fa fa-check-circle text-white"></i>
                                    Library card expired
                                </span>

                                <span class="badge"
                                    :class="userDetail.card_validity_expired ? 'badge-danger' : 'badge-success'"
                                    v-if="userDetail.card_validity_upto">
                                    <i class="fa text-white"
                                        :class="userDetail.card_validity_expired ? 'fa-times-circle' : 'fa-check-circle'"></i>
                                    {{ userDetail.card_validity_expired ? 'Expired' : 'Active' }} ID card
                                </span>
                                <span class="badge badge-danger" v-else>
                                    <i class="fa fa-times-circle text-white"></i>
                                    ID card not issued
                                </span>
                                <span class="badge badge-info" v-if="!userDetail.is_rfid_expiry && userDetail.rfid_id == null">
                                    <i class="fa fa-times-circle text-white"></i>
                                    RFID Card not issued
                                </span>
                                <span class="badge badge-danger" v-if="userDetail.is_rfid_expiry">
                                    <i class="fa fa-times-circle text-white"></i>
                                    RFID Card expired
                                </span>
                                <span class="badge badge-primary" v-if="!userDetail.is_rfid_expiry && userDetail.rfid_id">
                                    <i class="fa fa-check-circle text-white"></i>
                                    RFID Card issued
                                </span>
                            </v-col>
                            <!-- end: user badges -->

                            <!-- student info -->
                            <v-col cols="12" md="12">
                                <!-- account on hold start -->
                                <div v-if="userDetail.on_hold"
                                    class="notice d-flex bg-light-warning mb-5 rounded border-warning border border-dashed p-6">
                                    <!--begin::Icon-->
                                    <i class="ki-duotone ki-information fs-2tx text-warning me-4"><span
                                            class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                                    <!--end::Icon-->

                                    <!--begin::Wrapper-->
                                    <div class="d-flex flex-stack flex-grow-1">
                                        <!--begin::Content-->
                                        <div class="fw-semibold">
                                            <h4 class="text-gray-900 fw-bold">Account on hold!</h4>
                                            <div class="fs-6 text-gray-700">
                                                This account is on hold. To renew, click to
                                                <a class="fw-bold" @click="renewAccount(userDetail.id)"
                                                    href="javascript:;">activate this account</a>.
                                            </div>
                                        </div>
                                        <!--end::Content-->
                                    </div>
                                    <!--end::Wrapper-->
                                </div>
                                <!-- account on hold end -->

                                <!-- last check in/out record -->
                                <div v-if="lastCheckInOut"
                                    class="notice d-flex bg-light-warning mb-5 rounded border-warning border border-dashed p-6">
                                    <!--begin::Icon-->
                                    <i class="ki-duotone ki-information fs-2tx text-warning me-4"><span
                                            class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                                    <!--end::Icon-->

                                    <!--begin::Wrapper-->
                                    <div class="d-flex flex-stack flex-grow-1">
                                        <!--begin::Content-->
                                        <div class="fw-semibold">
                                            <h4 class="text-gray-900 fw-bold">{{ lastCheckInOut.exit_timestamp ? lastCheckInOut.readable_exit_timestamp : lastCheckInOut.readable_entry_timestamp }}</h4>
                                            <div class="fs-6 text-gray-700">
                                                {{ lastCheckInOut.exit_timestamp ? 'Last checked out time' : 'Last checked in time' }}
                                            </div>
                                        </div>
                                        <!--end::Content-->
                                    </div>
                                    <!--end::Wrapper-->
                                </div>
                                <!-- last check in/out record end -->
                                
                                <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                    <!--begin::Card header-->
                                    <div class="card-header cursor-pointer">
                                        <!--begin::Card title-->
                                        <div class="card-title m-2">
                                            <h4 class="m-0">General information</h4>
                                        </div>
                                        <!--end::Card title-->
                                    </div>
                                    <!--begin::Card header-->

                                    <!--begin::Card body-->
                                    <div class="card-body p-9">
                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">Full Name</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information">{{ userDetail.full_name ?
                                                    userDetail.full_name : 'NA' }}</span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->

                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">Email</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8 d-flex align-items-center">
                                                <span class="font-information me-2">{{ userDetail.email ?
                                                    userDetail.email : 'NA' }}</span>
                                                <span v-if="userDetail.email" class="badge"
                                                    :class="userDetail.is_email_verified ? 'badge-success' : 'badge-danger'">
                                                    {{ userDetail.is_email_verified ? 'Verified' : 'Not verified' }}
                                                </span>
                                                <a href="javascript:;" class="ml-2 font-weight-bold" v-if="!userDetail.is_email_verified" @click="verifyEmail(userDetail.id)">
                                                    Verify
                                                </a>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->

                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">CRN</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information">{{ userDetail.personal_id ?
                                                    userDetail.personal_id : 'NA' }}</span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->

                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">Symbol No.</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information">
                                                    {{ userDetail.symbol_no ? userDetail.symbol_no : 'NA' }}
                                                </span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->

                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">Board Symbol No.</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information">
                                                    {{ userDetail.board_symbol_no ? userDetail.board_symbol_no : 'NA' }}
                                                </span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->

                                        <!--begin::Input group-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">DOB (BS)</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8 fv-row">
                                                 <span v-if="userDetail.dob_bs" class="">
                                                    {{ userDetail.dob_bs ? userDetail.dob_bs : 'NA' }}
                                                </span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">DOB (AD)</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8 fv-row">
                                                <span class="">
                                                   {{ userDetail.dob ? userDetail.formatted_dob : 'NA' }}
                                                </span>

                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Input group-->

                                        <!--begin::Input group-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">
                                                Phone
                                                <span class="ms-1" data-bs-toggle="tooltip"
                                                    aria-label="Phone number must be active"
                                                    data-bs-original-title="Phone number must be active"
                                                    data-kt-initialized="1">
                                                    <i class="ki-duotone ki-information fs-7"><span
                                                            class="path1"></span><span class="path2"></span><span
                                                            class="path3"></span></i>
                                                </span>
                                            </label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8 d-flex align-items-center">
                                                <span class="font-information me-2">{{ userDetail.phone ?
                                                    userDetail.phone : 'NA' }}</span>
                                                <span v-if="userDetail.phone" class="badge"
                                                    :class="userDetail.is_phone_verified ? 'badge-success' : 'badge-danger'">
                                                    {{ userDetail.is_phone_verified ? 'Verified' : 'Not verified' }}
                                                </span>
                                                <a href="javascript:;" class="ml-2 font-weight-bold" v-if="userDetail.phone && !userDetail.is_phone_verified" @click="verifyPhone(userDetail.id)">
                                                    Verify
                                                </a>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Input group-->

                                        <!--begin::Input group-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">
                                                Mobile
                                                <span class="ms-1" data-bs-toggle="tooltip"
                                                    aria-label="Phone number must be active"
                                                    data-bs-original-title="Phone number must be active"
                                                    data-kt-initialized="1">
                                                    <i class="ki-duotone ki-information fs-7"><span
                                                            class="path1"></span><span class="path2"></span><span
                                                            class="path3"></span></i>
                                                </span>
                                            </label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8 d-flex align-items-center">
                                                <span class="font-information me-2">{{ userDetail.mobile ?
                                                    userDetail.mobile : 'NA' }}</span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Input group-->

                                        <!--begin::Input group-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">
                                                RFID card expiry date
                                                <span class="ms-1" data-bs-toggle="tooltip"
                                                    aria-label="Phone number must be active"
                                                    data-bs-original-title="Phone number must be active"
                                                    data-kt-initialized="1">
                                                    <i class="ki-duotone ki-information fs-7"><span
                                                            class="path1"></span><span class="path2"></span><span
                                                            class="path3"></span></i>
                                                </span>
                                            </label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8 d-flex align-items-center">
                                                <span class="font-information me-2">{{ userDetail.rfid_expiry_date ?
                                                    userDetail.rfid_expiry_date : 'NA' }}</span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Input group-->


                                        <!--begin::Input group-->
                                        <div class="row" v-if="userDetail.joining_date">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">
                                                Joining date
                                            </label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information">
                                                    {{ userDetail.joining_date }}
                                                </span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Input group-->


                                        <div v-if="scholarship"
                                            class="notice bg-light-primary rounded border-primary border mt-5 border-dashed flex-stack h-xl-100 mb-5 p-6">
                                            <!--begin::Wrapper-->
                                            <div class="d-flex justify-space-between">
                                                <!--begin::Content-->
                                                <div class="mb-3 mb-md-0 fw-semibold">
                                                    <h4 class="text-gray-900 fw-bold">Scholarship active</h4>
                                                    <div class="fs-6 text-gray-700 pe-7">
                                                        {{ scholarship.scholarship_name }} <br>
                                                        <strong>Program: </strong>{{ scholarship.program_name }} <br>
                                                        {{ scholarship.academic_year_name }}
                                                    </div>
                                                </div>
                                                <!--end::Content-->

                                                <!--begin::Action-->
                                                <!-- <div>
                                                    <a href="#" class="btn btn-primary px-6 align-self-center text-nowrap"
                                                        data-bs-toggle="modal" data-bs-target="#kt_modal_new_address">
                                                        View
                                                    </a>
                                                </div> -->
                                                <!--end::Action-->
                                            </div>
                                            <!--end::Wrapper-->
                                        </div>
                                    </div>
                                    <!--end::Card body-->
                                </div>
                            </v-col>
                            <!-- student info ends -->

                            <!-- academic detail -->
                            <v-col cols="12" md="12">
                                <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                    <!--begin::Card header-->
                                    <div class="card-header cursor-pointer">
                                        <!--begin::Card title-->
                                        <div class="card-title m-2">
                                            <h4 class="m-0">Address details</h4>
                                        </div>
                                        <!--end::Card title-->
                                    </div>
                                    <!--begin::Card header-->

                                    <!--begin::Card body-->
                                    <div class="card-body p-9">
                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-12 text-red"
                                                v-if="userDetail.permanent_address && userDetail.permanent_address.length < 1">Permanent address not
                                                available</label>


                                            <label class="col-lg-4">Address Line 1</label>


                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information"
                                                    v-if="userDetail.permanent_address && userDetail.permanent_address.length > 0">{{
                                                        userDetail.permanent_address[0].address_line_1 }}</span>
                                                <span class="font-information" v-else>NA</span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->
                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">Area</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information"
                                                    v-if="userDetail.permanent_address && userDetail.permanent_address.length > 0">
                                                    {{ userDetail.permanent_address[0].area }}
                                                </span>
                                                <span class="font-information" v-else>NA</span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->

                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">Ward</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information"
                                                    v-if="userDetail.permanent_address && userDetail.permanent_address.length > 0">
                                                    {{ userDetail.permanent_address[0].ward }}
                                                </span>
                                                <span class="font-information" v-else>NA</span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->

                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">City</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information"
                                                    v-if="userDetail.permanent_address && userDetail.permanent_address.length > 0">
                                                    {{ userDetail.permanent_address[0].city }}
                                                </span>
                                                <span class="font-information" v-else>NA</span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->

                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">District</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information"
                                                    v-if="userDetail.permanent_address && userDetail.permanent_address.length > 0">
                                                    {{ userDetail.permanent_address[0].district }}
                                                </span>
                                                <span class="font-information" v-else>NA</span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">State</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information"
                                                    v-if="userDetail.permanent_address && userDetail.permanent_address.length > 0">
                                                    {{ userDetail.permanent_address[0].state }}
                                                </span>
                                                <span class="font-information" v-else>NA</span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->
                                    </div>
                                    <!--end::Card body-->
                                </div>
                                

                                <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                    <!--begin::Card header-->
                                    <div class="card-header cursor-pointer">
                                        <!--begin::Card title-->
                                        <div class="card-title m-2">
                                            <h4 class="m-0">Academic details</h4>
                                        </div>
                                        <!--end::Card title-->
                                    </div>
                                    <!--begin::Card header-->

                                    <!--begin::Card body-->
                                    <div class="card-body p-9">
                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">Academic year</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information">{{ userDetail.academic_year ?
                                                    userDetail.academic_year : 'NA' }}</span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->
                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">Level</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information">
                                                    {{ userDetail.level ? userDetail.level : 'NA' }}
                                                </span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->

                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">Program</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information">
                                                    {{ userDetail.program ? userDetail.program : 'NA' }}
                                                </span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->

                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">Grade/Semester</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information">
                                                    {{ userDetail.grade ? userDetail.grade : 'NA' }}
                                                </span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->

                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">Class</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information">
                                                    {{ userDetail.class ? userDetail.class : 'NA' }}
                                                </span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->
                                    </div>
                                    <!--end::Card body-->
                                </div>

                                <!-- parents information -->
                                <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                    <!--begin::Card header-->
                                    <div class="card-header cursor-pointer">
                                        <!--begin::Card title-->
                                        <div class="card-title m-2">
                                            <h4 class="m-0">Parents information</h4>
                                        </div>
                                        <!--end::Card title-->
                                    </div>
                                    <!--begin::Card header-->

                                    <!--begin::Card body-->

                                    <div class="card-body p-9" v-if="userDetail.preferred_parents_information">
                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">Full name</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information">
                                                    {{ userDetail.preferred_parents_information.full_name ?
                                                        userDetail.preferred_parents_information.full_name : 'NA' }}
                                                </span>
                                                <span class="badge badge-primary text-capitalize">
                                                    {{ userDetail.preferred_parents_information.relation }}
                                                </span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->

                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">Email</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information">
                                                    {{ userDetail.preferred_parents_information.email ?
                                                        userDetail.preferred_parents_information.email : 'NA' }}
                                                </span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->

                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">Phone</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information">
                                                    {{ userDetail.preferred_parents_information.phone ?
                                                        userDetail.preferred_parents_information.phone : 'NA' }}
                                                </span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->

                                        <!--begin::Row-->
                                        <div class="row">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 ">Mobile</label>
                                            <!--end::Label-->

                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <span class="font-information">
                                                    {{ userDetail.preferred_parents_information.mobile ?
                                                        userDetail.preferred_parents_information.mobile : 'NA' }}
                                                </span>
                                            </div>
                                            <!--end::Col-->
                                        </div>
                                        <!--end::Row-->
                                    </div>
                                    <div class="card-body p-9" v-else>
                                        <div class="row">
                                            <div class="col-md-12">
                                                No preferred parent information available
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Card body-->
                                </div>
                            </v-col>
                            <!-- academic detail ends -->

                            <v-col cols="12">

                                <library-timeline :user="user"></library-timeline>
                                <p class="badge badge-warning mt-5"
                                    v-if="userDetail.enable_library && !userDetail.is_library_membership_expired">
                                    <i class="fa fa-hourglass text-white"></i> Library card expiring in
                                    {{ userDetail.library_membership_remaining_days }} ({{
                                        userDetail.library_membership_expiry ? userDetail.library_membership_expiry : '' }})
                                </p>
                            </v-col>
                            <v-col cols="12">
                                <!-- <suggestion-and-notes :user="user"></suggestion-and-notes> -->
                            </v-col>
                            <!-- recent notes -->
                            <v-col cols="12" md="12" xl="6">
                                <div class="mb-2">
                                    <!--begin::Card body-->
                                    <div class="p-0">
                                        <!--begin::Table wrapper-->
                                        <div class="d-flex justify-space-between">
                                            <h4 class="m-0">Notes</h4>
                                            <a @click="gotoNotes" v-if="recentNotes.length > 0" href="javascript:;"
                                                class="font-weight-bold mr-3">View</a>
                                        </div>
                                        <div class="">
                                            <!--begin::Table-->
                                            <table v-if="recentNotes.length > 0"
                                                class="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
                                                <!--begin::Thead-->
                                                <thead class="border-gray-200 fs-5 fw-semibold bg-lighten">
                                                    <tr>
                                                        <th class="min-w-250px">Title</th>
                                                        <th class="min-w-150px">Date</th>
                                                    </tr>
                                                </thead>
                                                <!--end::Thead-->

                                                <!--begin::Tbody-->
                                                <tbody class="fw-semibold text-gray-600">
                                                    <tr v-for="(item, index) in recentNotes">
                                                        <td>
                                                            <div class="wrap-column">
                                                                {{ item.title }}
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div class="wrap-column">
                                                                {{ item.created_at ? item.created_at : '-' }}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <!--end::Tbody-->
                                            </table>
                                            <!--end::Table-->
                                            <div class="card mt-3" v-if="recentNotes.length == 0">
                                                <div class="card-body px-2 py-4">
                                                    No notes found
                                                </div>
                                            </div>
                                        </div>
                                        <!--end::Table wrapper-->
                                    </div>
                                    <!--end::Card body-->
                                </div>
                            </v-col>
                            <!-- recent notes ends -->

                            <!-- health record -->
                            <v-col cols="12" md="12" xl="6">
                                <div class="mb-2">
                                    <!--begin::Card body-->
                                    <div class="card-body p-0">
                                        <!--begin::Table wrapper-->
                                        <div class="d-flex justify-space-between">
                                            <h4 class="m-0">Health records</h4>
                                            <a v-if="recentStudentHealthRecords.length > 0" @click="gotoHealthRecord"
                                                href="javascript:;" class="font-weight-bold mr-3">View</a>
                                        </div>
                                        <div class="">

                                            <!--begin::Table-->
                                            <table v-if="recentStudentHealthRecords.length > 0"
                                                class="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
                                                <!--begin::Thead-->
                                                <thead class="border-gray-200 fs-5 fw-semibold bg-lighten">
                                                    <tr>
                                                        <th class="px-3 wrap-column">Title</th>
                                                        <th class="px-3 wrap-column">Type</th>
                                                        <th class="px-3 wrap-column">Date of incident</th>
                                                    </tr>
                                                </thead>
                                                <!--end::Thead-->

                                                <!--begin::Tbody-->
                                                <tbody class="fw-semibold text-gray-600">
                                                    <tr v-for="(item, index) in recentStudentHealthRecords">
                                                        <td class="px-3 wrap-column">
                                                            <div><strong> {{ item.title }} </strong> </div>
                                                            <div class="mt-2 mb-3">
                                                                <span class="badge"
                                                                    :class="item.is_critical ? 'badge-danger' : 'badge-primary'">
                                                                    {{ item.is_critical ? 'Critical' : 'Not critical' }}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td class="px-3 wrap-column pt-4">
                                                            <div class="font-weight-bold text-capitalize">
                                                                {{ item.type }}
                                                            </div>
                                                        </td>
                                                        <td class="px-3 wrap-column">
                                                            <div class="mt-2" v-if="item.type == 'incident'">
                                                                {{ item.date_of_incident }}
                                                            </div>

                                                            <div class="mt-2 mb-3" v-if="item.type == 'incident'">
                                                                <span class="badge"
                                                                    :class="item.notified_to_parent ? 'badge-success' : 'badge-danger'">
                                                                    {{ item.notified_to_parent ? 'Notified parent' : 'Parent
                                                                                                                                        not notified'}}
                                                                </span>
                                                            </div>

                                                            <div class="mt-2 mb-3" v-if="item.type == 'record'">
                                                                NA
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <!--end::Tbody-->
                                            </table>
                                            <!--end::Table-->
                                            <div class="card mt-3" v-if="recentStudentHealthRecords.length == 0">
                                                <div class="card-body px-2 py-4">
                                                    No records found
                                                </div>
                                            </div>
                                        </div>
                                        <!--end::Table wrapper-->
                                    </div>
                                    <!--end::Card body-->
                                </div>
                            </v-col>
                            <!-- health record ends -->

                            <!-- parent meet -->
                            <v-col cols="12" md="12" xl="6">
                                <div class="mb-2">
                                    <!--begin::Card body-->
                                    <div class="card-body p-0">
                                        <!--begin::Table wrapper-->
                                        <div class="d-flex justify-space-between">
                                            <h4 class="m-0">Parent meets</h4>
                                            <a v-if="parentMeets.length > 0" @click="gotoParentsMeet()" href="javascript:;"
                                                class="font-weight-bold mr-3">View</a>
                                        </div>
                                        <div class="">

                                            <!--begin::Table-->
                                            <table v-if="parentMeets.length > 0"
                                                class="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
                                                <!--begin::Thead-->
                                                <thead class="border-gray-200 fs-5 fw-semibold bg-lighten">
                                                    <tr>
                                                        <th class="px-3 wrap-column">Title</th>
                                                        <th class="px-3 wrap-column">Reason</th>
                                                        <th class="px-3 wrap-column">Requested visit date</th>
                                                        <th class="px-3 wrap-column">Class</th>
                                                        <th class="px-3 wrap-column">Status</th>
                                                    </tr>
                                                </thead>
                                                <!--end::Thead-->

                                                <!--begin::Tbody-->
                                                <tbody class="fw-semibold text-gray-600">
                                                    <tr v-if="parentMeets.length > 0" v-for="(item, index) of parentMeets"
                                                        :key="item.id">
                                                        <td class="px-3 wrap-column">
                                                            <div><strong> {{ item.title }} </strong> </div>
                                                            <div v-if="item.meeting_type">
                                                                <small>Meeting type: {{ item.meeting_type_text }}</small>

                                                                <div v-if="item.meeting_type == 'online'">
                                                                    <small>
                                                                        Meeting link :
                                                                        <a :href="item.online_meeting_link" target="_blank">
                                                                            {{ item.online_meeting_link }}
                                                                        </a>
                                                                    </small>
                                                                </div>

                                                                <div v-if="item.meeting_type == 'outside_premise'">
                                                                    <small>
                                                                        Meeting link : {{ item.outside_premise_location }}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="px-3 wrap-column">
                                                            <div v-if="item.request_for_visit_reason"
                                                                v-html="item.request_for_visit_reason"></div>
                                                            <div v-else>NA</div>
                                                        </td>
                                                        <td class="px-3 wrap-column">
                                                            <div>
                                                                {{ item.datetime_requested_to_visit_formatted ?
                                                                    item.datetime_requested_to_visit_formatted : 'NA' }}
                                                            </div>
                                                        </td>

                                                        <td class="px-3 wrap-column">
                                                            <div>
                                                                {{ item.class_title ? item.class_title : 'NA' }}
                                                            </div>
                                                            <div>
                                                                {{ item.class_unique_no }}
                                                            </div>
                                                        </td>

                                                        <td class="px-3 pt-4 wrap-column">
                                                            <span class="badge text-lg"
                                                                :class="item.status == 'not_attended' ? 'badge-danger' : 'badge-success'">
                                                                {{ item.status_text }}
                                                            </span>
                                                            <div class="mt-2" v-if="item.status == 'not_attended'">
                                                                <strong>Reason :</strong>
                                                                <span v-html="item.not_attended_reason"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <!--end::Tbody-->
                                            </table>
                                            <!--end::Table-->
                                            <div class="card mt-3" v-if="parentMeets.length == 0">
                                                <div class="card-body px-2 py-4">
                                                    No records found
                                                </div>
                                            </div>
                                        </div>
                                        <!--end::Table wrapper-->
                                    </div>
                                    <!--end::Card body-->
                                </div>
                            </v-col>
                            <!-- parent meet ends -->

                            <!-- pending leave requests -->
                            <v-col cols="12" md="12" xl="6">
                                <!--begin::Card body-->
                                <div class="card-body p-0">
                                    <!--begin::Table wrapper-->
                                    <div class="d-flex justify-space-between">
                                        <h4 class="m-0">Pending leave requests</h4>
                                        <a v-if="pendingLeaveRequests.length > 0" @click="gotoLeaveRequest"
                                            href="javascript:;" class="font-weight-bold mr-3">View all</a>
                                    </div>
                                    <div class="">

                                        <!--begin::Table-->
                                        <table v-if="pendingLeaveRequests.length > 0"
                                            class="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
                                            <!--begin::Thead-->
                                            <thead class="border-gray-200 fs-5 fw-semibold bg-lighten">
                                                <tr>
                                                    <th class="px-3 wrap-column">Title</th>
                                                    <th class="px-3 wrap-column">Category</th>
                                                </tr>
                                            </thead>
                                            <!--end::Thead-->

                                            <!--begin::Tbody-->
                                            <tbody class="fw-semibold text-gray-600">
                                                <tr v-show="pendingLeaveRequests.length > 0" :key="index"
                                                    v-for="(leave, index) of pendingLeaveRequests">
                                                    <td class="wrap-column" scope="row">
                                                        <span class="font-weight-bolder text-hover-primary mb-1">
                                                            {{ leave.title }}</span> &nbsp;
                                                        <i :class="leave.is_active ? 'dot-active' : 'dot-inactive'"
                                                            class="fas fa-circle"></i>
                                                        <div>
                                                            <small>
                                                                <span class="font-weight-medium">
                                                                    {{ leave.leave_start_date | moment("Do MMMM YYYY") }}
                                                                </span>
                                                            </small>
                                                            <small v-if="leave.leave_end_date">
                                                                <span class="font-weight-medium">
                                                                    - {{ leave.leave_end_date | moment("Do MMMM YYYY") }}
                                                                </span>
                                                            </small>
                                                        </div>
                                                        <div class="text-secondary">
                                                            <span class="font-weight-bold">Requested by :</span><span
                                                                class="font-weight-medium" v-if="leave.requested_by_name">
                                                                {{ leave.requested_by_name }}</span><span
                                                                class="font-weight-medium">
                                                                ({{ leave.requested_by_text }})</span>
                                                        </div>
                                                    </td>

                                                    <td class="px-3 wrap-column">
                                                        <span class="font-weight-medium">
                                                            {{ leave.category }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <!--end::Tbody-->
                                        </table>
                                        <!--end::Table-->
                                        <div class="card mt-3" v-if="pendingLeaveRequests.length == 0">
                                            <div class="card-body px-2 py-4">
                                                No records found
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Table wrapper-->
                                </div>
                                <!--end::Card body-->
                            </v-col>
                            <!-- pending leave requests ends -->
                        </v-row>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import UserService from "@/core/services/user/UserService";
import LibraryTimeline from "./academic-progress/LibraryTimeline.vue";
// import SuggestionAndNotes from "./academic-progress/SuggestionAndNotes.vue";

const user = new UserService();

export default {
    components: { LibraryTimeline },
    props: ["user"],

    data() {
        return {
            userDetail: '',
            recentNotes: '',
            recentStudentHealthRecords: '',
            parentMeets: '',
            pendingLeaveRequests: '',
            scholarship: '',
            userDocumentsCount: '',
            lastCheckInOut: '',
        }
    },

    methods: {
        getUserStats() {
            user
                .getStats(this.user.id)
                .then((res) => {
                    this.userDetail = res.data.userDetail;
                    this.recentNotes = res.data.recentNotes;
                    this.recentStudentHealthRecords = res.data.studentHealthRecords;
                    this.parentMeets = res.data.parentMeets;
                    this.pendingLeaveRequests = res.data.pendingLeaveRequests;
                    this.scholarship = res.data.scholarship;
                    this.userDocumentsCount = res.data.userDocumentsCount;
                    this.lastCheckInOut = res.data.lastCheckInOut;
                })
                .catch((err) => {

                })
        },
        renewAccount(userDetailId) {
            this.$confirm({
                message: `Are you sure you want to activate this account?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        user
                            .renew(userDetailId)
                            .then(response => {
                                this.$snotify.success('Successfully renewed')
                                this.getUserStats();
                            })
                            .catch(error => {
                            });
                    }
                }
            });
        },
        verifyEmail(id){
            this.$confirm({
                message: `Are you sure you want to verify this email?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        user
                            .verifyEmail(id)
                            .then(response => {
                                this.$snotify.success('Email verified')
                                this.getUserStats();
                            })
                            .catch(error => {
                            });
                    }
                }
            });
        },
        verifyPhone(id){
            this.$confirm({
                message: `Are you sure you want to verify this phone?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        user
                            .verifyPhone(id)
                            .then(response => {
                                this.$snotify.success('Phone verified')
                                this.getUserStats();
                            })
                            .catch(error => {
                            });
                    }
                }
            });
        },
        leavestatus(status) {
            if (status) {
                switch (status) {
                    case 'pending':
                        return 'badge-warning';
                        break;
                    case 'approved':
                        return 'badge-success';
                        break;
                    case 'decline':
                        return 'badge-danger';
                        break;

                }
            } else {
                return 'badge-warning'
            }
        },
        gotoNotes() {
            this.$emit('goto-notes');
        },
        gotoHealthRecord() {
            this.$emit('goto-health-record');
        },
        gotoParentsMeet() {
            this.$emit('goto-parents-meet');
        },
        gotoLeaveRequest() {
            this.$emit('goto-leave-request');
        }
    },

    mounted() {
        this.getUserStats();
    }
};
</script>
