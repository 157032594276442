<template>
    <v-app>
        <div class="card card-custom">
            <!--begin::Header-->
            <div class="card-header py-5 px-5">
                <div class="card-title align-items-start flex-column">
                    <h3 class="card-label font-weight-bolder text-dark">Settings</h3>

                </div>
                <div class="card-toolbar">
                    <v-btn
                            depressed
                            :loading="isBusy"
                            class="btn btn-primary text-white mr-2"
                            @click="createOrUpdate()"
                    >
                        Save changes
                    </v-btn>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Form-->
          <div v-if="loading">
            <v-skeleton-loader
                v-bind="attrs"
                type="list-item-three-line"
            ></v-skeleton-loader>
            <v-skeleton-loader
                v-bind="attrs"
                type="list-item-three-line"
            ></v-skeleton-loader>
            <v-skeleton-loader
                v-bind="attrs"
                type="list-item-three-line"
            ></v-skeleton-loader>
          </div>
          <div v-else>
            <form class="form">
                <div class="card-body">
                    <v-expansion-panels
                        v-model="panel"
                    >
                        <v-expansion-panel>
                            <v-expansion-panel-header><h6>Information</h6></v-expansion-panel-header>
                            <v-expansion-panel-content class="pt-5">
                                <div class="form-group row" v-if="isAccessible('student,admin,coordinator')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Secondary email</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <v-text-field
                                                outlined
                                                dense
                                                class="form-control"
                                                v-model="studentSetting.secondary_email"
                                                label="Secondary email"
                                        ></v-text-field>
                                    </div>
                                </div>
                                <div class="form-group row" v-if="isAccessible('admin,coordinator')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">ID card address</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <v-text-field
                                                outlined
                                                dense
                                                class="form-control"
                                                v-model="studentSetting.id_card_address"
                                                label="ID card address"
                                        ></v-text-field>
                                    </div>
                                </div>
                                <div class="form-group row" v-if="isAccessible('student,admin,coordinator')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">DOB(AD)</label>

                                    <div class="col-lg-9 col-xl-6">
                                        <v-menu
                                                v-model="menudob"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                        v-model="user.dob"
                                                        label="Date of birth(AD)"
                                                        append-icon="mdi-calendar"
                                                        readonly
                                                        outlined
                                                        dense
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @change="convertAdToBs"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                    @input="menudob = false;  convertAdToBs(); "
                                                    v-model="user.dob"
                                            ></v-date-picker>
                                        </v-menu>
                                        <!--            <span class="form-text text-muted"-->
                                        <!--              >Email will not be publicly displayed.-->
                                        <!--              <a href="#" class="kt-link">Learn more</a>.</span-->
                                        <!--            >-->
                                    </div>
                                </div>
                                <div class="form-group row" v-if="isAccessible('student,admin')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">DOB(BS)</label>

                                    <div class="col-lg-9 col-xl-6">
                                        <v-text-field
                                                outlined
                                                :disabled="user.role == 'Student' && currentUser.type != 'admin'"
                                                dense
                                                v-model="user.dob_bs"
                                                class="form-control"
                                                label="Date of birth(BS)"
                                        ></v-text-field>
                                    </div>
                                </div>
                                <div class="form-group row" v-if="isAccessible('admin,coordinator')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Account on hold</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <v-switch v-model="studentSetting.on_hold" class="form-control"></v-switch>
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel class="mt-8">
                            <v-expansion-panel-header><h6>Exam & results</h6></v-expansion-panel-header>
                            <v-expansion-panel-content class="pt-5">
                                <div class="form-group row" v-if="isAccessible('admin')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">External ID</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <v-text-field
                                                outlined
                                                dense
                                                class="form-control"
                                                v-model="studentSetting.midas_id"
                                                label="External ID"
                                                @blur="validateMidas(studentSetting.midas_id)"
                                                :disabled="user.role == 'Student' && currentUser.type != 'admin'"
                                        ></v-text-field>
                                        <span v-if="validMidasId" class="text-danger">External ID already exist</span>
                                    </div>
                                </div>
                                <div class="form-group row" v-if="isAccessible('admin,coordinator')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Board Reg. No</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <v-text-field
                                                outlined
                                                dense
                                                class="form-control"
                                                v-model="studentSetting.board_registration_number"
                                                @blur="validateRegistrationNo(studentSetting.board_registration_number)"
                                                label="Board registration no"
                                        ></v-text-field>
                                        <span v-if="validRegistratioNo" class="text-danger">Registration No. already exist</span>

                                    </div>
                                </div>
                                <div class="form-group row" v-if="isAccessible('admin,coordinator')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Symbol No.</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <v-text-field
                                                outlined
                                                dense
                                                class="form-control"
                                                v-model="studentSetting.symbol_no"
                                                label="Symbol No."
                                                @blur="validateSymbolNumber(studentSetting.symbol_no)"
                                        ></v-text-field>
                                        <span v-if="validSymbolNo" class="text-danger">Symbol No already exist</span>

                                    </div>
                                </div>

                                <div class="form-group row" v-if="isAccessible('admin,coordinator')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Graduated</label>

                                    <div class="col-lg-9 col-xl-6">
                                        <v-switch
                                            @change="changeCourseCompleted()"
                                            v-model="studentSetting.is_course_complete"
                                            class="form-control"
                                            :label="studentSetting.is_course_complete ? 'Yes' : 'No'"
                                        >
                                        </v-switch>
                                    </div>
                                </div>

                                <div class="form-group row" v-if="isAccessible('admin,coordinator') && studentSetting.is_course_complete">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Completion date</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <v-menu
                                                ref="menu3"
                                                v-model="menu3"
                                                :close-on-content-click="false"
                                                :return-value.sync="date"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                        outlined
                                                        dense
                                                        v-model="studentSetting.completion_date"
                                                        class="form-control"
                                                        label="Date of completion"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                    v-model="studentSetting.completion_date"
                                                    no-title
                                                    scrollable
                                                    @input="menu3 = false"
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="menu3 = false"> Cancel</v-btn>
                                                <v-btn text color="primary" @click="$refs.menu3.save(date)"> OK</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    <br>
                                    <div class="text-danger" v-if="$v.studentSetting.completion_date.$error">This information is required</div>
                                    </div>
                                </div>

                                <div class="form-group row" v-if="isAccessible('admin,coordinator') && studentSetting.is_course_complete">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Final division</label>
                                    <div class="col-lg-9 col-xl-6">
                                    <v-text-field dense outlined label="Division" placeholder="Division" v-model="studentSetting.final_division" class="form-control">
                                    </v-text-field>
                                    <span class="text-danger mb-2" v-if="$v.studentSetting.final_division.$error">This information is required</span>
                                    </div>
                                </div>

                                <div class="form-group row" v-if="isAccessible('admin,coordinator') && studentSetting.is_course_complete">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Final GPA</label>
                                    <div class="col-lg-9 col-xl-6">
                                    <v-text-field dense outlined label="GPA" placeholder="Grade point average" v-model="studentSetting.final_gpa" class="form-control">
                                    </v-text-field>

                                    </div>
                                </div>

                                <div class="form-group row" v-if="isAccessible('admin,coordinator') && studentSetting.is_course_complete">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Final grade</label>
                                    <div class="col-lg-9 col-xl-6">
                                    <v-text-field dense outlined label="Final grade" placeholder="Final grade" v-model="studentSetting.final_grade" class="form-control">
                                    </v-text-field>

                                    </div>
                                </div>

                                <div class="form-group row" v-if="isAccessible('admin,coordinator') && studentSetting.is_course_complete">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Transcript issue no</label>
                                    <div class="col-lg-9 col-xl-6">
                                    <v-text-field dense outlined label="Transcript issue no" placeholder="Transcript issue no" v-model="studentSetting.transcript_issue_no" class="form-control">
                                    </v-text-field>
                                    <span class="text-danger mb-2" v-if="$v.studentSetting.transcript_issue_no.$error">This information is required</span>
                                    </div>
                                </div>

                                <div class="form-group row" v-if="isAccessible('admin,coordinator')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Withheld future results</label>
                                    <div class="col-lg-9 col-xl-6">
                                    <v-switch
                                        v-model="studentSetting.is_result_withheld"
                                        :label="studentSetting.is_result_withheld ? 'Yes' : 'No'"
                                    >
                                    </v-switch>
                                    </div>
                                </div>

                                <div class="form-group row" v-if="isAccessible('admin,coordinator') && studentSetting.is_result_withheld">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Withheld reason</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <ckeditor
                                            v-model="studentSetting.withheld_reason"
                                            :config="editorConfig"
                                        ></ckeditor>
                                        <span class="text-danger" v-if="$v.studentSetting.withheld_reason.$error">This information is required</span>
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel class="mt-8">
                            <v-expansion-panel-header><h6>Travel</h6></v-expansion-panel-header>
                            <v-expansion-panel-content class="pt-5">
                                <div class="form-group row" v-if="isAccessible('admin,coordinator')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Bus routes</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <v-select
                                                v-model="studentSetting.route_id"
                                                outlined
                                                dense
                                                item-value="id"
                                                item-text="title"
                                                :items="routes"
                                                label="Bus routes"
                                                @change="__getStops(studentSetting.route_id)"

                                        >
                                        </v-select>
                                    </div>
                                    <label class="col-xl-3 col-lg-3 col-form-label">Bus stops</label>

                                    <div class="col-lg-9 col-xl-6">
                                        <v-select
                                                v-model="studentSetting.stop_id"
                                                outlined
                                                dense
                                                item-value="id"
                                                item-text="title"
                                                :items="stops"
                                                label="Bus stops"
                                        >
                                        </v-select>
                                    </div>
                                </div>

                                <div class="form-group row" v-if="isAccessible('admin,coordinator')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Bus route description</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <ckeditor
                                            v-model="studentSetting.bus_route_description"
                                            :config="editorConfig"
                                        ></ckeditor>
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel class="mt-8">
                            <v-expansion-panel-header><h6>Admission</h6></v-expansion-panel-header>
                            <v-expansion-panel-content class="pt-5">
                                <div class="form-group row" v-if="isAccessible('admin,coordinator')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Admission date</label>

                                    <div class="col-lg-9 col-xl-6">
                                        <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :return-value.sync="date"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    v-model="studentSetting.admission_date"
                                                    class="form-control"
                                                    label="Date of admission"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="studentSetting.admission_date"
                                                no-title
                                                scrollable
                                                @input="
                                                menu = false;
                                                convertAdToBs();
                                            ">
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="menu = false"> Cancel</v-btn>
                                                <v-btn text color="primary" @click="$refs.menu.save(date)"> OK</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                        <!--            <span class="form-text text-muted"-->
                                        <!--              >Email will not be publicly displayed.-->
                                        <!--              <a href="#" class="kt-link">Learn more</a>.</span-->
                                        <!--            >-->
                                    </div>
                                </div>

                                <div class="form-group row" v-if="isAccessible('admin,coordinator')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Join date</label>

                                    <div class="col-lg-9 col-xl-6">
                                    <v-menu
                                        ref="menu"
                                        v-model="joining_date_menu"
                                        :close-on-content-click="false"
                                        :return-value.sync="date"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="studentSetting.join_date"
                                            class="form-control"
                                            label="Date of join"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="studentSetting.join_date"
                                            no-title
                                            scrollable :max="today"
                                        >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="joining_date_menu = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menu.save(date)"
                                        >
                                            OK
                                        </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                    <!--            <span class="form-text text-muted"-->
                                    <!--              >Email will not be publicly displayed.-->
                                    <!--              <a href="#" class="kt-link">Learn more</a>.</span-->
                                    <!--            >-->
                                    </div>
                                </div>

                                <div class="form-group row" v-if="isAccessible('admin,')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Admission notes</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <ckeditor
                                                v-model="studentSetting.admission_notes"
                                                :config="editorConfig"
                                        >
                                        </ckeditor>
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel class="mt-8">
                            <v-expansion-panel-header><h6>Health</h6></v-expansion-panel-header>
                            <v-expansion-panel-content class="pt-5">
                                <div class="form-group row" v-if="isAccessible('admin,coordinator')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Blood group</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <v-select :items="blood_groups"
                                                item-value="value" item-text="text" outlined dense v-model="studentSetting.blood_group" class="form-control"
                                                label="Blood group">
                                        </v-select>
                                    </div>
                                </div>

                                <div class="form-group row" v-if="isAccessible('admin,coordinator')">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Any known medical conditions</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <v-switch
                                            v-model="studentSetting.has_medical_conditions"
                                            :label="studentSetting.has_medical_conditions ? 'Yes' : 'No'"
                                            class="form-control"
                                        ></v-switch>
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>




                    <!--          <div class="form-group row" v-if="isAccessible('admin')">-->
                    <!--            <label class="col-xl-3 col-lg-3 col-form-label text-right"-->
                    <!--              >Payment Frequency</label-->
                    <!--            >-->
                    <!--            <div class="col-lg-9 col-xl-6">-->
                    <!--              <v-select-->
                    <!--                outlined-->
                    <!--                dense-->
                    <!--                v-model="studentSetting.payment_frequency"-->
                    <!--                :items="['Semester', 'Monthly']"-->
                    <!--                label="Payment Frequency"-->
                    <!--                class="form-control"-->
                    <!--              ></v-select>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <!--          <div class="form-group row" v-if="isAccessible('admin')">-->
                    <!--            <label class="col-xl-3 col-lg-3 col-form-label "-->
                    <!--              >Total Payable Fee</label-->
                    <!--            >-->
                    <!--            <div class="col-lg-9 col-xl-6">-->
                    <!--              <v-text-field-->
                    <!--                v-model="studentSetting.total_payable_fee"-->
                    <!--                outlined-->
                    <!--                dense-->
                    <!--                class="form-control"-->
                    <!--                label="Total Payable Fee for the Program"-->
                    <!--              >-->
                    <!--              </v-text-field>-->

                    <!--              &lt;!&ndash;            <span class="form-text text-muted"&ndash;&gt;-->
                    <!--              &lt;!&ndash;              >Email will not be publicly displayed.&ndash;&gt;-->
                    <!--              &lt;!&ndash;              <a href="#" class="kt-link">Learn more</a>.</span&ndash;&gt;-->
                    <!--              &lt;!&ndash;            >&ndash;&gt;-->
                    <!--            </div>-->
                    <!--          </div>-->




                </div>
            </form>
          </div>
            <!--end::Form-->
        </div>
    </v-app>
</template>

<script>
    import {required,requiredIf} from "vuelidate/lib/validators";
    import UserService from "@/core/services/user/UserService";
    import JobPositionService from "@/core/services/job-position/JobPositionService";
    import ProgramService from "@/core/services/level/program/ProgramService";
    import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
    import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
    import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
    import LevelService from "@/core/services/level/LevelService";
    import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
    import StudentSettingService from "@/core/services/user/student/StudentSettingService";
    import CourseService from "@/core/services/courses/CoursesService";
    import ConfigService from "@/core/services/config/ConfigService";
    import RoutesService from "@/core/services/Transport/Routes/RoutesService";
    import StopsService from "@/core/services/Transport/StopsService";

    const studentSettingService = new StudentSettingService();
    const userService = new UserService();
    const positionService = new JobPositionService();
    const programService = new ProgramService();
    const gradeLevelService = new GradeLevelService();
    const academicClassService = new AcademicClassService();
    const academicYearService = new AcademicYearService();
    const levelService = new LevelService();
    const enrollmentService = new UserEnrollmentService();
    const configService = new ConfigService();
    const courseService = new CourseService();
    const routesService = new RoutesService();
    const stopsService = new StopsService();
    export default {
        name: "student-Settings",
        props: ["user","userId", "studentSetting"],
        validations() {
            return {
              studentSetting:{
                completion_date: {
                  required: requiredIf(function () {
                    return this.studentSetting.is_course_complete;
                  })
                },

                final_division: {
                  required: requiredIf(function () {
                    return this.studentSetting.is_course_complete;
                  })
                },

                transcript_issue_no: {
                  required: requiredIf(function () {
                    return this.studentSetting.is_course_complete;
                  })
                },

                withheld_reason:{
                    required: requiredIf(function() {
                        return this.studentSetting.is_result_withheld
                    })
                }
              }
            };
        },
        data() {
            return {
                attrs: '',
                isBusy: false,
                loading: false,
                sizeExceed: false,
                job_types: ["admin", "student", "coordinator", "principle"],
                programs: [],
                optional_courses: [],
                selected_optional_courses: [],
                academicYears: [],
                gradesLevels: [],
                academic_classes: [],
                routes: [],
                stops: [],
                levels: [],
                levelId: null,
                menudob: false,
                menu2: false,
                menu3: false,
                menu: false,
                validMidasId: false,
                validRegistratioNo: false,
                validSymbolNo: false,
                date: new Date().toISOString().substr(0, 10),
                blood_groups:[
                  {text:'A+',value:'A+'},
                  {text:'A-',value:'A-'},
                  {text:'B',value:'B'},
                  {text:'B-',value:'B-'},
                  {text:'O+',value:'O+'},
                  {text:'O-',value:'O-'},
                  {text:'AB+',value:'AB+'},
                  {text:'AB-',value:'AB-'},
                ],
                joining_date_menu:false,
                editorConfig: {
                  versionCheck: false,
                    toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'NumberedList', 'BulletedList']]
                },
                panel: [0],
            };
        },

        mounted() {
            // this.getUser(this.id);
            this.__getRoutes();
            // this.__getStops(this.studentSetting.stop_id);
            // this.userId ? this.getUser(this.userId) : "";
        },
        computed: {
            id() {
                return this.$route.params.id ? this.$route.params.id : false;
            },
            currentUser() {
                return this.$store.getters.currentUser;
            },
            rules() {
                let rule = {
                    title: {required},
                    first_name: {required},
                    last_name: {required},
                    type: {required},
                };
                if (!this.id) {
                    rule.email = {required};
                    rule.phone = {required};
                }
                return rule;
            },
          today(){
            return   this.$moment(new Date).format('YYYY-MM-DD');
          },
        },
        methods: {
            getUser(id) {
                userService.show(id).then((response) => {
                    this.user = response.data.user;
                    this.user.is_active ? (this.user.is_active = true) : false;
                    this.user.is_email_verified ? (this.user.is_email_verified = true) : false;
                    if (this.user.setting) {
                        this.studentSetting = this.user.setting;
                        delete this.studentSetting.optional_courses;
                        this.edit = true;
                        this.getLevels();
                        this.getPrograms();
                        this.getGrades();
                        this.getAcademicClasses();
                        this.getOptionalCourses();
                        this.mapOptionalCourse();
                    }
                    this.loading=false;
                    this.getLevels();
                    this.__getRoutes();
                    this.__getStops();
                    this.getAcademicYears();

                });
            },
            __getRoutes() {
                routesService
                    .paginate()
                    .then(response => {
                        this.routes = response.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
            __getStops(routeId) {
                stopsService
                    .getByRoute(routeId)
                    .then(response => {
                        this.stops = response.data.stops;
                        console.log(this.stops);
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
            mapOptionalCourse() {
                let ids = this.studentSetting.optional_courses.map((courses) => {
                    return courses.id;
                });
                this.selected_optional_courses = ids;
            },
            getLevels() {
                levelService.all().then((response) => {
                    this.levels = response.data;
                    if (this.studentSetting.id && this.studentSetting.level_id) {
                    }
                });
            },
            validateMidas(midas_id) {
                userService.validateMidasId(midas_id).then(response => {
                    this.validMidasId = response.data.valid;
                }).catch(err => {
                    // console.log(err)
                }).finally(() => {

                })
            },
            validateRegistrationNo(registration_no) {
                userService.validateRegistratioNo(registration_no).then(response => {
                    this.validRegistratioNo = response.data.valid;
                }).catch(err => {
                    // console.log(err)
                }).finally(() => {

                })
            },
            validateSymbolNumber(symbol_no) {
                userService.validateSymbolNo(symbol_no).then(response => {
                    this.validSymbolNo = response.data.valid;
                }).catch(err => {
                    // console.log(err)
                }).finally(() => {

                })
            },
            getPrograms() {
                programService.getAllByLevelId(this.studentSetting.level_id).then((response) => {
                    this.programs = response.data;
                });
            },
            getAcademicYears() {
                academicYearService.all().then((response) => {
                    this.academicYears = response.data;
                });
            },
            getGrades() {
                gradeLevelService.getByProgram(this.studentSetting.program_id).then((response) => {
                    this.gradesLevels = response.data;
                });
            },

            getOptionalCourses() {
                courseService
                    .getOptionalCourseByProgramAndGrade(
                        this.studentSetting.program_id,
                        this.studentSetting.grade_id
                    )
                    .then((response) => {
                        this.optional_courses = response.data.courses;

                        if (this.optional_courses.length < 1) {
                            this.studentSetting.optional_courses = [];
                        }
                    });
            },
            getAcademicClasses() {
                academicClassService
                    .getByProgramLevel(
                        this.studentSetting.academic_year_id,
                        this.studentSetting.program_id,
                        this.studentSetting.grade_id
                    )
                    .then((response) => {
                        this.academic_classes = response.data;
                    });
            },
            save(date) {
                this.$refs.menu2.save(date);
            },
            convertAdToBs() {
                let data = {
                    dob: this.user.dob,
                };
                enrollmentService
                    .convertAdToBs(data)
                    .then((response) => {
                        this.user.dob_bs = response.data.dob;
                    })
                    .catch((error) => {
                        //console.log(error);
                    });
            },
            redirectTo() {
                this.$router.push({name: "user"});
            },
            isAccessible(roles) {
                let flag = [];
                let type = this.currentUser.type;
                let userRoles = configService.getRoles(type);
                if (roles != null && roles != undefined && userRoles != null && userRoles != undefined) {
                    roles = roles.split(",");
                    for (let t of roles) {
                        t = t.trim();
                        if (userRoles.includes(t)) {
                            flag.push(true);
                        } else {
                            flag.push(false);
                        }
                    }
                    if (flag.includes(true)) {
                        return true;
                    }
                }
                return false;
            },
            createOrUpdate() {
                if (!this.validRegistratioNo && !this.validMidasId && !this.validSymbolNo) {
                    this.studentSetting.optional_courses = this.selected_optional_courses;
                    if (this.studentSetting.id) {
                        this.updateSetting();
                    } else {
                        this.saveStudent();
                    }
                }

            },
            saveStudent() {
                this.isBusy = true;
                studentSettingService
                    .store(this.user.id, this.studentSetting)
                    .then((response) => {
                        this.selected_optional_courses = [];
                        this.isBusy = false;
                        // this.getUser(this.id);
                        this.$emit("refresh");
                        this.$snotify.success("Student Record added");
                        // this.$router.push({name: "students"});
                    })
                    .catch((error) => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                    });
            },
            updateUser() {
                this.isBusy = true;
                delete this.user.photo;
                userService.update(this.id, this.user).then(response => {
                    // console.log(response)
                  this.$emit("refresh");
                    this.isBusy = false;

                })
            },
            updateSetting() {
              this.$v.$touch()
              if (this.$v.$error) {
                setTimeout(() => {
                  this.$v.$reset()
                }, 3000);
              } else {
                this.isBusy = true;
                studentSettingService
                    .update(this.user.id, this.studentSetting.id, this.studentSetting)
                    .then((response) => {
                      this.selected_optional_courses = [];
                      this.isBusy = false;
                      this.updateUser();
                      this.$emit("refresh");
                      this.$snotify.success("Student Record updated");
                      // this.$router.push({name: "students"});
                    })
                    .catch((error) => {
                      this.isBusy = false;
                      this.$snotify.error("Something Went Wrong");
                    });
              }
            },
          changeCourseCompleted(){
              if(this.studentSetting.is_course_complete){
                this.studentSetting.completion_date=null;
              }else{
                this.studentSetting.completion_date=null;
              }
          }
        },
    };
</script>
<style scoped>
    .form-control {
        border: none !important;
    }
</style>
