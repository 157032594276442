import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class StudentHealthRecordService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student-health-record`;
    }

    getByStudent(studentId){
        let url = `${this.#api}/${studentId}/get-by-student`
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);
    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    notifyParent(data, id){
        let url = `${this.#api}/${id}/notify-parents`
        return apiService.post(url, data);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
}
