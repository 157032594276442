<template>
  <v-app>
    <v-dialog v-model="dialog"
        max-width="800"
        scrollable>
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            {{`${edit?'Update':"Add"} leave request`}}
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field label="Title" v-model="leave_request.title" outlined dense :error="$v.leave_request.title.$error">

                </v-text-field>
                <span class="text-danger" v-if="$v.leave_request.title.$error">This information is required</span>
              </v-col>
              <v-col cols="6">
                <v-select label="Categories"
                          :items="leave_categories"
                          item-text="title"
                          item-value="id"
                          @change="checkCategoryChange"
                          :loading="categoryLoading" :error="$v.leave_request.leave_category_id.$error"
                          v-model="leave_request.leave_category_id" outlined dense>
                </v-select>
                <span class="text-danger" v-if="$v.leave_request.leave_category_id.$error">This information is required</span>
                <span  v-if="selectedCategory  && selectedCategory[0].description" class="font-weight-medium"></span>
              </v-col>
              <v-col cols="12">
                <label for="description">Description</label>
                <ckeditor id="description" v-model="leave_request.description"
                          :config="editorConfig">
                </ckeditor>
                <span class="text-danger" v-if="$v.leave_request.description.$error">This information is required</span>
              </v-col>
              <v-col cols="5">
                <v-menu
                    v-model="leave_from_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="leave_request.leave_start_date"
                        label="Leave from"
                          :error="$v.leave_request.leave_start_date.$error"
                        readonly dense outlined  prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      :min="today"
                      v-model="leave_request.leave_start_date"
                      @input="leave_from_menu = false"
                  ></v-date-picker>
                </v-menu>
                <span class="text-danger" v-if="$v.leave_request.leave_start_date.$error">This information is required</span>
              </v-col>
              <v-col cols="5">
                <v-menu
                    v-model="leave_to_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="leave_request.leave_end_date"
                        label="Leave to"
                        prepend-inner-icon="mdi-calendar"
                        readonly dense outlined
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      :min="today"
                      v-model="leave_request.leave_end_date"
                      @input="leave_to_menu = false"
                  ></v-date-picker>
                </v-menu>

              </v-col>
              <v-col cols="2">
                <v-checkbox v-model="leave_request.is_half" label="Half day"></v-checkbox>
              </v-col>
              <v-col md="4" cols="12">
                <v-file-input
                    label="Attachment 1"
                    prepend-inner-icon="mdi-file"
                    prepend-icon=""
                    outlined
                    v-model="leave_request.attachment_1"
                    dense
                ></v-file-input>
              </v-col>
              <v-col md="4" cols="12">
                <v-file-input
                    label="Attachment 2"
                    outlined
                    prepend-inner-icon="mdi-file"
                    prepend-icon=""
                    v-model="leave_request.attachment_2"
                    dense
                ></v-file-input>
              </v-col>
              <v-col md="4" cols="12">
                <v-file-input
                    label="Attachment 3"
                    outlined
                    prepend-inner-icon="mdi-file"
                    prepend-icon=""
                    v-model="leave_request.attachment_3"
                    dense
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              class="cancel-btn"
              x-large
              @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn
              color="btn btn-primary"
              dark
              x-large
              :loading="loading"
              @click="createOrUpdate()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import LeaveCategoryService from "@/core/services/leave/leave-category/LeaveCategoryService";
import LeaveRequestService from "@/core/services/leave/leave-request/LeaveRequestService";

const leaveRequestService = new LeaveRequestService();
const leaveCategoryService = new LeaveCategoryService();
export default {
  props:['user_id'],
  validations: {
    leave_request: {
      user_id: {required},
      title: {required},
      leave_category_id: {required},
      description: {required},
      leave_start_date: {required},
    }
  },
  data(){
    return{
      loading:false,
      categoryLoading:false,
      dialog:false,
      edit:false,
      leave_categories: [],
      selectedCategory: null,
      leave_request: {
        user_id: null,
        title: null,
        leave_category_id: null,
        description: null,
        leave_start_date: null,
        leave_end_date: null,
        attachment_1: null,
        attachment_2: null,
        attachment_3: null,
        is_half: false,
        is_active: true,
        requested_by:'admin',
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
      leave_from_menu:false,
      leave_to_menu:false,
      today: new Date().toISOString().substr(0,10),
    }
  },
  methods:{
    openDialog(){
      this.dialog=true;
      this.__getLeaveCategories();
    },
    closeDialog(){
      this.dialog=false;
      this.resetForm();
    },
    resetForm(){
      this.leave_request= {
          user_id: null,
          title: null,
          leave_category_id: null,
          description: null,
          leave_start_date: null,
          leave_end_date: null,
          attachment_1: null,
          attachment_2: null,
          attachment_3: null,
          is_half: false,
          is_active: true,
          requested_by:'admin',
      };
      this.$v.$reset();
    },
    createLeaveRequest(){
      this.edit=false;
      this.openDialog()

    },
    editLeaveRequest(id){
      this.edit=true;
      this.openDialog();
      this.__getSingle(id);
    },
    __getSingle(id) {
      leaveRequestService
          .show(id)
          .then(response => {
            let leave_request=response.data.leave_request;
            this.leave_request.id = leave_request.id;
            this.leave_request.user_id = leave_request.user_id;
            this.leave_request.title = leave_request.title;
            this.leave_request.leave_category_id = leave_request.leave_category_id;
            this.leave_request.description = leave_request.description;
            this.leave_request.leave_start_date = leave_request.leave_start_date;
            this.leave_request.leave_end_date = leave_request.leave_end_date;
            this.leave_request.is_half = leave_request.is_half;
            this.leave_request.is_active = leave_request.is_active;
          })
          .catch(error => {

          })
          .finally(()=>{

          })
    },
    generateFd() {
      this.leave_request.user_id=this.user_id;
      // console.log(this.leave_request)
      let fd = new FormData();
      for (let key in this.leave_request) {
        if (key == "is_half" && this.leave_request["is_half"]) {
          fd.append("is_half", this.leave_request.is_half ? 1 : 0);
        } else {
          if (this.leave_request[key] != null) {
            fd.append(key, this.leave_request[key]);
          }
        }
      }
      return fd;
    },
    createOrUpdate(){
      this.leave_request.user_id=this.user_id;
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        let fd = this.generateFd();
        if (this.edit) {
          this.__update(fd);
        } else {
          this.__create(fd);
        }
      }
    },
    __update(fd){
      this.loading = true;
      leaveRequestService
          .update(this.leave_request.id, fd)
          .then(response => {
            this.closeDialog();
            this.$snotify.success("Information updated");
            this.$emit('refresh');

          })
          .catch(error => {

            this.$snotify.error("Something Went Wrong");
            // console.log(error);
          })
          .finally(()=>{
            this.loading = false;
          })
    },
    __create(fd){
      this.loading = true;
      leaveRequestService
          .store(fd)
          .then((response) => {
            this.closeDialog();
            this.$emit('refresh');
            this.$snotify.success("Leave request added");

          })
          .catch(error => {
            this.$snotify.error("Unable to create leave request. Please contact admin");
          })
          .finally(()=>{
            this.loading = false;
          })

    },
    checkCategoryChange(){
      this.selectedCategory = this.leave_categories.filter(leave => {
        return leave.id === this.leave_request.leave_category_id;
      })
    },
    __getLeaveCategories() {
      this.categoryLoading = true;
      leaveCategoryService
       .getAllActiveLeaveCategory()
          .then(response => {
              this.leave_categories = response.data.leave_categories
          })
          .catch(error=>{

          })
          .finally(()=>{
             this.categoryLoading = false;
          })
    },
  }
}
</script>
