<template>
  <v-app>
    <v-dialog
          v-model="dialog"
          persistent
          max-width="600"
      >
        <v-card>
          <v-toolbar dark>
            <v-card-title class="headline">
              Create sub installment
              <hr>
            </v-card-title>

            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <v-btn icon  @click="closeDialog">
                <i class="fas fa-times"></i>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field type="number" v-model="fee.installment_amount" outlined dense
                              label="Installment Amount"
                              @input="validateSubInstallmentAmount()"></v-text-field>
                <span class="text-danger font-weight-bold" v-if="error">Installment amount cannot be greater than or equals to actual amount</span>
                <!--                          <pre>{{ fee }}</pre>-->
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12 text-right">
                <v-btn
                    class="btn btn-standard cancel-btn"
                    depressed
                    @click="closeDialog"
                >Cancel
                </v-btn>
                <v-btn
                    class="text-white ml-2 btn btn-primary"
                    depressed
                    @click="createInstallment"
                    :loading="isBusy"
                >Save
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-app>
</template>

<script>
import StudentFeeService from "@/core/services/user/student/fee/StudentFeeService";

const feeService = new StudentFeeService();
export default {
  name: "Installment",
  props: ["user_id","dialog", 'fee'],
  data() {
    return {
      error: false,
      isBusy: false
    }
  },
  methods: {
    validateSubInstallmentAmount() {
      let actualAmount = parseFloat(this.fee.amount)
      let installmentAmount = parseFloat(this.fee.installment_amount)
      this.error = actualAmount <= installmentAmount;
    },
    createInstallment() {
      this.isBusy = true
      feeService.createInstallment(this.user_id, this.fee).then(response => {
        this.$snotify.success('Information added')
        this.closeDialog();
      }).catch(() => {
        this.isBusy = false
      });
    },
    closeDialog() {
      this.$emit('refresh')
    },
  }
}
</script>
