<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>

                                  Health record
                                </h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard </router-link>\ Health record
                                </div>
                            </div>

                            <div class="breadcrumb-right mt-4">
                                <v-btn
                                    depressed
                                    :loading="isBusy"
                                    class="btn btn-primary text-white mr-2"
                                    @click="addHealthRecord()"
                                >
                                    <i class="fa fa-plus"></i> Add health record
                                </v-btn>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="">
                            <v-skeleton-loader v-if="loading" type="table-thead">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
            
                            <table class="table" v-if="!loading">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3 wrap-column">Title</th>
                                        <th class="px-3 wrap-column">Type</th>
                                        <th class="px-3 wrap-column">More info</th>
                                        <th class="pr-3 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="healthRecords.length > 0" v-for="(item, index) of healthRecords" :key="item.id">
                                        <td class="px-3 wrap-column">
                                            <div><strong> {{ item.title }} </strong> </div>
                                            <div class="mt-2 mb-3">
                                                <span class="badge" :class="item.is_critical ? 'badge-danger' : 'badge-primary'">
                                                    {{ item.is_critical ? 'Critical' : 'Not critical' }}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="px-3 wrap-column pt-4">
                                            <div class="font-weight-bold text-capitalize">
                                                {{ item.type }}
                                            </div>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            <div class="mt-2" v-if="item.type == 'incident'">
                                                <strong> Date of incident: </strong> {{ item.date_of_incident }}
                                            </div>

                                            <div class="mt-2 mb-3" v-if="item.type == 'incident'">
                                                <span class="badge" :class="item.notified_to_parent ? 'badge-success' : 'badge-danger'">
                                                    {{item.notified_to_parent ? 'Notified parent' : 'Parent not notified'}}
                                                    {{ item.parent_notified_date ? 'on '+ item.parent_notified_date : '' }}
                                                </span>
                                            </div>

                                            <div class="mt-2 mb-3" v-if="item.type == 'record'">
                                                NA
                                            </div>
                                        </td>
                                        
                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">

                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link" @click="editHealthRecord(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text"> Edit </span>
                                                        </a>
                                                    </b-dropdown-text>

                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link" @click="downloadReport(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-download"></i>
                                                            </span>
                                                            <span class="navi-text"> Download report  </span>
                                                        </a>
                                                    </b-dropdown-text>

                                                    <b-dropdown-text tag="div" class="navi-item" v-if="item.type == 'incident'">
                                                        <a href="#" class="navi-link" @click="notifyParent(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-bell"></i>
                                                            </span>
                                                            <span class="navi-text"> Notify parent  </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link" @click="deleteHealthRecord(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-delete"></i>
                                                            </span>
                                                            <span class="navi-text"> Delete </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr v-if="healthRecords.length == 0">
                                        <td class="text-center  px-3" colspan="4">
                                          <strong>Data not available</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
            <create-and-update
                ref="health-record"
                @refresh="getHealthRecords"
            ></create-and-update>

            <notify-parent
                ref="notify-parent"
                @refresh="getHealthRecords"
            ></notify-parent>
        </div>
    </v-app>
</template>

<script>
    import ConfigService from "@/core/services/config/ConfigService";
    import StudentHealthRecordService from "@/core/services/user/student/student-health-record/StudentHealthRecordService";
    import CreateAndUpdate from "./CreateAndUpdate"
    import NotifyParent from "./NotifyParent";
    import {API_URL} from "@/core/config";

    const configService = new ConfigService();
    const studentHealthRecord = new StudentHealthRecordService();

    export default {
        components:{
            CreateAndUpdate, NotifyParent
        },
        props: ["user","userId"],
        data(){
            return {
                isBusy: false,
                loading: false,
                healthRecord: {
                    title: ''
                },
                healthRecords:[],
            }
        },
        methods:{
            isAccessible(roles) {
                let flag = [];
                let type = this.currentUser.type;
                let userRoles = configService.getRoles(type);
                if (roles != null && roles != undefined && userRoles != null && userRoles != undefined) {
                    roles = roles.split(",");
                    for (let t of roles) {
                        t = t.trim();
                        if (userRoles.includes(t)) {
                            flag.push(true);
                        } else {
                            flag.push(false);
                        }
                    }
                    if (flag.includes(true)) {
                        return true;
                    }
                }
                return false;
            },

            addHealthRecord(){
                this.$refs['health-record'].showModal(this.userId, null);
            },

            editHealthRecord(id){
                this.$refs['health-record'].showModal(this.userId, id);
            },

            deleteHealthRecord(id){
                this.$confirm({
                    message: `Are you sure you want to delete?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            studentHealthRecord
                            .delete(id)
                            .then(response => {
                                this.$snotify.success('Successfully deleted')
                                this.getHealthRecords();
                            })
                            .catch(error => {
                            });
                        }
                    }
                });
            },

            getHealthRecords(){
                this.loading = true;

                studentHealthRecord
                .getByStudent(this.id)
                .then((res) => {
                    this.healthRecords = res.data.data;
                })
                .catch(err => {

                })
                .finally(() => {
                    this.loading = false;
                })
            },

            downloadReport(id){
                window.open(
                    API_URL + "user/student-health-record/" + id + '/report/preview',
                    "_blank"
                );
            },

            notifyParent(studentHealthRecordId){
                this.$refs['notify-parent'].showModal(this.userId, studentHealthRecordId);
            }
        },

        mounted(){
            this.getHealthRecords()
        },

        computed: {
            id() {
                return this.$route.params.id ? this.$route.params.id : false;
            },
            currentUser() {
                return this.$store.getters.currentUser;
            },
        }
    }
</script>

<style scoped>
    .wrap-column{
        max-width: 270px !important; 
        white-space: pre-wrap;
    }
</style>