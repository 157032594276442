<template>
  <v-app>
    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header py-5 px-5">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">Academic settings</h3>

        </div>
        <div class="card-toolbar">
          <v-btn
                  depressed
                  :loading="isBusy"
                  class="btn btn-primary text-white mr-2"
                  @click="createOrUpdate()"
          >
            Save changes
          </v-btn>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Form-->
      <form class="form">
        <div class="card-body">

          <div class="form-group row" v-if="isAccessible('admin')">
            <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Academic year</label
            >
            <div class="col-lg-9 col-xl-6">
              <v-select
                      outlined
                      dense
                      class="form-control"
                      v-model="studentSetting.academic_year_id"
                      :items="academicYears"
                      label="Academic year"
                      item-value="id"
                      item-text="year"
                      @change="changeBatch"

              ></v-select>
            </div>
          </div>
          <div class="form-group row" v-if="isAccessible('admin')">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Level</label>
            <div class="col-lg-9 col-xl-6">
              <v-select
                      outlined
                      dense
                      class="form-control"
                      v-model="studentSetting.level_id"
                      :items="levels"
                      label="Levels"
                      item-value="id"
                      item-text="title"
                      @change="getPrograms"
              ></v-select>
            </div>
          </div>
          <div class="form-group row" v-if="isAccessible('admin')">
            <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Program</label
            >
            <div class="col-lg-9 col-xl-6">
              <v-select
                      outlined
                      dense
                      class="form-control"
                      v-model="studentSetting.program_id"
                      :items="programs"
                      label="Programs"
                      item-value="id"
                      item-text="title"
                      @change="getGrades"
              ></v-select>
            </div>
          </div>


          <div class="form-group row" v-if="isAccessible('admin')">
            <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Semester/Year</label
            >
            <div class="col-lg-9 col-xl-6">
              <v-select
                      outlined
                      dense
                      class="form-control"
                      v-model="studentSetting.grade_id"
                      :items="gradesLevels"
                      label="Semester/Year"
                      item-value="id"
                      item-text="title"
                      @change="getAcademicClasses"
              ></v-select>
            </div>
          </div>
        </div>
      </form>
      <!--end::Form-->
    </div>
  </v-app>

</template>

<script>
  import {required} from "vuelidate/lib/validators";
  import UserService from "@/core/services/user/UserService";
  import JobPositionService from "@/core/services/job-position/JobPositionService";
  import ProgramService from "@/core/services/level/program/ProgramService";
  import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
  import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
  import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
  import LevelService from "@/core/services/level/LevelService";
  import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
  import StudentSettingService from "@/core/services/user/student/StudentSettingService";
  import CourseService from "@/core/services/courses/CoursesService";
  import ConfigService from "@/core/services/config/ConfigService";

  const studentSettingService = new StudentSettingService();
  const userService = new UserService();
  const positionService = new JobPositionService();
  const programService = new ProgramService();
  const gradeLevelService = new GradeLevelService();
  const academicClassService = new AcademicClassService();
  const academicYearService = new AcademicYearService();
  const levelService = new LevelService()
  const enrollmentService = new UserEnrollmentService();
  const configService = new ConfigService();
  const courseService = new CourseService();
  export default {
    name: "student-Settings",
    props: ['user','userId', 'studentSetting'],
    validations() {
      return {
        user: this.rules,
      };
    },
    data() {
      return {
        isBusy: false,
        sizeExceed: false,
        job_types: ["admin", "student", "coordinator", "principle"],
        programs: [],
        optional_courses: [],
        selected_optional_courses: [],
        academicYears: [],
        gradesLevels: [],
        academic_classes: [],
        levels: [],
        levelId: null,
        menudob: false,
        menu2: false,
        menu3: false,
        menu: false,
        date: new Date().toISOString().substr(0, 10),
        recent_program_id:null,
      };
    },

    mounted() {
      // this.getUser(this.id)
      // this.userId ? this.getUser(this.userId) : "";
      this.getLevels();
      this.getPrograms();
      this.getGrades();
      this.getAcademicClasses();
      this.getAcademicYears();
      //Assigning Old Program ID to check whether new program or old program
      if(this.studentSetting && this.studentSetting.program_id){
        this.recent_program_id=this.studentSetting.program_id;
      }

    },
    computed: {
      id() {
        return this.$route.params.id ? this.$route.params.id : false;
      },
      currentUser() {
        return this.$store.getters.currentUser;
      },
      rules() {
        let rule = {
          title: {required},
          first_name: {required},
          last_name: {required},
          type: {required}
        };
        if (!this.id) {
          rule.email = {required};
          rule.phone = {required};
        }
        return rule;
      }
    },
    methods: {
      getUser(id) {
        userService.show(id).then(response => {
          this.user = response.data.user;
          this.user.is_active ? (this.user.is_active = true) : false;
          this.user.is_email_verified
                  ? (this.user.is_email_verified = true)
                  : false;
          if (this.user.setting) {
            this.studentSetting = this.user.setting;
            this.edit = true;
          }
          this.getLevels();



        });
      },
      mapOptionalCourse() {
        let ids = this.studentSetting.optional_courses.map(courses => {
          return courses.id;
        });
        this.selected_optional_courses = ids
      },
      getLevels() {
        levelService.all().then(response => {
          this.levels = response.data;
          if (this.studentSetting.id && this.studentSetting.level_id) {

          }
        });
      },
      getPrograms() {
        programService.getAllByLevelId(this.studentSetting.level_id).then(response => {
          this.programs = response.data;

        });
      },
      changeBatch() {
        if (this.studentSetting.level_id)
          this.getPrograms();
        if (this.studentSetting.program_id)
          this.getGrades();
        if (this.studentSetting.grade_id)
          this.getAcademicClasses();

      },
      getAcademicYears() {
        academicYearService.all().then(response => {
          this.academicYears = response.data;


        });
      },
      getGrades() {
        gradeLevelService.getByProgram(this.studentSetting.program_id).then(response => {
          this.gradesLevels = response.data;


        });
      },

      getOptionalCourses() {
        courseService.getOptionalCourseByProgramAndGrade(this.studentSetting.program_id, this.studentSetting.grade_id).then(response => {
          this.optional_courses = response.data.courses;

          if (this.optional_courses.length < 1) {
            this.studentSetting.optional_courses = [];
          }

        });
      },
      getAcademicClasses() {
        academicClassService.getByProgramLevel(this.studentSetting.academic_year_id, this.studentSetting.program_id, this.studentSetting.grade_id).then(response => {
          this.academic_classes = response.data;

        });
      },
      save(date) {
        this.$refs.menu2.save(date);
      },
      convertAdToBs() {
        let data = {
          dob: this.user.dob
        };
        enrollmentService
                .convertAdToBs(data)
                .then(response => {
                  this.user.dob_bs = response.data.dob;
                })
                .catch(error => {
                  //console.log(error);
                });
      },
      redirectTo() {
        this.$router.push({name: "user"});
      },
      isAccessible(roles) {
        let flag = [];
        let type = this.currentUser.type;
        let userRoles = configService.getRoles(type);
        if(roles!=null && roles!=undefined && userRoles!=null && userRoles!=undefined){
          roles = roles.split(",");
          for (let t of roles) {
            t = t.trim();
            if (userRoles.includes(t)) {
              flag.push(true);
            } else {
              flag.push(false);
            }
          }
          if (flag.includes(true)) {
            return true;
          }
        }
        return false;
      },
      createOrUpdate() {
        this.studentSetting.optional_courses = this.selected_optional_courses;
        if (this.studentSetting.id) {
          this.updateSetting();
        } else {
          this.saveStudent();
        }
      },
      saveStudent() {
        this.isBusy = true;

        studentSettingService
                .store(this.user.id, this.studentSetting)
                .then(response => {
                  this.selected_optional_courses = []
                  this.isBusy = false;
                  this.$emit('refresh');
                  this.$snotify.success("Student Record added");
                  // this.$router.push({name: "students"});
                })
                .catch(error => {
                  this.isBusy = false;
                  this.$snotify.error("Something Went Wrong");
                });
      },

      updateSetting() {
        this.isBusy = true;
        if(this.recent_program_id !=this.studentSetting.program_id) {
          this.studentSetting.student_grade_status='leaved';
          this.studentSetting.recent_program_id=this.recent_program_id;
        }
        studentSettingService
                .update(this.user.id, this.studentSetting.id, this.studentSetting)
                .then(response => {
                  this.selected_optional_courses = []
                  this.isBusy = false;
                  this.$emit('refresh');
                  this.$snotify.success("Student Record updated");
                  // this.$router.push({name: "students"});
                })
                .catch(error => {
                  this.isBusy = false;
                  this.$snotify.error("Something Went Wrong");
                });

      },


    }
  };
</script>
<style scoped>
  .form-control {
    border: none !important;
  }
</style>
