import Apiservice from "../../../api.service"
import {API_URL} from "../../../../../common/config";
const apiService = Apiservice;


export default class NotesService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user`;

      }
      paginate(userId) {
            let url = `${this.#api}/${userId}/notes`;
            return apiService.get(url);
      }

      update(userId,noteId, data) {
            let url = `${this.#api}/${userId}/notes/${noteId}`
            return apiService.put(url, data);

      }

      store(userId, data) {
            let url = `${this.#api}/${userId}/notes`
            return apiService.post(url, data);

      }

      show(userId, noteId) {
            let url = `${this.#api}/${userId}/notes/${noteId}`
            return apiService.get(url);
      }

      delete(userId, noteId) {
            let url = `${this.#api}/${userId}/notes/${noteId}`
            return apiService.delete(url);
      }
}
