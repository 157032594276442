<template>
  <v-app>
    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header py-5 px-5">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">Transfer settings</h3>

        </div>
        <div class="card-toolbar">
          <v-btn
            depressed
            :loading="isBusy"
            class="btn btn-primary text-white mr-2"
            @click="createOrUpdate()"
          >
            Save changes
          </v-btn>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Form-->
      <form class="form">
        <div class="card-body">
          <div class="form-group row" v-if="isAccessible('admin')">
            <label class="col-xl-3 col-lg-3 col-form-label">Transfer</label>
            <div class="col-lg-9 col-xl-6">
              <v-select
                v-model="studentSetting.is_transferred_student"
                outlined
                dense
                :items="[
                  { text: 'Yes', val: 1 },
                  { text: 'No', val: 0 },
                ]"
                item-value="val"
                item-text="text"
                label="Is this student transferred from or to college?"
                class="form-control"
              ></v-select>
            </div>
          </div>
          <div
            class="form-group row"
            v-if="isAccessible('admin') && studentSetting.is_transferred_student == 1"
          >
            <label class="col-xl-3 col-lg-3 col-form-label">Transfer Type</label>
            <div class="col-lg-9 col-xl-6">
              <v-select
                v-model="studentSetting.transfer_type"
                outlined
                dense
                :items="[
                  'From this college/institution to another college/institution',
                  'From another college/institution to this college/institution',
                ]"
                label="Select type of transfer"
                class="form-control"
              ></v-select>
            </div>
          </div>
          <div
            class="form-group row"
            v-if="isAccessible('admin') && studentSetting.is_transferred_student == 1"
          >
            <label class="col-xl-3 col-lg-3 col-form-label"
              >Semester/Year when transferred</label
            >
            <div class="col-lg-9 col-xl-6">
              <v-select
                v-model="studentSetting.transferred_grade_id"
                outlined
                dense
                :items="gradesLevels"
                item-value="id"
                item-text="title"
                label="Year/Semester"
                class="form-control"
              ></v-select>
            </div>
          </div>
          <div
            class="form-group row"
            v-if="isAccessible('admin') && studentSetting.is_transferred_student == 1"
          >
            <label class="col-xl-3 col-lg-3 col-form-label"> Transfer Date</label>
            <div class="col-lg-9 col-xl-6">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="studentSetting.transfer_date"
                    class="form-control"
                    label="Date of Transfer"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="studentSetting.transfer_date"
                  no-title
                  scrollable
                  @input="menu2 = false"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false"> Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(date)"> OK </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
          </div>
          <div
            class="form-group row"
            v-if="isAccessible('admin') && studentSetting.is_transferred_student == 1"
          >
            <label class="col-xl-3 col-lg-3 col-form-label"
              >Education Institution to/from Transfer</label
            >
            <div class="col-lg-9 col-xl-6">
              <v-text-field
                v-model="studentSetting.transfer_educational_institute"
                dense
                outlined
                label="Educational Institute Name"
              >
              </v-text-field>
            </div>
          </div>
          <div
            class="form-group row"
            v-if="isAccessible('admin') && studentSetting.is_transferred_student == 1"
          >
            <label class="col-xl-3 col-lg-3 col-form-label">Transfer Notes</label>
            <div class="col-lg-9 col-xl-6">
              <v-textarea
                v-model="studentSetting.transfer_note"
                label="Notes on Transfer"
                outlined
                dense
              >
              </v-textarea>
            </div>
          </div>

        </div>
      </form>
      <!--end::Form-->
    </div>
  </v-app>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import UserService from "@/core/services/user/UserService";
import JobPositionService from "@/core/services/job-position/JobPositionService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import StudentSettingService from "@/core/services/user/student/StudentSettingService";
import CourseService from "@/core/services/courses/CoursesService";
import ConfigService from "@/core/services/config/ConfigService";

const studentSettingService = new StudentSettingService();
const userService = new UserService();
const positionService = new JobPositionService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService();
const enrollmentService = new UserEnrollmentService();
const configService = new ConfigService();
const courseService = new CourseService();
export default {
  name: "student-Settings",
  props: ["user","userId", "studentSetting"],
  validations() {
    return {
      user: this.rules,
    };
  },
  data() {
    return {
      isBusy: false,
      sizeExceed: false,
      job_types: ["admin", "student", "coordinator", "principle"],
      programs: [],
      optional_courses: [],
      selected_optional_courses: [],
      academicYears: [],
      gradesLevels: [],
      academic_classes: [],
      levels: [],
      levelId: null,
      menudob: false,
      menu2: false,
      menu3: false,
      menu: false,
      validMidasId: false,
      validRegistratioNo: false,
      validSymbolNo: false,
      date: new Date().toISOString().substr(0, 10),
    };
  },

  mounted() {
    // this.getUser(this.id);
    // this.userId ? this.getUser(this.userId) : "";
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : false;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    rules() {
      let rule = {
        title: { required },
        first_name: { required },
        last_name: { required },
        type: { required },
      };
      if (!this.id) {
        rule.email = { required };
        rule.phone = { required };
      }
      return rule;
    },
  },
  methods: {
    getUser(id) {
      userService.show(id).then((response) => {
        this.user = response.data.user;
        this.user.is_active ? (this.user.is_active = true) : false;
        this.user.is_email_verified ? (this.user.is_email_verified = true) : false;
        if (this.user.setting) {
          this.studentSetting = this.user.setting;
          this.edit = true;
          this.getLevels();
          this.getPrograms();
          this.getGrades();
          this.getAcademicClasses();
          this.getOptionalCourses();
          this.mapOptionalCourse();
        }
        this.getLevels();
        this.getAcademicYears();
      });
    },
    mapOptionalCourse() {
      let ids = this.studentSetting.optional_courses.map((courses) => {
        return courses.id;
      });
      this.selected_optional_courses = ids;
    },
    getLevels() {
      levelService.all().then((response) => {
        this.levels = response.data;
        if (this.studentSetting.id && this.studentSetting.level_id) {
        }
      });
    },
    validateMidas(midas_id){
      userService.validateMidasId(midas_id).then(response=>{
        this.validMidasId = response.data.valid;
      }).catch(err=>{
        console.log(err)
      }).finally(()=>{

      })
    },
    validateRegistrationNo(registration_no){
      userService.validateRegistratioNo(registration_no).then(response=>{
        this.validRegistratioNo = response.data.valid;
      }).catch(err=>{
        console.log(err)
      }).finally(()=>{

      })
    },
    validateSymbolNumber(symbol_no){
      userService.validateSymbolNo(symbol_no).then(response=>{
        this.validSymbolNo = response.data.valid;
      }).catch(err=>{
        console.log(err)
      }).finally(()=>{

      })
    },
    getPrograms() {
      programService.getAllByLevelId(this.studentSetting.level_id).then((response) => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.all().then((response) => {
        this.academicYears = response.data;
      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.studentSetting.program_id).then((response) => {
        this.gradesLevels = response.data;
      });
    },

    getOptionalCourses() {
      courseService
        .getOptionalCourseByProgramAndGrade(
          this.studentSetting.program_id,
          this.studentSetting.grade_id
        )
        .then((response) => {
          this.optional_courses = response.data.courses;

          if (this.optional_courses.length < 1) {
            this.studentSetting.optional_courses = [];
          }
        });
    },
    getAcademicClasses() {
      academicClassService
        .getByProgramLevel(
          this.studentSetting.academic_year_id,
          this.studentSetting.program_id,
          this.studentSetting.grade_id
        )
        .then((response) => {
          this.academic_classes = response.data;
        });
    },
    save(date) {
      this.$refs.menu2.save(date);
    },
    convertAdToBs() {
      let data = {
        dob: this.user.dob,
      };
      enrollmentService
        .convertAdToBs(data)
        .then((response) => {
          this.user.dob_bs = response.data.dob;
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    redirectTo() {
      this.$router.push({ name: "user" });
    },
    isAccessible(roles) {
      let flag = [];
      let type = this.currentUser.type;
      let userRoles = configService.getRoles(type);
      if(roles!=null && roles!=undefined && userRoles!=null && userRoles!=undefined){
        roles = roles.split(",");
        for (let t of roles) {
          t = t.trim();
          if (userRoles.includes(t)) {
            flag.push(true);
          } else {
            flag.push(false);
          }
        }
        if (flag.includes(true)) {
          return true;
        }
      }
      return false;
    },
    createOrUpdate() {
      this.studentSetting.optional_courses = this.selected_optional_courses;
      if (this.studentSetting.id) {
        this.updateSetting();
      } else {
        this.saveStudent();
      }
    },
    saveStudent() {
      this.isBusy = true;

      studentSettingService
        .store(this.user.id, this.studentSetting)
        .then((response) => {
          this.selected_optional_courses = [];
          this.isBusy = false;
          this.$emit("refresh");
          this.$snotify.success("Student Record added");
          // this.$router.push({name: "students"});
        })
        .catch((error) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },

    updateSetting() {
      this.isBusy = true;
      studentSettingService
        .update(this.user.id, this.studentSetting.id, this.studentSetting)
        .then((response) => {
          this.selected_optional_courses = [];
          this.isBusy = false;
          this.$emit("refresh");
          this.$snotify.success("Student Record updated");
          // this.$router.push({name: "students"});
        })
        .catch((error) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },
  },
};
</script>
<style scoped>
.form-control {
  border: none !important;
}
</style>
