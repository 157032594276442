import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class HostelResidentService {
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/hostel-resident`;
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
                url = `${url}?page=${index}`;
        let param ={
                params: data
        }
        return apiService.query(url,param);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);
    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    getByStudent(studentId){
        let url = `${this.#api}/get/by-student/${studentId}`
        return apiService.get(url);
    }

    findByStudent(studentId){
        let url = `${this.#api}/find/by-student/${studentId}`
        return apiService.get(url);
    }

    renewStudent(hostelResidentId){
        let url = `${this.#api}/${hostelResidentId}/renew-student`
        return apiService.post(url);
    }

    checkForHostelRenew(userId){
        let url = `${this.#api}/user/${userId}/check/hostel-renew`
        return apiService.get(url);
    }
}
