<template>
    <!--begin::Card-->
    <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-5 px-5">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">Change password</h3>
<!--                <span class="text-muted font-weight-bold font-size-sm mt-1"-->
<!--                >Change student password</span-->
<!--                >-->
            </div>
            <div class="card-toolbar">
                <v-btn
                        depressed
                        :loading="isBusy"
                        class="btn btn-primary text-white mr-2"
                        @click="updateStudent()"
                >
                    Save changes
                </v-btn>

            </div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form" id="kt_password_change_form">
            <div class="card-body">
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-alert"
                    >New password</label
                    >
                    <div class="col-lg-9 col-xl-6">
                        <v-text-field
                                v-model="pass.password"
                                type="password"
                                outlined
                                dense
                                value=""
                                placeholder="New password"
                                label="New password"
                                name="new_password"
                                ref="new_password"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-alert"
                    >Confirm password</label
                    >
                    <div class="col-lg-9 col-xl-6">
                        <v-text-field
                                v-model="pass.confirmPassword"
                                type="password"
                                outlined
                                dense
                                placeholder="Confirm password"
                                label="Confirm password"
                                name="verify_password"
                                ref="verify_password"
                        />
                    </div>
                    <span class="text-danger" v-if="$v.pass.password.$error">Password is required and  must have at least 6 letters.</span>
                    <br>
                    <span class="text-danger" v-if="$v.pass.confirmPassword.$error">Confirm Password should be same as Password</span>
                </div>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>

<script>
    import {minLength, required, sameAs} from "vuelidate/lib/validators";
    import {mapGetters} from "vuex";
    // FormValidation plugins
    import UserService from "@/core/services/user/UserService";

    const userService = new UserService();

    export default {
        name: "ChangePassword",
        props: ['user'],
        validations: {
            pass: {
                password: {required, minLength: minLength(6)},
                confirmPassword: {
                    sameAsPassword: sameAs('password')
                },
            }
        },
        data() {
            return {
                error: false,
                isBusy: false,

                pass: {
                    password: "",
                    confirmPassword: "",
                },
                status: "",
                valid: true
            };
        },
        mounted() {

        },
        methods: {
            updateStudent() {
                this.$v.pass.$touch();
                if (this.$v.pass.$error) {
                    setTimeout(() => {
                        this.$v.pass.$reset();
                    }, 3000);
                } else {
                    this.isBusy = true;
                    const {id, is_active, is_login_verified} = this.user;
                    const data = {
                        id: id,
                        password: this.pass.password,
                        is_active: is_active,
                        is_login_verified: is_login_verified
                    }
                    userService
                        .updateUser(this.user.id, data)
                        .then(response => {
                            this.isBusy = false;
                            this.$snotify.success("Information updated");
                        })
                        .catch(error => {
                            this.isBusy = false;
                            this.$snotify.error("Error updating record. Please try again later.");
                        });
                }

            },
            cancel() {
            }
        },
        computed: {
            ...mapGetters(["currentUser"])
        }
    };
</script>
