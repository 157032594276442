<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-5 px-5">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Library settings</h3>

      </div>
      <div class="card-toolbar">
        <v-btn
            depressed
            :loading="isBusy"
            class="btn btn-primary text-white mr-2"
            @click="updateStudent()"
        >
          Save
        </v-btn>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <div v-if="isBusy">
      <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
      ></v-skeleton-loader>
    </div>
    <div v-else>
      <form class="form">
        <div class="card-body">
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Expiry date</label>
            <div class="col-lg-9 col-xl-6">
              <v-menu
                  ref="menu1"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="user.library_membership_expiry"
                      label="Membership expiry date"
                      hint="MM/DD/YYYY format"
                      persistent-hint
                      prepend--inner-icon="mdi-calendar"
                      prepend-icon=""
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      autocomplete="off"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="user.library_membership_expiry"
                    no-title
                    @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>


          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Remarks</label>
            <div class="col-lg-9 col-xl-6">
              <v-textarea
                  v-model="user.library_notes"
                  label="Library note"
                  outlined
                  dense
              >
              </v-textarea>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Library access</label>
            <div class="col-lg-9 col-xl-6">
              <v-switch
                  v-model="user.enable_library"
                  :label="user.enable_library?' Enabled ':'Disabled'"
                  outlined
                  dense
              >
              </v-switch>
            </div>
          </div>

        </div>
      </form>
    </div>
    <!--end::Form-->
  </div>
</template>

<script>
import userService from "@/core/services/user/UserService";

const serviceUser = new userService();
export default {
  name: "LibrarySetting",
  props: ['user'],
  data() {
    return {
      isBusy: false,
      menu: false
    }
  },
  methods: {
    // createOrUpdate(){
    //   this.$emit('')
    // },
    updateStudent(fd) {
      this.isBusy = true;
      // delete this.user.email;
      // delete this.user.phone;
      serviceUser
          .update(this.user.id, this.user)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Information updated");
            this.$emit('refresh')
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
          });
    },
  }
}
</script>

<style scoped>

</style>