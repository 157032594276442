import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class UserSettingService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user`;
    }

    paginate(userId) {
        let url = `${this.#api}/${userId}/user-setting`;
        return apiService.get(url);
    }

    update(userId,userSettingId, data) {
        let url = `${this.#api}/${userId}/user-setting/${userSettingId}`
        return apiService.put(url, data);

    }

    store(userId, data) {
        let url = `${this.#api}/${userId}/user-setting`
        return apiService.post(url, data);

    }

    show(userId, userSettingId) {
        let url = `${this.#api}/${userId}/user-setting/${userSettingId}`
        return apiService.get(url);
    }

    delete(userId, userSettingId) {
        let url = `${this.#api}/${userId}/user-setting/${userSettingId}`
        return apiService.delete(url);
    }
}
