<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card ">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Activity log</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Activity log
                </div>
              </div>

            </div>
          </div>
          <div class="card-body">
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr>
                    <th class="px-3 wrap-column">Type</th>
                    <th class="px-3 wrap-column">Date</th>
                    <th class="px-3 wrap-column">Triggered by</th>
                  </tr>
                </thead>
                <template>
                  <tr v-for="(activity, index) in activities" :key="index">
                    <td class="font-weight-medium px-3 wrap-column">
                      {{ activity.type_text }}
                    </td>
                    <td class="font-weight-medium px-3 wrap-column">
                      {{ activity.issue_date }}
                    </td>
                    <td class="font-weight-medium px-3 wrap-column">
                      {{ activity.issued_by_name }}
                    </td>
                  </tr>
                  <tr v-show="activities.length == 0">
                    <td colspan="3" class="text-center">
                      <strong>Data not available</strong>
                    </td>
                  </tr>
                </template>
              </table>
              <b-pagination @input="getAllActivities" v-model="page" v-if="activities.length > 0" :total-rows="total"
                :per-page="perPage" first-number last-number class="pull-right mt-7" :disabled="loading"></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import StudentPaperIssueActivity from "@/core/services/activity/StudentPaperIssueActivity";
const activity = new StudentPaperIssueActivity();
export default {
  props: ['user'],
  data() {
    return {
      page: null,
      total: null,
      perPage: null,
      loading: false,
      activities: [],
      search: {
        user_id: '',
      }
    }
  },
  methods: {
    getAllActivities() {
      this.loading = true;
      this.search.user_id = this.user.id;
      activity
        .paginate(this.search, this.page)
        .then(response => {
          this.activities = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {

        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.getAllActivities();
  }
}
</script>