// $router->get('provinces', 'Address\Nepal\NepalAddressController@provinces');
//     $router->get('province/{provinceId}/district', 'Address\Nepal\NepalAddressController@districtsByProvince');
//     $router->get('district/{districtId}/municipalities', 'Address\Nepal\NepalAddressController@municipalitiesByDistrict');

import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class StateService {

      #api;
      #cityApi;
      constructor() {
            this.#api = `${API_URL}user`
      }

      provinces() {
            let url = `${API_URL}user/provinces`
            return apiService.get(url);
      }
      districtsByProvince(provinceId) {
            let url = `${API_URL}user/province/${provinceId}/districts`
            return apiService.get(url);
      }
      municipalitiesByDistrict(districtId) {
            let url = `${API_URL}user/district/${districtId}/municipalities`
            return apiService.get(url);
      }
}
