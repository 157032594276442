import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;

export default class CharacterCertificateService
{
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/character-certificate`;

    }
    issueCharacterCertificate(data=null){
        let url = `${this.#api}/issued`
        return apiService.post(url, data);
    }
    previewCharacterCertificate(data=null)
    {
        let url = `${this.#api}/preview`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }
}
