<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Student Statement
                </h4>
              </div>

            </div>
          </div>
          <div class="card-body">

            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="4">
                  <v-select
                      :items="academicYears"
                      item-text="title"
                      item-value="id"
                      label="Academic year"
                      v-model="search.academic_year_id"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                      :items="gradesLevels"
                      item-text="title"
                      item-value="id"
                      label="Semester/Level"
                      v-model="search.grade_id"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-btn :loading="loading" @click.prevent="searchStudentStatement()"
                         class="btn btn-primary w-35 float-right" dark>
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader type="table-thead" v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="px-3">
                    <th class="wrap-column">S.N.</th>
                    <th class="wrap-column">Particular</th>
                    <th class="wrap-column">Date</th>
                    <th class="wrap-column">Bill/Receipt No.</th>
                    <th class="wrap-column">Debit amount (Rs.)</th>
                    <th class="wrap-column">Credit amount (Rs.)</th>
                    <th class="wrap-column">Fine (Rs.)</th>
                    <th class="wrap-column">Scholarship (Rs.)</th>
                    <th class="wrap-column">Balance amount (Rs.)</th>
                    <th class="pr-3 text-center">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="studentStatements.length > 0" v-for="(item, index) of studentStatements" :key="index">

                    <td class="text-center">
                      {{ index + 1 }}.
                    </td>

                    <td class="text-center">
                     {{ item.particular }}
                    </td>

                    <td class="text-center">
                      {{ item.formatted_date }}
                    </td>

                    <td class="text-center">
                      {{ item.receipt_no || item.invoice_no }}
                    </td>

                    <td class="text-center">
                      {{ item.debit_amount }}
                    </td>

                    <td class="text-center">
                      {{ item.credit_amount }}
                    </td>

                    <td class="text-center">
                      {{ item.fine_amount }}
                    </td>

                    <td class="text-center">
                      {{ item.scholarship_amount }}
                    </td>

                    <td class="text-center">
                      {{ item.balance_amount }}
                    </td>

                    <td class="text-center">
                      {{ item.remarks }}
                    </td>
                  </tr>
                  <tr v-if="studentStatements.length == 0">
                    <td colspan="15" class="text-center">
                      <strong>Data not available</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import StudentSettingService from "@/core/services/user/student/StudentSettingService";

const studentSetting = new StudentSettingService();
const gradeLevel = new GradeLevelService();
const academicYear = new AcademicYearService();

export default {
  name: "StudentStatement",
  props: ['user'],
  data() {
    return {
      academicYears: [],
      studentStatements: [],
      gradesLevels: [],
      loading: false,
      search:{
        academic_year_id: '',
        grade_id: ''
      },
    }
  },

  mounted() {
    this.search.grade_id = this.user.setting.grade_id;
    this.search.academic_year_id = this.user.setting.academic_year_id;
    this.getGrades();
    this.getAllAcademicYears();
    this.getAllStudentStatements();
  },

  methods: {
    searchStudentStatement() {
      this.getAllStudentStatements();
    },

    getAllStudentStatements() {
      this.loading = true;
      studentSetting.getStatementStudent(this.user.id, this.search).then(response => {
        this.loading = false;
        this.studentStatements = response.data.statement;
      }).catch(() => {
        this.loading = false;
      });
    },
    getAllAcademicYears() {
      academicYear.all().then(response => {
        this.academicYears = response.data
      }).catch(() => {});
    },

    getGrades() {
      gradeLevel.getByProgram(this.user.setting.program_id).then(response => {
        this.gradesLevels = response.data;
      }).catch(() => {});
    },
  }
}
</script>
