<template>
  <v-app>
    <v-row v-if="!user.id">
      <v-col cols="12">
        <v-skeleton-loader
            v-bind="attrs"
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" md="2">
        <div class="card">
          <!--begin::User-->
          <div class="d-flex align-items-center px-4 py-4" style="background: #f6fffe; border-right: 1px solid #f5f5f5">
            <div >
              <div class="image-input image-input-outline" id="kt_profile_avatar">
                <div
                    class="text-center"
                >
                  <img class="image-input-wrapper" v-if="getUserImagePath(user)" :src="getUserImagePath(user)" alt="">
                  <img class="image-input-wrapper" v-else
                       src="https://images.unsplash.com/photo-1509114397022-ed747cca3f65?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80"
                       alt="">
                </div>
              </div><br/>
              <span class="font-weight-bolder font-size-h6 text-dark-75">
                   {{ user.full_name ? user.full_name : 'NA' }}
                 </span>

              <p class="mb-2 student_summary_program_name" v-if="checkUserIncludes(user.type,'student')">
                {{ studentSetting.program_title ? studentSetting.program_title : 'N/A' }}
              </p>
              <span class="mb-2 student_summary_program_name" v-if="checkUserIncludes(user.type,'student')">
                    {{ studentSetting && studentSetting.current_semester_title ? studentSetting.current_semester_title : 'N/A' }} |
                </span>
              <!--                <span class="badge badge-primary text-uppercase">{{ user.account_type  }}</span>-->
              <span class="mb-2 student_summary_program_name">{{ studentSetting.academic_year ? studentSetting.academic_year : 'N/A' }}</span>
            </div>
          </div>
          <!--end::User-->
          <!--begin::Nav-->
          <v-tabs
              v-model="tab"
              vertical
          >
            <v-tab :tab-value="0">
                <span class="navi-icon mr-1">
                  <i class="fa fa-gauge"></i>
                </span>
              <span class="navi-text">Dashboard</span>
            </v-tab>

            <v-tab :tab-value="1">
                <span class="navi-icon mr-1">
                  <i class="far fa-id-card"></i>
                </span>
              <span class="navi-text">Profile edit</span>
            </v-tab>

            <v-tab :tab-value="2" v-if="checkUserIncludes(user.type,'student')">
             <span class="navi-icon mr-1">
                <i class="fas fa-info-circle"></i>
              </span>
              <span class="navi-text">Other info</span>
            </v-tab>

            <v-tab :tab-value="3" v-if="isAccessible('admin,staff,coordinator') && checkUserIncludes(user.type,'student') || checkUserIncludes(user.type,'staff')">
                <span class="navi-icon mr-1">
                  <i class="fa fa-chart-bar"></i>
                </span>
              <span class="navi-text">Academic progress</span>
            </v-tab>

            <v-tab :tab-value="4"
                v-if="isAccessible('admin,staff,finance,coordinator') && checkUserIncludes(user.type,'student') || checkUserIncludes(user.type,'teacher') || checkUserIncludes(user.type,'staff')">
                <span class="navi-icon mr-1">
                  <i class="fas fas fa-umbrella-beach"></i>
                </span>
              <div class="d-flex justify-space-between">
                <span class="navi-text">Leave requests</span>
                <span v-if="leaveRequestCount" class="count_number_badge ml-1">{{ leaveRequestCount }}</span>
              </div>
            </v-tab>

            <v-tab :tab-value="5" v-if="isAccessible('admin,exam,coordinator') && checkUserIncludes(user.type,'student')">
                <span class="navi-icon mr-1">
                <i class="fa fa-file-alt"></i>
              </span>
              <span class="navi-text">Exams</span>
            </v-tab>

            <v-tab :tab-value="6"
                v-if="checkUserIncludes(user.type,'student')"
            >
              <span class="navi-icon mr-1">
                <i class="fas fa-notes-medical"></i>
              </span>
              <div class="d-flex justify-space-between">
                <span class="navi-text">Health record</span>
                <span v-if="studentHealthRecordCount" class="count_number_badge ml-1">{{ studentHealthRecordCount }}</span>
              </div>

            </v-tab>

            <v-tab :tab-value="7">
              <span class="navi-icon mr-1">
                <i class="fas fa-paperclip"></i>
              </span>
              <div class="d-flex justify-space-between">
                <span class="navi-text">Documents</span>
                <span v-if="userDocumentCount" class="count_number_badge ml-1">{{ userDocumentCount }}</span>
              </div>
            </v-tab>

            <v-tab :tab-value="8" v-if="isAccessible('admin,coordinator')">
                <span class="navi-icon mr-1">
                  <i class="fa fa-map-marker-alt"></i>
                </span>
              <div class="d-flex justify-space-between">
                <span class="navi-text">Address</span>
                <span v-if="userAddressCount" class="count_number_badge ml-1">{{ userAddressCount }}</span>
              </div>
            </v-tab>

            <v-tab :tab-value="9" v-if="checkUserIncludes(user.type,'student')">
               <span class="navi-icon mr-1">
                   <i class="fa flaticon-medal"></i>
               </span>
              <div class="d-flex justify-space-between">
                <span class="navi-text">Grades</span>
                <span v-if="studentGradesCount" class="count_number_badge ml-1">{{ studentGradesCount }}</span>
              </div>
            </v-tab>

            <v-tab :tab-value="10" v-if="isAccessible('admin,coordinator')   && checkUserIncludes(user.type,'student')">
                <span class="navi-icon mr-1">
                <i class="fas fa-child"></i>
              </span>
              <div class="d-flex justify-space-between">
                <span class="navi-text">Parents</span>
                <span v-if="userParentsCount" class="count_number_badge ml-1">{{ userParentsCount }}</span>
              </div>
            </v-tab>

            <v-tab :tab-value="11"
                v-if="isAccessible('admin,coordinator') && (checkUserIncludes(user.type,'student') || checkUserIncludes(user.type,'teacher'))">
               <span class="navi-icon mr-1">
                  <i class="fa fa-sticky-note"></i>
                </span>
              <div class="d-flex justify-space-between">
                <span class="navi-text">Notes</span>
                <span v-if="notesCount" class="count_number_badge ml-1">{{ notesCount }}</span>
              </div>
            </v-tab>

            <v-tab :tab-value="12" v-if="isAccessible('admin,coordinator') && checkUserIncludes(user.type,'student')">
              <span class="navi-icon mr-1">
                <i class="fas fa-calendar"></i>
              </span>
              <div class="d-flex justify-space-between">
                <span class="navi-text">Parents meet</span>
                <span v-if="parentMeetsCount" class="count_number_badge ml-1">{{ parentMeetsCount }}</span>
              </div>
            </v-tab>

            <v-tab :tab-value="13" v-if="isAccessible('admin,coordinator') && checkIsAccessible('attendance', 'list')">
                <span class="navi-icon mr-1">
                   <i class="fas fa-clipboard"></i>
                </span>
              <span class="navi-text">Attendance</span>
            </v-tab>

            <v-tab :tab-value="14" v-if="isAccessible('admin,coordinator') ">
                <span class="navi-icon mr-1">
                  <i class="fa fa-globe"></i>
                </span>
              <span class="navi-text">Online portal</span>
            </v-tab>

            <v-tab :tab-value="15" v-if="!checkUserIncludes(user.type,'student')">
                <span class="navi-icon mr-1">
                                  <i class="fa fa-cog"></i>
                                </span>
              <span class="navi-text">Settings</span>
            </v-tab>

            <v-tab :tab-value="16" v-if="(isAccessible('admin,coordinator') || checkUserIncludes(user.type,'teacher')
                || checkUserIncludes(user.type,'coordinator') || checkUserIncludes(user.type,'department-manager')) && !checkUserIncludes(user.type,'student') ">
                               <span class="navi-icon mr-1">
                                  <i class="fas fa-hotel"></i>
                                </span>
              <span class="navi-text">Faculty</span>
            </v-tab>

            <v-tab :tab-value="17" v-if="isAccessible('admin,coordinator') && checkUserIncludes(user.type,'teacher')">
                               <span class="navi-icon mr-1">
                                  <i class="fas fa-video"></i>
                                </span>
              <span class="navi-text">Zoom account</span>
            </v-tab>

            <v-tab :tab-value="18" v-if="isAccessible('admin,finance') && checkUserIncludes(user.type,'student')">
                               <span class="navi-icon mr-1">
                                  <i class="fas fa-money-check-alt"></i>
                                </span>
              <span class="navi-text">Fees</span>
            </v-tab>

            <v-tab :tab-value="19" v-if="isAccessible('admin,finance') && checkUserIncludes(user.type,'student')">
                 <span class="navi-icon mr-1">
                    <i class="fa fa-graduation-cap"></i>
                  </span>
              <div class="d-flex justify-space-between">
                <span class="navi-text">Scholarship</span>
                <span v-if="scholarshipCount" class="badge badge-success">{{ scholarshipCount }}</span>
              </div>
            </v-tab>

            <v-tab :tab-value="20" v-if="isAccessible('admin') && checkUserIncludes(user.type,'student')">
                 <span class="navi-icon mr-1">
                    <i class="fas fa-file-invoice"></i>
                  </span>
              <div class="d-flex justify-space-between">
                <span class="navi-text">Statement</span>
              </div>
            </v-tab>

            <v-tab :tab-value="21"
                v-if="isAccessible('admin, finance,coordinator') && checkUserIncludes(user.type,'student') || checkUserIncludes(user.type,'teacher')">
                                  <span class="navi-icon mr-1">
                                  <i class="fas fa-book"></i>
                                </span>
              <span class="navi-text">Library</span>
            </v-tab>

            <v-tab :tab-value="22"
                v-if="isAccessible('admin') && !checkUserIncludes(user.type,'student')">
                  <span class="navi-icon mr-1">
                  <i class="fas fas fa-cogs"></i>
                </span>
              <span class="navi-text">Role setting</span>
            </v-tab>

            <v-tab :tab-value="23" v-if="isAccessible('admin,coordinator') && checkUserIncludes(user.type,'student')">
                  <span class="navi-icon mr-1">
                  <i class="fas fa-building"></i>
                </span>
              <span class="navi-text">Hostel</span>
            </v-tab>

            <v-tab :tab-value="24" v-if="isAccessible('admin,coordinator') && checkUserIncludes(user.type,'student') && user.id && user.setting && user.setting.is_course_complete">
                <span class="navi-icon mr-1">
                  <i class="fas fa-certificate"></i>
                </span>
              <span class="navi-text">Character certificate</span>
            </v-tab>

            <v-tab :tab-value="25" v-if="isAccessible('admin') && checkUserIncludes(user.type,'student') && user.id && user.transport_allocation_count > 0">
                <span class="navi-icon mr-1">
                  <i class="fas fa-bus"></i>
                </span>
              <span class="navi-text">Bus service</span>
            </v-tab>

            <v-tab :tab-value="26"
                v-if="isAccessible('admin,coordinator')">
                                  <span class="navi-icon mr-1">
                                  <i class="fas fas fa-info-circle"></i>
                                </span>
              <span class="navi-text">Information log</span>
            </v-tab>

            <v-tab :tab-value="27" v-if="isAccessible('admin,coordinator') && checkUserIncludes(user.type,'student')">
                <span class="navi-icon mr-1">
                  <i class="fa fa-history"></i>
                </span>
              <span class="navi-text">Activity</span>
            </v-tab>
          </v-tabs>
          <!--end::Nav-->
        </div>
      </v-col>
      <v-col cols="12" md="10">
          <v-tabs-items v-model="tab">
            <v-tab-item title="Dashboard" :value="0">
              <Dashboard
                  v-if="user.id"
                  :user="user"
                  @refresh="getUser"
                  @goto-notes="gotoNotes"
                  @goto-health-record="gotoHealthRecord"
                  @goto-parents-meet="gotoParentsMeet"
                  @goto-leave-request="gotoLeaveRequest"
              ></Dashboard>
            </v-tab-item>

            <v-tab-item title="General" :value="1">
              <StudentGeneralInformation v-if="user.id" :user="user" @refresh="getUser"></StudentGeneralInformation>
            </v-tab-item>

            <v-tab-item title="Settings" :value="2" v-if="checkUserIncludes(user.type,'student')">
              <div style="margin-top: 18px;">
                <v-tabs class="justify-start"  v-model="setting_tab">

                  <v-tab class="justify-start" v-if="checkUserIncludes(user.type,'student')">
                              <span class="navi-icon mr-1">
                                  <i class="fas fa-cogs"></i>
                                </span>
                    <span class="navi-text">General</span>
                  </v-tab>
                  <v-tab class="justify-start" v-if="isAccessible('admin') && checkUserIncludes(user.type,'student')">
                     <span class="navi-icon mr-1">
                                  <i class="fa fa-graduation-cap"></i>
                                </span>
                    <span class="navi-text">Academic</span>
                  </v-tab>
                  <v-tab class="justify-start" v-if="isAccessible('admin') && checkUserIncludes(user.type,'student')">
                               <span class="navi-icon mr-1">
                                  <i class="fa fa-people-arrows"></i>
                                </span>
                    <span class="navi-text">Transfer</span>
                  </v-tab>

                </v-tabs>
                <v-tabs-items v-model="setting_tab">
                  <v-tab-item v-if="checkUserIncludes(user.type,'student')">
                    <Settings :user="user" :userId="user.id" :studentSetting="user.setting" @refresh="getUser"></Settings>
                  </v-tab-item>

                  <v-tab-item  v-if="isAccessible('admin') && checkUserIncludes(user.type,'student')">
                    <academic-settings :user="user" :userId="user.id" :studentSetting="user.setting"
                                       @refresh="getUser"></academic-settings>
                  </v-tab-item>
                  <v-tab-item v-if="isAccessible('admin') && checkUserIncludes(user.type,'student')">
                    <transfer-settings :user="user" :userId="user.id"
                                       :studentSetting="user.setting" @refresh="getUser"></transfer-settings>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-tab-item>

            <v-tab-item tile="Activity" :value="3" v-if="isAccessible('admin,staff,coordinator') && checkUserIncludes(user.type,'student') || checkUserIncludes(user.type,'staff')">
              <academic-progress :user="user">></academic-progress>
            </v-tab-item>

            <v-tab-item title="Leave request" :value="4" v-if="isAccessible('admin,finance,coordinator') && checkUserIncludes(user.type,'student') || checkUserIncludes(user.type,'teacher') || checkUserIncludes(user.type,'staff')">
              <leave :user="user" v-if="user"></leave>
            </v-tab-item>

            <v-tab-item title="Exam" :value="5" v-if="isAccessible('admin,exam,coordinator') && checkUserIncludes(user.type,'student')">
              <exam v-if="user.id" :user="user"></exam>
            </v-tab-item>

            <v-tab-item title="Grade/semester" :value="6" v-if="checkUserIncludes(user.type,'student')">
              <HealthRecords
                  :user="user"
                  :userId="user.id"
                  ref="health-records"
                  @refresh="getUser"
              ></HealthRecords>
            </v-tab-item>

            <v-tab-item title="Documents" :value="7">
              <StudentDocuments :user="user"></StudentDocuments>
            </v-tab-item>

            <v-tab-item title="Address" :value="8" v-if="isAccessible('admin,coordinator')">
              <AddressInformation :user="user" @refresh="getUser"></AddressInformation>
            </v-tab-item>

            <v-tab-item title="Grade/semester" :value="9" v-if="checkUserIncludes(user.type,'student')">
              <Grades :user="user" :userId="user.id" ref="grades" @refresh="getUser"></Grades>
            </v-tab-item>

            <v-tab-item title="Parents" :value="10" v-if="isAccessible('admin,coordinator')   && checkUserIncludes(user.type,'student')">
              <ParentalInformation :user="user" @refresh="getUser"></ParentalInformation>
            </v-tab-item>

            <v-tab-item title="Notes" :value="11" v-if="isAccessible('admin,coordinator')  && checkUserIncludes(user.type,'student') || checkUserIncludes(user.type,'teacher')">
              <StudentNotes :user="user"></StudentNotes>
            </v-tab-item>

            <v-tab-item title="Parents meet" :value="12" v-if="isAccessible('admin,coordinator') && checkUserIncludes(user.type,'student')">
              <ParentsMeet :user="user" @refresh="getUser"></ParentsMeet>
            </v-tab-item>

            <v-tab-item title="Device" :value="13">
              <general-attendance :user="user" @refresh="getUser"></general-attendance>
            </v-tab-item>

            <v-tab-item title="Online" :value="14">
              <div style="margin-top: 18px;">
                <v-tabs class="justify-start" v-model="password_setting_tab">
                  <v-tab class="justify-start"  v-if="isAccessible('admin,coordinator')">
                      <span class="navi-icon ">
                                          <i class="fa fa-globe"></i>
                                        </span>
                    <span class="navi-text">Online portal</span>
                  </v-tab>
                  <v-tab class="justify-start"  v-if="isAccessible('admin,coordinator')">
                     <span class="navi-icon ">
                                      <i class="fa fa-shield-alt"></i>
                                    </span>
                    <span class="navi-text">Password</span>
                  </v-tab>
                </v-tabs>
              </div>
              <v-tabs-items v-model="password_setting_tab">
                <v-tab-item  v-if="isAccessible('admin,coordinator')">
                  <OnlineInformation :user="user"></OnlineInformation>
                </v-tab-item>
                <v-tab-item v-if="isAccessible('admin,coordinator')">
                  <ChangePassword :user="user"></ChangePassword>
                </v-tab-item>
              </v-tabs-items>
            </v-tab-item>

            <v-tab-item title="User settings" :value="15" v-if="!checkUserIncludes(user.type,'student')">
              <user-settings v-if="user.id" :user="user" :userId="user.id" :userSetting="user.setting?user.setting:{}"
                             @refresh="getUser"></user-settings>
            </v-tab-item>

            <v-tab-item title="Faculty" :value="16"
                   v-if="(isAccessible('admin') || checkUserIncludes(user.type,'teacher')
                || checkUserIncludes(user.type,'coordinator') || checkUserIncludes(user.type,'department-manager')) && !checkUserIncludes(user.type,'student') ">
              <TeacherFaculty ref="teacher-faculty" v-if="user.id"  :user="user"></TeacherFaculty>
            </v-tab-item>

            <v-tab-item title="Zoom Account" :value="17" v-if="isAccessible('admin') && checkUserIncludes(user.type,'teacher')">
              <ZoomAccountSetting :user="user" @refresh_user="getUser"></ZoomAccountSetting>
            </v-tab-item>

            <v-tab-item title="Fee" :value="18" v-if="isAccessible('admin,finance') && checkUserIncludes(user.type,'student')">
              <div style="margin-top: 18px;">
                <v-tabs class="justify-start"  v-model="fees_tab">
                  <v-tab class="justify-start" v-if="isAccessible('admin,finance') && checkUserIncludes(user.type,'student')">
                  <span class="navi-icon mr-1">
                    <i class="fas fa-dollar"></i>
                  </span>
                    <span class="navi-text">Fees</span>
                  </v-tab>
                  <v-tab class="justify-start" v-if="isAccessible('admin') && checkUserIncludes(user.type,'student')">
                    <span class="navi-icon mr-1">
                      <i class="fa fa-plus"></i>
                    </span>
                    <span class="navi-text">Additional fees</span>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="fees_tab">
                  <v-tab-item v-if="checkUserIncludes(user.type,'student')">
                    <fee-component v-if="user && user.id" :user="user"></fee-component>
                  </v-tab-item>

                  <v-tab-item  v-if="checkUserIncludes(user.type,'student')">
                    <additional-fee :user="user"></additional-fee>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-tab-item>

            <v-tab-item title="Scholarship" :value="19" v-if="isAccessible('admin,finance') && checkUserIncludes(user.type,'student')">
              <scholarship v-if="user && user.id" :user="user"></scholarship>
            </v-tab-item>

            <v-tab-item title="Scholarship" :value="20" v-if="isAccessible('admin,finance') && checkUserIncludes(user.type,'student')">
              <student-statement v-if="user && user.id" :user="user"></student-statement>
            </v-tab-item>

            <v-tab-item title="Library setting" :value="21"
                   v-if="isAccessible('admin,finance,coordinator') && checkUserIncludes(user.type,'student') || checkUserIncludes(user.type,'teacher')">
              <div style="margin-top: 18px;">
                <v-tabs class="justify-start" v-model="library_setting_tab">
                  <v-tab class="justify-start">
                      <span class="navi-icon">
                                  <i class="fas fas fa-cogs"></i>
                                </span>
                    <span class="navi-text">
                     Library setting
                   </span>
                  </v-tab>
                  <v-tab>
                   <span class="navi-icon mr-1">
                                  <i class="fas fas fa-history"></i>
                                </span>
                    <span class="navi-text">Library history</span>
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="library_setting_tab">
                  <v-tab-item>
                    <library-setting :user="user" @refresh="getUser"></library-setting>
                  </v-tab-item>
                  <v-tab-item>
                    <library-history :user="user" @refresh="getUser"></library-history>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-tab-item>

            <v-tab-item title="Role setting" :value="22"
                   v-if="isAccessible('admin') && !checkUserIncludes(user.type,'student')">
              <role-setting v-if="user.id" ref="role-setting" :user="user" @refresh="getUser"></role-setting>
            </v-tab-item>

            <v-tab-item title="Hostel" :value="23" v-if="isAccessible('admin,coordinator') && checkUserIncludes(user.type,'student')">
              <hostel v-if="user.id" :user="user"></hostel>
            </v-tab-item>

            <v-tab-item tile="Character certificate" :value="24" v-if="isAccessible('admin,coordinator') && checkUserIncludes(user.type,'student')  && user.id && user.setting && user.setting.is_course_complete">
              <character-certificate :user="user" v-if="user.id && user.setting && user.setting.is_course_complete"></character-certificate>
            </v-tab-item>

            <v-tab-item title="Transport allocation" :value="25" v-if="isAccessible('admin,coordinator') && checkUserIncludes(user.type,'student') && user.id && user.transport_allocation_count > 0">
              <transport-allocation :user="user"></transport-allocation>
            </v-tab-item>

            <v-tab-item title="Information log" :value="26"
                   v-if="isAccessible('admin,coordinator')">
              <information-log v-if="user.id"></information-log>
            </v-tab-item>

            <v-tab-item tile="Activity" :value="27" v-if="isAccessible('admin,coordinator') && checkUserIncludes(user.type,'student')">
              <student-paper-issue-activity :user="user"></student-paper-issue-activity>
            </v-tab-item>

          </v-tabs-items>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import StudentGeneralInformation from "@/view/pages/student/profile/StudentGeneralInformation";
import OnlineInformation from "@/view/pages/student/profile/OnlineInformation";
import ChangePassword from "@/view/pages/student/profile/ChangePassword";
import Settings from "@/view/pages/student/profile/Settings";
import ParentalInformation from "@/view/pages/student/profile/parents/ParentalInformation.vue";
import ParentsMeet from "@/view/pages/student/profile/parents-meet/ParentsMeet";
import AddressInformation from "@/view/pages/student/profile/AddressInformation";
import StudentNotes from "@/view/pages/student/profile/notes/Index";
import StudentDocuments from "@/view/pages/student/profile/Documents";
import UserService from "@/core/services/user/UserService";
import JobPositionService from "@/core/services/job-position/JobPositionService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import LevelService from "@/core/services/level/LevelService";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import AdditionalFee from "@/view/pages/student/profile/additional-fees/Index"
import Leave from "@/view/pages/student/profile/leave/Index"
import UserSettings from "./profile/UserSettings";
import Grades from "./profile/Grades";
import AcademicSettings from "./profile/AcademicSettings";
import TransferSettings from "./profile/TransferSettings";
import ConfigService from "@/core/services/config/ConfigService";
import TeacherFaculty from "./profile/TeacherFaculty";
import FeeComponent from "./profile/fee/Index";
import Scholarship from "./profile/scholarship/Index";
import ZoomAccountSetting from "./profile/ZoomAccountSetting";
import LibrarySetting from "@/view/pages/student/profile/library/LibrarySetting";
import LibraryHistory from "@/view/pages/student/profile/library/LibraryHistory.vue";
import RoleSetting from "@/view/pages/student/profile/role/RoleSetting";
import InformationLog from "@/view/pages/student/profile/information-log/InformationLog";
import Exam from "@/view/pages/student/profile/exam/Index.vue";
import AcademicProgress from "@/view/pages/student/profile/academic-progress/AcademicProgress.vue";
import Hostel from "@/view/pages/student/profile/hostel/Index.vue";
import CharacterCertificate from "@/view/pages/student/profile/certificate/CharacterCertificate.vue";
import StudentPaperIssueActivity from "@/view/pages/student/profile/activity/StudentPaperIssueActivity.vue";
import TransportAllocation from "@/view/pages/student/profile/transport-allocation/TransportAllocation.vue";
import GeneralAttendance from "@/view/pages/general-attendance/Index";
import HealthRecords from "./profile/health-record/HealthRecords"
import Dashboard from "./profile/Dashboard";
import StudentStatement from "@/view/pages/student/profile/statement/StudentStatement.vue";

const userService = new UserService();
const positionService = new JobPositionService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService()
const enrollmentService = new UserEnrollmentService();


const configService = new ConfigService();
export default {
  name: "student-summary-page",
  components: {
    StudentStatement,
    GeneralAttendance,
    InformationLog,
    AddressInformation,
    // KTProfileOverview,
    StudentGeneralInformation,
    OnlineInformation,
    ChangePassword,
    Settings,
    ParentalInformation,
    ParentsMeet,
    AcademicSettings,
    StudentNotes,
    AdditionalFee,
    UserSettings,
    TeacherFaculty,
    StudentDocuments,
    Grades,
    ZoomAccountSetting,
    TransferSettings,
    Leave,
    FeeComponent,
    LibrarySetting,
    LibraryHistory,
    RoleSetting,
    Exam,
    Hostel,
    CharacterCertificate,
    StudentPaperIssueActivity,
    TransportAllocation,
    Scholarship,
    AcademicProgress,
    HealthRecords,
    Dashboard
  },
  data() {
    return {
      tab: null,
      items: [
        'web', 'shopping', 'videos', 'images', 'news',
      ],
      text: '',
      tabIndex: 0,
      isBusy: false,
      studentSetting: {
        academic_year: null,
        current_semester_title: null,
        program_title: null,
        symbol_no: null,
        board_registration_number: null,
        midas_id: null,
        payment_frequency: null,
        admission_date: null,
        on_hold: null,
        is_course_complete: null,
        completion_date: null,
      },
      user: {
        title: null,
        full_name: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        phone: null,
        description: null,
        father: null,
        mother: null,
        guardian: null,
        permanent_address: null,
        temporary_address: null,
        type: 'student',
        is_email_verified: null,
        is_active: null,
        dob: null,
        notify_other: 0,
        notify_parent: 0,
        notification_other_email: [],
      },
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      library_setting_tab:null,
      password_setting_tab:null,
      fees_tab:null,
      setting_tab:null,
      leaveRequestCount: null,
      studentHealthRecordCount: null,
      userDocumentCount: null,
      userAddressCount: null,
      parentMeetsCount: null,
      notesCount: null,
      userParentsCount: null,
      scholarshipCount: null,
      studentGradesCount: null
    };
  },
  mounted() {
    this.id ? this.getUser() : "";
    this.getStatCounts();
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : false;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    routeTab() {
      return {
        title: `${this.user.full_name}`,
        tips: `Profile update for ${this.user.full_name}`
      }
    }
  },

  methods: {
    /**
     * Set current active on click
     * @param event
     */
    getStatCounts(){
      userService
      .getStatCounts(this.id)
      .then((res) => {
        this.leaveRequestCount = res.data.leaveRequestCount ? res.data.leaveRequestCount : '0';
        this.studentHealthRecordCount = res.data.studentHealthRecordCount ? res.data.studentHealthRecordCount : '0';
        this.userDocumentCount = res.data.userDocumentCount ? res.data.userDocumentCount : '0';
        this.userAddressCount = res.data.userAddressCount ? res.data.userAddressCount : '0';
        this.parentMeetsCount = res.data.parentMeetsCount ? res.data.parentMeetsCount : '0';
        this.notesCount = res.data.notesCount ? res.data.notesCount : '0';
        this.userParentsCount = res.data.userParentsCount ? res.data.userParentsCount : '0';
        this.studentGradesCount = res.data.studentGradesCount ? res.data.studentGradesCount : '0';
        this.scholarshipCount = res.data.scholarshipCount ? res.data.scholarshipCount : '0';
      })
      .catch((err) => {

      })
    },
    getUser() {
      // this.user = {}
      userService.show(this.id).then(response => {
        this.user = response.data.user;
        this.user.is_active ? (this.user.is_active = true) : false;
        this.user.is_email_verified
            ? (this.user.is_email_verified = true)
            : false;
        this.studentSetting = this.user.setting ? this.user.setting : {};
        // this.$refs["grades"].getUser(this.user.id);

      });
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    isAccessible(roles) {
      let flag = [];
      let type = this.currentUser.type;
      let userRoles = configService.getRoles(type);
      if (roles != null && roles != undefined && userRoles != null && userRoles != undefined) {
        roles = roles.split(",");
        for (let t of roles) {
          t = t.trim();
          if (userRoles.includes(t)) {
            flag.push(true);
          } else {
            flag.push(false);
          }
        }
        if (flag.includes(true)) {
          return true;
        }
      }
      return false;
    },
    checkUserIncludes(type, role) {
      return !!(type && type.length > 0 && type.includes(role));
    },
    getUserImagePath(user){
        if (user.image_path && user.image_path.real) {
            return this.user.image_path.real;
        }
    },
    gotoNotes(){
      this.tab = 11;
    },
    gotoHealthRecord(){
      this.tab = 6;
    },
    gotoParentsMeet(){
      this.tab = 12;
    },
    gotoLeaveRequest(){
      this.tab = 4;
    }
  },
};
</script>
