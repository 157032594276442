<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>
                                    Hostel
                                </h4>
                            </div>
                            <div class="breadcrumb-right">
                                <v-btn 
                                    @click="openDialog()" 
                                    class="mt-4 btn btn-primary  mr-1" 
                                    dark
                                    :disabled="!shouldRenew"
                                >
                                    <i class="fa fa-plus"></i>
                                    {{ hostelResidents.length > 0 ? 'Renew' : 'Add'}} 
                                </v-btn>

                            </div>
                        </div>
                    </div>
                    <div class="card-body ">
                        <div class="">
                            <table class="table">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3 wrap-column">Hostel info</th>
                                        <th class="px-3 wrap-column">Fee info</th>
                                        <th class="px-3 wrap-column">Status</th>
                                        <th class="px-3">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="hostelResidents.length > 0" v-for="(item, i) in hostelResidents" :key="i">
                                        <td class="px-3 wrap-column">
                                            {{ item.hostel_name ? item.hostel_name : '-' }}
                                            <div class="mt-3">
                                                <strong>
                                                    Room: 
                                                </strong> 
                                                {{ item.hostel_room_name ? item.hostel_room_name : '-' }}
                                            </div>
                                        </td>
                                        
                                        <td class="px-3 wrap-column">
                                            <div class="mt-2">
                                                <strong> Fee frequency:</strong> {{ item.fee_frequency_formatted ? item.fee_frequency_formatted : '-' }}                                                
                                            </div>
                                            <div class="mt-2">
                                                <strong> Fee:</strong> {{ item.fee ? item.fee : '-' }}                                                
                                            </div>
                                            <div class="mt-2">
                                                <strong> Payment start date:</strong> {{ item.payment_start_date ? item.formatted_payment_start_date : '-' }}                                                
                                            </div>

                                            <div class="mt-2">
                                                <strong> Has discount:</strong>
                                                <span 
                                                    class="badge text-lg "
                                                    v-bind:class="{ 'badge-success': item.has_discount, 'badge-danger': !item.has_discount }">
                                                    {{ item.has_discount ? 'Yes' : 'No' }}
                                                </span>                                                
                                            </div>
                                            <div class="mt-2" v-if="item.has_discount">
                                                <strong> Discount type:</strong> {{ item.discount_type_formatted }}                                                
                                            </div>
                                            <div class="mt-2" v-if="item.has_discount">
                                                <strong> Discount amount:</strong> {{ item.discount_amount ? item.discount_amount : 'NA' }}                                                
                                            </div>
                                        </td>
                                
                                        <td class="px-3 pt-4 wrap-column">
                                            <span 
                                                class="badge text-lg "
                                                v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }">
                                                {{ item.is_active ? 'Active' : 'Inactive' }}
                                            </span>

                                            <div class="mt-2">
                                                <strong>Dates: </strong>
                                                {{ item.entry_date ? item.entry_date : '' }} to {{ item.exit_date ? item.exit_date : '' }}
                                            </div>
                                        </td>

                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link" v-if="item.is_active"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link" @click="editHostelResident(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text"> Edit </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link" @click="deleteHostelResident(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-delete"></i>
                                                            </span>
                                                            <span class="navi-text"> Delete </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                            <div v-else>
                                                NA
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-show="hostelResidents.length == 0">
                                        <td colspan="7" class="text-center">
                                            <strong>Data not available</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination 
                                class="pull-right mt-7" 
                                v-if="hostelResidents.length > 0" 
                                @input="getHostels"
                                v-model="page" 
                                :total-rows="total" 
                                :per-page="perPage" 
                                first-number
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
                <create-and-update 
                    ref="hostel" 
                    @refresh="getHostels"
                ></create-and-update>
            </div>
        </div>
    </v-app>
</template>

<script>
import CreateAndUpdate from "./CreateAndUpdate";
import HostelResidentService from "@/core/services/hostel/hostel-resident/HostelResidentService";

const hostelResident = new HostelResidentService();

export default {
    props: ['user'],
    components: {
        CreateAndUpdate
    },
    data() {
        return {
            hostelResidents: [],
            page: null,
            perPage: null,
            total: null,
            loading: false,
            shouldRenew: false,
        };
    },
    computed: {
        userId() {
            return this.$route.params.id ? this.$route.params.id : false;
        }
    },
    mounted() {
      this.getHostels();
    },
    methods: {
        openDialog(){
            this.$refs['hostel'].showModal(this.userId);
        },
        getHostels(){
            this.loading = true;
            hostelResident
            .getByStudent(this.userId)
            .then((res) => {
                this.hostelResidents = res.data.data;
                this.page = res.data.meta.current_page;
                this.total = res.data.meta.total;
                this.perPage = res.data.meta.per_page;
                this.loading = false;
                this.checkForHostelRenew();
            })
            .catch((err) => {

            })
        },
        checkForHostelRenew(){
            hostelResident
            .checkForHostelRenew(this.userId)
            .then((res) => {
                this.shouldRenew = res.data.renewStatus;
            })
            .catch((err) => {

            });
        },
        editHostelResident(hostelResidentId){
            this.$refs['hostel'].showModal(this.userId, hostelResidentId);
        },
        deleteHostelResident(hostelResidentId){
            this.$confirm({
                message: `Are you sure you want to delete?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        hostelResident
                        .delete(hostelResidentId)
                        .then(response => {
                            this.$snotify.success('Successfully deleted')
                            this.getHostels();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        },
    }
};
</script>
