<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        {{ `${edit ? 'Update' : "Assign"} scholarship` }}
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="resetForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-autocomplete
                                outlined
                                dense
                                v-model="studentScholarship.scholarship_id"
                                :items="scholarships"
                                item-text="title"
                                item-value="id"
                                :error="$v.studentScholarship.scholarship_id.$error"
                            >
                                <template v-slot:label>
                                    Scholarship <span class="text-danger">*</span>
                                </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.studentScholarship.scholarship_id.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="12">
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    min-width="auto"
                                    offset-y
                                    transition="scale-transition"
                                    v-model="menu"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        append-icon="mdi-calendar"
                                        clearable
                                        dense
                                        outlined
                                        readonly
                                        :error="$v.studentScholarship.granted_date.$error"
                                        v-bind="attrs"
                                        v-model="studentScholarship.granted_date"
                                        v-on="on"
                                    >
                                        <template v-slot:label>
                                            Granted date
                                            <span class="text-danger">*</span>
                                        </template>
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    @input="menu = false"
                                    v-model="studentScholarship.granted_date"
                                ></v-date-picker>
                                </v-menu>
                                <span class="text-danger" v-if="$v.studentScholarship.granted_date.$error">This information is required</span>
                            </v-col>

                        <v-col cols="12">
                            <label>
                                Remarks <span class="text-danger"></span>
                            </label>
                            <ckeditor v-model="studentScholarship.remarks" :config="editorConfig"></ckeditor>
                            <span class="text-danger" v-if="$v.studentScholarship.remarks.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn
                                    depressed
                                    @click="resetForm"
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    depressed
                                    :loading="isBusy"
                                    @click="createOrUpdate"
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import ScholarshipService from "@/core/services/scholarship/ScholarshipService";
import StudentScholarshipService from "@/core/services/scholarship/student-scholarship/StudentScholarshipService";

const scholarship = new ScholarshipService();
const studentScholarship = new StudentScholarshipService();

export default {
    validations: {
        studentScholarship: {
            remarks: {},
            scholarship_id: {required},
            student_id: {required},
            granted_date: {required},
        }
    },
    data() {
        return {
            dialog: false,
            menu: false,
            edit: false,
            isBusy: false,
            editorConfig: {
              versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
            studentScholarship: {
                remarks: '',
                scholarship_id: null,
                student_id: null,
                granted_date: ''
            },
            errors: [],
            scholarships: [],
            userId: null,
            programId: null,
            academicYearId: null,
        };
    },
    methods: {
        showModal(userId = null, programId = null, academicYearId = null) {
            this.userId = userId;
            this.programId = programId;
            this.academicYearId = academicYearId;

            this.getScholarships();
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        },
        createOrUpdate() {
            this.studentScholarship.student_id = this.userId;

            this.$v.studentScholarship.$touch();
            if (this.$v.studentScholarship.$error) {
                setTimeout(() => {
                    this.$v.studentScholarship.$reset();
                }, 3000);
            } else {
                this.assignScholarship(this.scholarship);
            }
        },
        assignScholarship() {
            this.isBusy = true;
            studentScholarship
            .store(this.studentScholarship)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.$emit("refresh");
                this.$snotify.success("Scholarship assigned.");
            })
            .catch(error => {
                this.errors = error.errors;
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.studentScholarship.$reset();
            this.edit = false;
            this.studentScholarship = {
                remarks: '',
                scholarship_id: null,
                student_id: null,
                granted_date: ''
            };
            this.hideModal();
            // this.$emit("refresh");
        },
        getScholarships(){
            scholarship
            .getByProgramAndYear(this.programId, this.academicYearId)
            .then((res) => {
                this.scholarships = res.data.scholarships;
            })
            .catch(err => {

            })
            .finally(() => {

            });
        },
    }
}
</script>
