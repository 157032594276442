<template>
    <v-app>

        <div class="card card-custom">
            <!--begin::Header-->
            <div class="card-header py-5 px-5">
                <div class="card-title align-items-start flex-column">
                    <h3 class="card-label font-weight-bolder text-dark">Address</h3>

                </div>
                <div class="card-toolbar">
<!--                    <button :disabled="filterTemperoryAddress.length>0 && filterPermanentAddress.length>0"-->
<!--                            @click="addAddress()"-->
<!--                            class=" mr-5" type="reset">Add address-->
<!--                    </button>-->
                  <v-btn
                      @click="show_form = false"
                      class="btn btn-standard text-white mr-2"
                      depressed
                  >
                    Cancel
                  </v-btn>
                    <v-btn
                            :loading="isBusy"
                            @click="createOrUpdateAddress()"
                            class="btn btn-primary text-white mr-2"
                            depressed
                    >
                        Save changes
                    </v-btn>

                    <!--                    <button type="reset" class="btn btn-secondary">Cancel</button>-->
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Form-->
            <div class="container">

                <div class="row" v-if="!show_form">
                    <div :key="index" class="m-5 col-12" v-for="(addr,index) of addresses">

                      <div class="row">
                        <div class="col-lg-4">
                          <span class="badge badge-primary" v-if="addr.type">{{ addr.type[0].toUpperCase() + addr.type.slice(1) }} Address</span>
                        </div>
                        <!--begin::Col-->
                        <div class="col-lg-8">
                          <a @click="edit(addr)"
                             class="fas fa-edit ml-2"
                             href="#"></a>
                          <a @click="deleteAddress(addr.id)"
                             class="text-danger fas fa-trash ml-2"
                             href="#"></a>
                        </div>
                        <!--end::Col-->
                      </div>

                      <div class="row">

                        <label class="col-lg-4">Address Line 1</label>
                        <!--begin::Col-->
                        <div class="col-lg-8">
                            <span class="font-information">{{ addr.address_line_1 }}</span>
                        </div>
                        <!--end::Col-->
                      </div>

                      <div class="row">
                        <!--begin::Label-->
                        <label class="col-lg-4">Area</label>
                        <!--end::Label-->

                        <!--begin::Col-->
                        <div class="col-lg-8">
                          <span class="font-information">
                              {{ addr.area }}
                          </span>
                        </div>
                        <!--end::Col-->
                      </div>

                      <div class="row">
                        <!--begin::Label-->
                        <label class="col-lg-4 ">Ward</label>
                        <!--end::Label-->

                        <!--begin::Col-->
                        <div class="col-lg-8">
                          <span class="font-information">
                              {{ addr.ward }}
                          </span>
                        </div>
                        <!--end::Col-->
                      </div>

                      <div class="row">
                        <!--begin::Label-->
                        <label class="col-lg-4 ">City</label>
                        <!--end::Label-->

                        <!--begin::Col-->
                        <div class="col-lg-8">
                          <span class="font-information">
                              {{ addr.city }}
                          </span>
                        </div>
                        <!--end::Col-->
                      </div>

                      <div class="row">
                        <!--begin::Label-->
                        <label class="col-lg-4 ">District</label>
                        <!--end::Label-->

                        <!--begin::Col-->
                        <div class="col-lg-8">
                          <span class="font-information">
                              {{ addr.district }}
                          </span>
                        </div>
                        <!--end::Col-->
                      </div>

                      <div class="row">
                        <!--begin::Label-->
                        <label class="col-lg-4 ">State</label>
                        <!--end::Label-->

                        <!--begin::Col-->
                        <div class="col-lg-8">
                          <span class="font-information">
                              {{ addr.state }}
                          </span>
                        </div>
                        <!--end::Col-->
                      </div>

                    </div>

                </div>
                <div v-if="show_form">
                    <div class="row mt-10 mx-3">
                        <div class="col-12 ">
                            <div class="row" v-if="permanent_address_available">

                                <div class="col-12">
                                    <div class="form-group">
                                        <v-select
                                                :items="types"
                                                dense item-text="text" item-value="value"
                                                name
                                                outlined
                                                v-model="address.type"
                                        >
                                        </v-select>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <v-select
                                                :items="['Nepal','Other']"
                                                dense
                                                label="Select location"
                                                name
                                                outlined
                                                v-model="address.country_type"
                                        >
                                        </v-select>
                                    </div>
                                </div>

                                <div class="col-md-6" v-if="address.country_type=='Other'">
                                    <v-text-field dense label="Country name" outlined v-model="address.country">
                                    </v-text-field>
                                </div>

                                <div class="col-md-6">
                                    <v-select
                                            :items="provinces"
                                            @change="getDistricts('permanent')"
                                            class="form-control"
                                            dense
                                            item-text="name"
                                            label="Province"
                                            outlined
                                            return-object
                                            v-if="address.country_type=='Nepal'"
                                            v-model="address.state"
                                    >
                                    </v-select>

                                    <v-text-field dense label="Province" outlined
                                                  v-else v-model="address.state">

                                    </v-text-field>
                                    <span class="form-text text-danger"
                                          v-if="$v.address.state.$error">Please select province.</span>
                                </div>

                                <div class="col-md-6">
                                    <v-select
                                            :items="districts"
                                            @change="getMunicipalities('permanent')"
                                            class="form-control"
                                            dense
                                            item-text="name"
                                            label="District"
                                            outlined
                                            return-object
                                            v-if="address.country_type=='Nepal'"
                                            v-model="address.district"
                                    >

                                    </v-select>
                                    <v-text-field dense label="District" outlined
                                                  v-else v-model="address.district">

                                    </v-text-field>
                                    <span
                                            class="form-text text-danger"
                                            v-if="$v.address.district.$error"
                                    >Please select district.</span>
                                </div>

                                <div class="col-md-6">
                                    <v-select
                                            :items="municipalities"
                                            class="form-control"
                                            dense
                                            :loading="cityLoading"
                                            item-text="name"
                                            label="Local level"
                                            outlined
                                            return-object
                                            v-if="address.country_type=='Nepal'"
                                            v-model="address.city"
                                    >

                                    </v-select>
                                    <v-text-field dense label="Local Level" outlined
                                                  v-else v-model="address.city">
                                    </v-text-field>
                                    <span
                                            class="form-text text-danger"
                                            v-if="$v.address.city.$error"
                                    >Please select local level.</span>
                                </div>

                                <div class="col-md-6">
                                    <v-select
                                            :items="wards"
                                            class="form-control"
                                            dense
                                            item-text="name"
                                            label="Ward"
                                            outlined
                                            return-object
                                            v-if="address.country_type=='Nepal'"
                                            v-model="address.ward"
                                    >

                                    </v-select>
                                    <v-text-field dense label="Ward" outlined
                                                  v-else v-model="address.ward">
                                    </v-text-field>
                                    <span
                                            class="form-text text-danger"
                                            v-if="$v.address.ward.$error"
                                    >Please select your ward.</span>
                                </div>

                                <div class="col-md-6">
                                    <v-text-field
                                            class="form-control"
                                            dense
                                            label="Area"
                                            outlined
                                            v-model="address.area">

                                    </v-text-field>


                                </div>

                                <div class="col-md-6">
                                    <v-text-field
                                            class="form-control"
                                            dense
                                            label="Address line 1"
                                            outlined
                                            v-model="address.address_line_1">

                                    </v-text-field>


                                </div>

                                <div class="col-12" v-if="!temporary_address.id">
                                    <v-checkbox
                                            label="Add temporary address"
                                            @change="resetTemporaryAddress"
                                            v-model="differentAddress"
                                    >
                                    </v-checkbox>
                                </div>
                            </div>

                            <div class="row" v-if="differentAddress && temporary_address_available">

                                <div class="col-xl-12">
                                        <v-select
                                                :items="[
                                                     {text:'Temporary',value:'temporary'}
                                                ]"
                                                dense item-value="value" item-text="text"
                                                name
                                                outlined
                                                v-model="temporary_address.type"
                                        >
                                        </v-select>
                                </div>

                                <div class="col-md-6">
                                  <v-select
                                      :items="temporary_provinces"
                                      @change="getDistricts('temporary')"
                                      class="form-control"
                                      dense
                                      item-text="name"
                                      label="Province"
                                      outlined
                                      return-object
                                      v-if="address.country_type=='Nepal'"
                                      v-model="temporary_address.state"
                                  >
                                  </v-select>

                                  <v-text-field dense label="Province" outlined
                                                v-else v-model="temporary_address.state">

                                  </v-text-field>
                                  <span class="form-text text-danger"
                                        v-if="$v.temporary_address.state.$error">Please select province.</span>
                                </div>
                                <div class="col-md-6">
                                  <v-select
                                      :items="temporary_districts"
                                      @change="getMunicipalities('temporary')"
                                      class="form-control"
                                      dense
                                      item-text="name"
                                      label="District"
                                      outlined
                                      return-object
                                      v-if="address.country_type=='Nepal'"
                                      v-model="temporary_address.district"
                                  >

                                  </v-select>
                                  <v-text-field dense label="District" outlined
                                                v-else v-model="temporary_address.district">

                                  </v-text-field>
                                  <span
                                      class="form-text text-danger"
                                      v-if="$v.temporary_address.district.$error"
                                  >Please select district.</span>
                                </div>
                                <div class="col-md-6">
                                  <v-select
                                      :items="temporary_municipalities"
                                      class="form-control"
                                      dense
                                      item-text="name"
                                      label="Local level"
                                      outlined
                                      :loading="tempCityLoading"
                                      return-object
                                      v-if="address.country_type=='Nepal'"
                                      v-model="temporary_address.city"
                                  >

                                  </v-select>
                                  <v-text-field dense label="Local Level" outlined
                                                v-else v-model="temporary_address.city">
                                  </v-text-field>
                                  <span
                                      class="form-text text-danger"
                                      v-if="$v.temporary_address.city.$error"
                                  >Please select local level.</span>
                                </div>

                                <div class="col-xl-6">
                                    <div class="form-group">
                                        <v-select
                                                :items="wards"
                                                class="form-control"
                                                dense
                                                item-text="name"
                                                label="Ward"
                                                outlined
                                                return-object
                                                v-model="temporary_address.ward"
                                        >
                                        </v-select>
                                        <span class="form-text text-danger"
                                                v-if="$v.temporary_address.ward.$error"
                                        >Please select your ward.</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <v-text-field
                                            class="form-control"
                                            dense
                                            label="Area"
                                            outlined
                                            v-model="temporary_address.area">

                                    </v-text-field>

                                </div>
                                <div class="col-xl-6">
                                    <div class="form-group">
                                        <v-text-field
                                                class="form-control"
                                                dense
                                                label="Address line 1"
                                                outlined
                                                v-model="temporary_address.address_line_1">

                                        </v-text-field>


                                    </div>
                                </div>
                                <!--          <div class="col-xl-4">-->
                                <!--            <div class="form-group">-->
                                <!--              <label>Address Line 2</label>-->
                                <!--              <input-->
                                <!--                  type="text"-->
                                <!--                  class="form-control form-control-solid form-control-lg"-->
                                <!--                  name="address2"-->
                                <!--                  placeholder="Address Line 2"-->
                                <!--                  value="Address Line 2"-->
                                <!--                  v-model="temporary_address.address_line_2"-->
                                <!--              />-->
                                <!--              &lt;!&ndash; <span class="form-text text-danger">Please enter your Address.</span> &ndash;&gt;-->
                                <!--            </div>-->
                                <!--          </div>-->


                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Form-->
            </div>

        </div>
    </v-app>

</template>
<script>
import {required} from "vuelidate/lib/validators";
import UserIndividualAddressService from "@/core/services/user/student/UserIndividualAddressService";
import NepalAddressService from "@/core/services/address/NepalAddressService";

const individualAddressService = new UserIndividualAddressService();
const nepalAddressService = new NepalAddressService();

export default {
    name: "address-component",
    props: ["user"],
    validations: {
        address: {
            city: {required},
            state: {required},
            district: {required},
            country: {required},
            type: {required},
            ward: {required}
        },
        temporary_address: {
            city: {required},
            state: {required},
            district: {required},
            country: {required},
            type: {required},
            ward: {required}
        }
    },
    data() {
        return {
            isBusy: false,
            addresses: [],
            provinces: [],
            districts: [],
            municipalities: [],
            temporary_provinces: [],
            temporary_districts: [],
            temporary_municipalities: [],
            state: null,
            district: null,
            city: null,
            show_form: true,
            addressDialog: false,
            cityLoading: false,
            tempCityLoading: false,
            sameAddress: false,
            differentAddress: false,
            permanent_address_available: false,
            temporary_address_available: false,
            wards: [
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
                22,
                23,
                24,
                25,
                26,
                27,
                28,
                29,
                30,
                31,
                32
            ],
            address: {
                user_id: this.userId,
                user_type: 'student',
                address_line_1: null,
                // address_line_2: null,
                ward: null,
                city: null,
                area: null,
                district: null,
                state: null,
                country: "Nepal",
                country_type: "Nepal",
                type: "permanent"
            },
            temporary_address: {
                user_id: this.userId,
                user_type: 'student',
                address_line_1: null,
                // address_line_2: null,
                ward: null,
                city: null,
                area: null,
                district: null,
                state: null,
                country: "Nepal",
                type: "temporary"
            },
            types:[
              {text:'Permanent',value:'permanent'}
            ]
        };
    },
    computed: {
        userId() {
            return this.$route.params.id ? this.$route.params.id : false;
        },
        filterPermanentAddress() {
            return this.addresses.filter(address => {
                return address.type == 'permanent'
            })
        },
        filterTemperoryAddress() {
            return this.addresses.filter(address => {
                return address.type == 'temporary'
            })
        },

    },
    mounted() {
        this.getAddresses()
        this.getProvinces();
        this.getProvinces('temporary');
    },
    methods: {

      edit(address) {
        this.show_form = true;
        if(address.type == 'permanent') {
          this.address = address;
          this.address.state = this.provinces.find(o => o.name === address.state);
          this.address.ward = parseInt(address.ward);
          this.permanent_address_available = true;
          this.temporary_address_available = false;
          this.cityLoading = true;
          this.getDistricts();
          setTimeout(() => {
            this.address.district = this.districts.find(o => o.name === address.district);
            this.getMunicipalities();
            this.cityLoading = false;
          }, 2000)

        } else {
          this.temporary_address = address;
          this.temporary_address.state = this.temporary_provinces.find(o => o.name === address.state);
          this.temporary_address.ward = parseInt(address.ward);
          this.permanent_address_available = false;
          this.differentAddress = true;
          this.temporary_address_available = true;
          this.tempCityLoading = true;
          this.getDistricts('temporary');
          setTimeout(() => {
            this.temporary_address.district = this.temporary_districts.find(o => o.name === address.district);
            this.getMunicipalities('temporary');
            this.tempCityLoading = false;
          }, 2000)

        }
        if(address.country == 'Nepal') {
          this.address.country_type = 'Nepal';
        } else {
          this.address.country_type = 'Other';
        }
      },

      getProvinces(type = 'permanent') {
        if (type === 'permanent') {
          nepalAddressService.provinces().then(response => {
            this.provinces = response.data;
          });
        } else {
          nepalAddressService.provinces().then(response => {
            this.temporary_provinces = response.data;
          });
        }
      },

      getDistricts(type = 'permanent') {
          if (type === 'permanent') {
              nepalAddressService.districtsByProvince(this.address.state.id).then(response => {
                  this.districts = response.data;
              });
          } else {
              nepalAddressService.districtsByProvince(this.temporary_address.state.id).then(response => {
                  this.temporary_districts = response.data;
              });
          }

      },

      getMunicipalities(type = 'permanent') {

          if (type === 'permanent') {
              nepalAddressService
                  .municipalitiesByDistrict(this.address.district.id)
                  .then(response => {
                      this.municipalities = response.data;
                  });
          } else {
              nepalAddressService
                  .municipalitiesByDistrict(this.temporary_address.district.id)
                  .then(response => {
                      this.temporary_municipalities = response.data;
                  });
          }

      },

      resetAddress() {
          this.addressDialog = false;
          this.address = {
              user_id: this.userId,
              user_type: 'student',
              address_line_1: null,
              // address_line_2: null,
              ward: null,
              city: null,
              area: null,
              district: null,
              state: null,
              country: "Nepal",
              type: "permanent"
          };
          this.temporary_address = {
            user_id: this.userId,
            user_type: 'student',
            address_line_1: '',
            ward: '',
            city: '',
            area: '',
            district: '',
            state: '',
            country: "Nepal",
            type: "temporary"
          };
      },

      getAddresses() {
          individualAddressService
              .paginate(this.userId)
              .then(response => {
                  this.addresses = response.data.data;
                  this.checkAddress();
                  if (this.addresses.length > 0 && this.addresses.length <= 2) {
                      this.show_form = false;
                  }
              })
              .catch(error => {
                  //console.log(error);
              });
      },

      resetTemporaryAddress() {
        this.temporary_address_available = true;
        this.getProvinces('temporary');
        if(this.temporary_address && this.temporary_address.id) {
          this.deleteAddress(this.temporary_address.id);
        }
        this.temporary_address = {
          user_id: this.userId,
          user_type: 'student',
          address_line_1: '',
          ward: '',
          city: '',
          area: '',
          district: '',
          state: '',
          country: "Nepal",
          type: "temporary"
        };
      },

      checkAddress() {
        if (this.addresses.length > 0) {
            this.addresses.forEach(ele => {
                if (ele.type == "permanent") {
                    this.permanent_address_available = true;
                    this.show_form = false;
                }
                if (ele.type == "temporary") {
                    this.temporary_address_available = true;
                    this.temporary_address.id = ele.id;
                    this.show_form = false;
                }
            });
        } else {
            this.permanent_address_available = true;
            this.show_form = true;
        }
      },

      sameAsPermanent() {
        let data = {
            address_line_1: this.address.address_line_1,
            // address_line_2: this.address.address_line_2,
            city: this.address.city,
            area: this.address.area,
            district: this.address.district,
            state: this.address.state,
            country: this.address.country,
            type: "temporary"
        };
        data.user_id = this.userId;
        data.user_type = 'student';
        individualAddressService
            .store(this.user.id, data)
            .then(response => {
                this.$v.address.$reset();
                this.resetAddress();
                this.$bus.emit('toggleLoader');
                this.getAddresses();
            })
            .catch(() => {
            });
      },

      setAddress() {
        if(this.address.state && this.address.state.name) {
          this.address.state = this.address.state.name;
        }
        if(this.address.district && this.address.district.name) {
          this.address.district = this.address.district.name;
        }
        if(this.address.city && this.address.city.name) {
          this.address.city = this.address.city.name;
        }
        if(this.address.user_id == null) {
          this.address.user_id = this.userId;
        }
      },
      setTemporaryAddress() {
        if(this.temporary_address.state && this.temporary_address.state.name) {
          this.temporary_address.state = this.temporary_address.state.name;
        }
        if(this.temporary_address.district && this.temporary_address.district.name) {
          this.temporary_address.district = this.temporary_address.district.name;
        }
        if(this.temporary_address.city && this.temporary_address.city.name) {
          this.temporary_address.city = this.temporary_address.city.name;
        }
        if(this.temporary_address.user_id == null) {
          this.temporary_address.user_id = this.userId;
        }
      },

      createOrUpdateAddress() {
          if(this.differentAddress) {
            this.createOrUpdatePermanentAddress();
            this.createOrUpdateTemporaryAddress();
          } else {
            this.createOrUpdatePermanentAddress();
          }
      },

      createOrUpdatePermanentAddress() {
        this.$v.address.$touch();
        if (this.$v.address.$error) {
          setTimeout(() => {
            this.$v.address.$reset();
          }, 3000);
        } else {
          this.isBusy = true;
          this.setAddress();
          if (this.address.id) {
            this.$bus.emit('toggleLoader');
            this.address.user_type = 'student';
            individualAddressService
                .update(this.user.id, this.address.id, this.address)
                .then(response => {
                  this.$v.address.$reset();
                  this.resetAddress();
                  this.$bus.emit('toggleLoader');
                  this.isBusy = false;
                  this.getAddresses();
                })
                .catch(() => {
                  this.$bus.emit('toggleLoader');
                });
          }
          else {
            this.$bus.emit('toggleLoader');
            this.address.user_type = 'student';
            individualAddressService
                .store(this.user.id, this.address)
                .then(response => {
                  this.$v.address.$reset();
                  this.resetAddress();
                  this.$bus.emit('toggleLoader');
                  this.isBusy = false;
                  this.getAddresses();
                })
                .catch(() => {
                  this.$bus.emit('toggleLoader');
                });
          }
        }
      },

      createOrUpdateTemporaryAddress() {
        this.$v.temporary_address.$touch();
        if (this.$v.temporary_address.$error) {
          setTimeout(() => {
            this.$v.temporary_address.$reset();
          }, 3000);
        } else {
          this.isBusy = true;
          this.setTemporaryAddress();
          if (this.temporary_address.id) {
            this.$bus.emit('toggleLoader');
            this.address.user_type = 'student';
            individualAddressService
                .update(this.user.id, this.temporary_address.id, this.temporary_address)
                .then(response => {
                  this.$v.address.$reset();
                  this.resetAddress();
                  this.$bus.emit('toggleLoader');
                  this.isBusy = false;
                  this.getAddresses();
                })
                .catch(() => {
                  this.$bus.emit('toggleLoader');
                });
          }
          else {
            this.$bus.emit('toggleLoader');
            this.address.user_type = 'student';
            individualAddressService
                .store(this.user.id, this.temporary_address)
                .then(response => {
                  this.$v.temporary_address.$reset();
                  this.resetAddress();
                  this.$bus.emit('toggleLoader');
                  this.isBusy = false;
                  this.getAddresses();
                })
                .catch(() => {
                  this.$bus.emit('toggleLoader');
                });
          }
        }
      },

      deleteAddress(id) {
          this.$confirm({
              message: `Are you sure you want to delete this item?`,
              button: {
                  no: "No",
                  yes: "Yes"
              },
              /**
               * Callback Function
               * @param {Boolean} confirm
               */
              callback: confirm => {
                  if (confirm) {
                      this.$bus.emit('toggleLoader');

                      individualAddressService
                          .delete(this.userId, id)
                          .then(response => {
                              this.$snotify.success("Information deleted");
                              this.$v.address.$reset();
                              this.resetAddress();
                              this.$bus.emit('toggleLoader');
                              this.getAddresses();
                          })
                          .catch(error => {
                              //console.log(error);
                          });
                  }
              }
          });
      }
    }
};
</script>
