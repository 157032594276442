<template>
  <div>
    <div class="border-graph">
      <v-row>
        <v-col md="9">
            <h4>Exam performance by semester/year</h4>

        </v-col>
        <v-col md="3" class="pt-7 text-right">
            <v-switch @change="changeExamPerformanceTableView"
                      v-model="is_table_view"
                      :label="is_table_view ? 'Table': 'Graph'"
                      color="primary"

                      hide-details>
            </v-switch>
        </v-col>
          <v-row class="p-7">
              <v-col md="6">
                  <v-select  @change="getExamByStudentGradeId" v-model="search.student_grade_id" item-value="id" item-text="grade.title" dense outlined :items="grades" label="Semester/year"></v-select>
              </v-col>
              <v-col md="6">
                  <v-autocomplete @change="changeExamData" v-model="search.exam_id" item-value="id" item-text="title" dense outlined :items="exams" label="Exam"></v-autocomplete>
              </v-col>
          </v-row>
        <v-col md="12" class="mt-2" v-if="!loading && !is_table_view">
            <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
        </v-col>
        <v-col md="12" class="mt-2" v-if="!loading && is_table_view">
          <table class="table">
            <thead>
              <tr class="px-3">
                <td>Subject</td>
                <td>Total marks</td>
                <td>Pass marks</td>
                <td>Total obtained marks</td>
                <td v-if="examType != 'class_test'">Remarks</td>
              </tr>
            </thead>
            <tbody>
              <tr class="px-3" v-show="exam_performance_table_lists.length > 0" v-for="(exam_performance_table_list,index ) in exam_performance_table_lists" :key="index">
                <td>
                  <span class="font-weight-bold">
                    {{exam_performance_table_list.course_title}}
                  </span>
                </td>
                <td>
                  <span class="font-weight-medium">
                    {{exam_performance_table_list.total_marks}}
                  </span>
                </td>
                <td>
                  <span class="font-weight-medium">
                    {{exam_performance_table_list.pass_marks}}
                  </span>
                </td>

                <td>
                  <span class="font-weight-medium text-success" v-if="parseInt(exam_performance_table_list.total_obtained_marks) > parseInt(exam_performance_table_list.pass_marks)">
                    {{exam_performance_table_list.total_obtained_marks}}
                  </span>
                  <span class="font-weight-medium text-danger" v-else>
                    {{exam_performance_table_list.total_obtained_marks}}
                  </span>
                </td>

                <td v-if="examType != 'class_test'">
                 {{exam_performance_table_list.remarks}}
                </td>
              </tr>
              <tr v-show="exam_performance_table_lists.length == 0">
                Date
              </tr>
            </tbody>

          </table>
        </v-col>
      </v-row>

    </div>

  </div>
</template>
<script>
import ExamService from "@/core/services/exam/ExamService";
import StudentSettingService from "@/core/services/user/student/StudentSettingService";
const studentSetting=new StudentSettingService();
const exam=new ExamService();
export default {
  props:['user'],
  data(){
    return{
      exam_performance_table_lists:[],
      is_table_view:false,
      exams:[],
      student_grade:null,
      examType:null,
      grades:[],
      search:{
        student_grade_id:null,
        exam_id:null,
      },
      exam_performance_lists:[],
      loading:false,
      grade:'',
      series: [
   /*     {
          name: 'Total obtained marks',
          data: [42,73,23,80,52,48],
          type: 'column',
        }, {
          name: 'Total Marks',
          type: 'c',

          data: [80,100,100,50,75,100]
        }, {
          name: 'Pass Mark',
          type: 'line',
          data: [16, 32, 24, 32, 24, 16]
        }*/
       ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },

        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        labels: [],
        markers: {
          size: 0
        },
        xaxis: {
          type: 'string'
        },
        yaxis: {
          title: {
            text: 'Points',
          },
          min: 0
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " points";
              }
              return y;

            }
          }
        }
      },

    }
  },
  methods:{
    getGrades() {
      this.search.student_grade_id=null;
      studentSetting
          .getGrades(this.user.id)
          .then(response => {
            this.grades = response.data.data;
            if(this.grades.length > 0){
              let currentGrade =  this.grades.filter(function (grade) {
                return grade.status=='current';
              });
              if(currentGrade.length > 0){
                this.search.student_grade_id=currentGrade[0].id;
              }else{
                this.search.student_grade_id=this.grades[0].id;
              }
              this.getExamByStudentGradeId()

            }
          }).catch((error) => {

      })
      .finally(() => {

      });
    },

    getStudentExamPerformance(){
      this.loading=true;
      exam
          .getExamPerformanceStats(this.user.id,this.search)
          .then(response => {
           let examPerformanceLists=response.data.exam_performance_lists;
            this.examType = response.data.exam_type;

              let series=[];
              let courses_title=[];
              examPerformanceLists.forEach(function (item) {
                series.push(item.total_obtained_mark);
                series.push(item.total_marks);
                series.push(item.pass_marks);
                courses_title=item.courses_title;
              });
              this.series=series;
              this.chartOptions.labels=courses_title;
          })
          .catch(error => {

          })
          .finally(() => {
            this.loading=false;
          });
    },
    getStudentExamPerformanceInTableView(){
      this.exam_performance_table_lists=[];
      this.loading=true;
      exam
          .getExamPerformanceStatsInTableView(this.user.id,this.search)
          .then(response => {
            this.exam_performance_table_lists=response.data.exam_performance_table_lists;
            this.examType = response.data.exam_type;
          })
          .catch(error => {

          })
          .finally(() => {
            this.loading=false;
          });
    },
    getExamByStudentGradeId(){
      if(this.search.student_grade_id){
        exam
            .getExamByStudentGradeId(this.search.student_grade_id,this.user.id,null)
            .then(response => {
              this.exams=response.data.exams;
              if(this.exams.length > 0){
                this.search.exam_id=this.exams[0].id;
              }
              if(this.search.exam_id){
                if(this.is_table_view){
                  this.getStudentExamPerformanceInTableView();
                }else{
                  this.getStudentExamPerformance();
                }

              }

            })
            .catch(error => {

            })
            .finally(() => {

            });
      }
    },
    changeExamData(){
      if(this.is_table_view){
        this.getStudentExamPerformanceInTableView();
      }else{
        this.getStudentExamPerformance();
      }
    },
    changeExamPerformanceTableView(){
      if(this.is_table_view){
        this.getStudentExamPerformanceInTableView();
      }else{
        this.getStudentExamPerformance();
      }
    },
  },
  mounted() {
    this.getGrades();

  }
}
</script>
