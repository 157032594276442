<template>
  <v-app>
    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header py-5 px-5">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">Zoom account for teacher</h3>
        </div>

      </div>
      <!--end::Header-->
      <!--begin::Form-->
      <form class="form">
        <div class="card-body">
          <div class="form-group row" v-if="isAccessible('admin')">

            <div class="col-lg-9 col-xl-6">
              <v-switch
                  @change="toggleZoomAccount()"
                  v-model="isActivated"
                  class="form-control"
                  label="Activate Account">
              </v-switch>

            </div>
          </div>
          <v-skeleton-loader
                  v-if="is_loading"
                  type="article, actions"
          ></v-skeleton-loader>
          <template v-else>

            <v-card v-if="zoom_user">
              <v-card-title>
                <span v-if="zoom_user.status == 'active'">Active Zoom Account</span>
                <span v-else>Pending Email Verification</span>
              </v-card-title>
              <v-card-text>
                <p v-if="zoom_user.status == 'active'">A zoom account under this education organisation exists for this teacher.</p>
                <p v-else>An email confirmation has been sent to teacher to verify their account by Zoom. Once they verify account, zoom account for teacher will be active. Teacher can create their own classroom.</p>
                <b>Zoom ID: </b> {{ zoom_user.id }}<br>
                <b>Status: </b>
                <v-chip x-small class="ma-2 text-uppercase text-sm" :class="zoom_user.status == 'active'?'green text-white':'red text-white'"
                >
                  {{ zoom_user.status }}
                </v-chip>
              </v-card-text>
            </v-card>
            <v-card v-else>
              <v-card-title>
                This teacher does not have a zoom account under this school account. Please create one
              </v-card-title>
              <v-card-text>
                Click on activate switch button above to enable this account. An email will be sent to teacher email address by Zoom to verify and activate.
                Only activated email address will be able to use Online Classroom feature.
              </v-card-text>
            </v-card>
          </template>
        </div>
      </form>
      <!--end::Form-->
    </div>
  </v-app>

</template>

<script>
import UserService from "@/core/services/user/UserService";
import ConfigService from "@/core/services/config/ConfigService";

const userService = new UserService();
const configService = new ConfigService();

export default {
  name: "Zooms-Settings",
  props: ['user'],

  data() {
    return {
      isBusy: false,
      is_loading: false,
      isActivated: false,
      zoom_user: null,
    };
  },

  mounted() {
    this.isActivated = this.user.zoom_account || 0;
    this.checkZoomAccount();
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : false;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
  methods: {

    checkZoomAccount() {
      this.is_loading = true;
      userService.checkZoomAccount(this.id).then(response => {
        this.zoom_user = response.data.data
      }).catch(error => {
      }).finally(() => {
        this.is_loading = false;
      })
    },
    toggleZoomAccount() {
      this.$confirm({
        message: `Confirm change in zoom account ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.is_loading = true;
            userService.toggleZoomAccount(this.id).then(response => {
               if(response.data.msg) {
                 this.isActivated = 0;
                 this.$snotify.error(response.data.msg)
               }

              if(response.data.data) {
                this.isActivated = 1;
                this.zoom_user = response.data.data
                this.$snotify.success("Information updated");
                this.checkZoomAccount();
                this.$emit('refresh_user')
              }
            }).catch(() => {
              this.user.zoom_account = false
              this.isActivated = 0;
              this.$snotify.success("Something went wrong")
            }).finally(() => {
              this.is_loading = false;
            })
          }
        }
      });
    },
    redirectTo() {
      this.$router.push({name: "user"});
    },
    isAccessible(roles) {
      let flag = [];
      let type = this.currentUser.type;
      let userRoles = configService.getRoles(type);
      if(roles!=null && roles!=undefined && userRoles!=null && userRoles!=undefined){
        roles = roles.split(",");
        for (let t of roles) {
          t = t.trim();
          if (userRoles.includes(t)) {
            flag.push(true);
          } else {
            flag.push(false);
          }
        }
        if (flag.includes(true)) {
          return true;
        }
      }
      return false;
    },
  }
};
</script>
<style scoped>
.form-control {
  border: none !important;
}
</style>
