<template>
    <!--begin::Card-->
    <v-app>

    <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-5 px-5">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">
                    Online information
                </h3>

            </div>
<!--            <div class="card-toolbar">-->
<!--&lt;!&ndash;                <button&ndash;&gt;-->
<!--&lt;!&ndash;                        type="reset"&ndash;&gt;-->
<!--&lt;!&ndash;                        class="btn btn-primary text-white mr-2"&ndash;&gt;-->
<!--&lt;!&ndash;                        @click="save()"&ndash;&gt;-->
<!--&lt;!&ndash;                        ref="kt_save_changes"&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                    Save Changes&ndash;&gt;-->
<!--&lt;!&ndash;                </button>&ndash;&gt;-->
<!--&lt;!&ndash;                <button type="reset" class="btn btn-secondary" @click="cancel()">&ndash;&gt;-->
<!--&lt;!&ndash;                    Cancel&ndash;&gt;-->
<!--&lt;!&ndash;                </button>&ndash;&gt;-->
<!--            </div>-->
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form">
            <div class="card-body">
                <!--begin::Heading-->
                <!--        <div class="row">-->
                <!--          <label class="col-xl-3"></label>-->
                <!--          <div class="col-lg-9 col-xl-6">-->
                <!--            <h5 class="font-weight-bold mb-6">Account:</h5>-->
                <!--          </div>-->
                <!--        </div>-->
                <!--begin::Form Group-->
<!--                <div class="form-group row">-->
<!--                    <label class="col-xl-3 col-lg-3 col-form-label">Username</label>-->
<!--                    <div class="col-lg-9 col-xl-6">-->
<!--                        <div class="spinner spinner-sm spinner-success spinner-right">-->
<!--                            <input-->
<!--                                    class="form-control form-control-lg form-control-solid"-->
<!--                                    type="text"-->
<!--                                    ref="username"-->
<!--                            />-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;begin::Form Group&ndash;&gt;-->
<!--                <div class="form-group row">-->
<!--                    <label class="col-xl-3 col-lg-3 col-form-label">Email Address</label>-->
<!--                    <div class="col-lg-9 col-xl-6">-->
<!--                        <div class="input-group input-group-lg input-group-solid">-->
<!--                            <div class="input-group-prepend">-->
<!--                                <span class="input-group-text">-->
<!--                                  <i class="la la-at"></i>-->
<!--                                </span>-->
<!--                            </div>-->
<!--                            <input-->
<!--                                    type="text"-->
<!--                                    class="form-control form-control-lg form-control-solid"-->
<!--                                    ref="email"-->
<!--                                    placeholder="Email"-->
<!--                            />-->
<!--                        </div>-->
<!--                        &lt;!&ndash;            <span class="form-text text-muted"&ndash;&gt;-->
<!--                        &lt;!&ndash;              >Email will not be publicly displayed.&ndash;&gt;-->
<!--                        &lt;!&ndash;              <a href="#" class="kt-link">Learn more</a>.</span&ndash;&gt;-->
<!--                        &lt;!&ndash;            >&ndash;&gt;-->
<!--                    </div>-->
<!--                </div>-->
                <!--begin::Form Group-->
                <!--begin::Form Group-->
<!--                <div class="separator separator-dashed my-5"></div>-->
                <!--begin::Form Group-->
                <!--        <div class="row">-->
                <!--          <label class="col-xl-3"></label>-->
                <!--          <div class="col-lg-9 col-xl-6">-->
                <!--            <h5 class="font-weight-bold mb-6">Security:</h5>-->
                <!--          </div>-->
                <!--        </div>-->
                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label"
                    >Email verified</label
                    >
                    <div class="col-lg-9 col-xl-6">

                        <v-switch v-model="user.is_email_verified"  @change="confirmUser" >
                        </v-switch>
                        <!--                        <p class="form-text text-muted pt-2">-->
                        <!--                            After student log in, they will be asked for verification code to continue.-->
                        <!--                            <a href="#" class="font-weight-bold">Learn more</a>.-->
                        <!--                        </p>-->
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label"
                    >Phone verified</label
                    >
                    <div class="col-lg-9 col-xl-6">

                        <v-switch v-model="user.is_phone_verified"  @change="confirmUser" >
                        </v-switch>
                        <!--                        <p class="form-text text-muted pt-2">-->
                        <!--                            After student log in, they will be asked for verification code to continue.-->
                        <!--                            <a href="#" class="font-weight-bold">Learn more</a>.-->
                        <!--                        </p>-->
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label"
                    >Login verified</label
                    >
                    <div class="col-lg-9 col-xl-6">

                        <v-switch v-model="user.is_login_verified"  @change="confirmUser" >
                        </v-switch>
<!--                        <p class="form-text text-muted pt-2">-->
<!--                            After student log in, they will be asked for verification code to continue.-->
<!--                            <a href="#" class="font-weight-bold">Learn more</a>.-->
<!--                        </p>-->
                    </div>
                </div>
                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label"
                    >Login enabled</label
                    >
                    <div class="col-lg-9 col-xl-6">
                        <v-switch v-model="user.is_active"  @change="deactiveUser" >

                        </v-switch>

                    </div>
                </div>
            </div>
        </form>
        <!--end::Form-->
    </div>
    <!--end::Card-->
    </v-app>

</template>

<script>
    import UserService from "@/core/services/user/UserService";

    const userService = new UserService();
    export default {
        name: "OnlineInformation",
        props: ['user'],
        data() {
            return {
                localUser : this.user,
            };
        },
        // watch: {
        //     user(newValue, oldValue) {
        //         this.localUser = this.user;
        //     }
        // },
        mounted() {
            this.localUser = this.user;
        },
        methods: {
            save() {
                // set spinner to submit button
                const submitButton = this.$refs["kt_save_changes"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                // dummy delay
                setTimeout(() => {
                    // send update request
                    // this.$store.dispatch(UPDATE_ACCOUNT_INFO, {
                    //   username,
                    //   email,
                    //   language,
                    //   time_zone,
                    //   communication,
                    //   verification
                    // });

                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                }, 2000);
            },
            confirmUser() {
                this.$confirm({
                    message: `Confirm changes`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            this.updateStudent();
                        }
                    }
                });
            },
            deactiveUser() {
                this.$confirm({
                    message: `Are you sure you want to disable login for this account?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            this.updateStudent();
                        }
                    }
                });
            },
            updateStudent() {

                this.isBusy = true;
                const {id,is_active,is_login_verified,is_email_verified,is_phone_verified} = this.user;
                const data = {
                    id:id,
                    is_active :is_active,
                    is_login_verified :is_login_verified,
                    is_email_verified :is_email_verified,
                    is_phone_verified :is_phone_verified
                }
                // console.log(data)
                userService
                    .updateUser(this.user.id, data)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Student record updated");
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Error updating record. Please try again later.");
                    });
            },
            cancel() {
                // this.$refs.username.value = this.currentUserAccountInfo.username;
                // this.$refs.email.value = this.currentUserAccountInfo.email;
                // this.$refs.language.value = this.currentUserAccountInfo.language;
                // this.$refs.time_zone.value = this.currentUserAccountInfo.time_zone;
                // this.$refs.email_com.checked = this.currentUserAccountInfo.communication.email;
                // this.$refs.sms_com.checked = this.currentUserAccountInfo.communication.sms;
                // this.$refs.phone_com.checked = this.currentUserAccountInfo.communication.phone;
                // this.$refs.verification.checked = this.currentUserAccountInfo.verification;
            }
        },
        computed: {}
    };
</script>
