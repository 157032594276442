<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="900" scrollable>
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            {{`${edit?'Update':"Add"}`}} {{`${type}`+' information'}}
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field dense outlined label="First name" v-model="user_parent.first_name" :error="$v.user_parent.first_name.$error"></v-text-field>
                <span class="text-danger" v-if="$v.user_parent.first_name.$error">This information is required</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field dense outlined label="Middle name" v-model="user_parent.middle_name"></v-text-field>

              </v-col>
              <v-col cols="12" md="4">
                <v-text-field dense outlined label="Last name" v-model="user_parent.last_name" :error="$v.user_parent.last_name.$error"></v-text-field>
                <span class="text-danger" v-if="$v.user_parent.last_name.$error">This information is required</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field  @input="checkDuplicateEmail" dense outlined label="Email" v-model="user_parent.email" :error="!$v.user_parent.email.email || duplicateEmailStatus=='ERROR'"></v-text-field>
                <span class="text-danger" v-if="user_parent.email && duplicateEmailStatus=='ERROR' && buttonDisabled">Email already taken.</span>
                <div class="text-danger" v-if="!$v.user_parent.email.email">This information is invalid</div>

              </v-col>
              <v-col cols="12" md="6">
                <v-text-field dense outlined label="Profession" v-model="user_parent.profession"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field dense outlined label="Phone" v-model="user_parent.phone" ></v-text-field>

              </v-col>
              <v-col cols="12" md="6">
                <v-text-field dense outlined label="Mobile" v-model="user_parent.mobile" :error="$v.user_parent.mobile.$error">
                </v-text-field>
                <span class="text-danger" v-if="$v.user_parent.mobile.$error">This information is required</span>
              </v-col>

              <v-col cols="12" md="6" v-if="!edit">
                <v-text-field type="password" dense outlined label="Password" v-model="user_parent.new_password" :error="!edit && $v.user_parent.new_password.$error">
                </v-text-field>
                <span class="text-danger" v-if="!edit && $v.user_parent.new_password.$error">This information is required</span>
              </v-col>

              <v-col cols="12" md="6" v-if="!edit">
                <v-text-field type="password" dense outlined label="Confirm password" v-model="user_parent.confirm_password" :error="!edit && !$v.user_parent.confirm_password.sameAsPassword">
                </v-text-field>
                <span class="text-danger" v-if="!edit && !$v.user_parent.confirm_password.sameAsPassword">Password cannot matched.</span>
              </v-col>

              <v-col cols="12" md="6" >
                <v-switch dense outlined label="Email verified" v-model="user_parent.is_email_verified">
                </v-switch>
              </v-col>

              <v-col cols="12" md="6">
                <v-switch dense outlined label="Login verified" v-model="user_parent.is_login_verified">
                </v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              class="cancel-btn"
              x-large
              @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn
              color="btn btn-primary"
              dark :disabled="buttonDisabled"
              x-large
              :loading="loading"
              @click="createOrUpdate()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import {required,email,requiredIf,sameAs,minLength} from "vuelidate/lib/validators";
import StudentParentService from "@/core/services/user/student/StudentParentService";
const studentParent = new StudentParentService();
export default {
  props:['user'],
  validations(){
    return {user_parent : this.rules};
  },
  data(){
    return{
      loading:false,
      dialog:false,
      edit:false,
      type:null,
      user_parent:{
        first_name:null,
        middle_name:null,
        last_name:null,
        email:null,
        phone:null,
        mobile:null,
        profession:null,
        relation:null,
        user_id:null,
        user_type:'student',
        new_password:null,
        confirm_password:null,
      },
      messages:null,
      buttonDisabled:false,
      duplicateEmailStatus:null,
    }
  },
  methods:{
    openDialog(){
      this.dialog=true;
    },
    closeDialog(){
      this.dialog=false;
      this.resetData();
    },
    createParentInformation(type){
      this.edit=false;
      this.type=type;
      this.openDialog();
    },
    editParentInformation(type,item){
      this.user_parent=item;
      this.type=type;
      this.edit=true;
      this.openDialog();
    },
    createOrUpdate(){
      this.user_parent.user_id=this.user.id;
      this.user_parent.relation=this.type;
      this.$v.$touch()
      if (this.$v.$error && this.duplicateEmailStatus=='ERROR') {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (this.edit) {
          this.__update();
        } else {
          this.__create();
        }
      }
    },
    __update(){
      this.loading = true;
      studentParent
          .updateStudentParent(this.user_parent.id,this.user_parent)
          .then(response => {
            this.closeDialog();
            this.$snotify.success("User parent updated");
            this.$emit('refresh');
          })
          .catch(error=>{

          })
          .finally(()=>{
            this.loading = false;
          });
    },
    __create(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        studentParent
            .createStudentParent(this.user_parent)
            .then(response => {
              this.closeDialog();
              this.$snotify.success("User parent added");
              this.$emit('refresh');
            })
            .catch(error => {

            })
            .finally(() => {
              this.loading = false;
            });
      }
    },
    resetData(){
      this.$v.$reset();
      this.type=null;
      this.user_parent={
        first_name:null,
        middle_name:null,
        last_name:null,
        email:null,
        phone:null,
        mobile:null,
        profession:null,
        relation:null,
        user_id:null,
        user_type:'student',
      };
    },
    checkDuplicateEmail(){
      let data={'email':this.user_parent.email};
      if(this.edit){
        data.id=this.user_parent.id;
      }
      studentParent
          .checkDuplicateEmail(data)
          .then(response => {
            this.messages=response.data.message;
            this.duplicateEmailStatus=response.data.status;
            if(this.duplicateEmailStatus=="SUCCESS"){
              this.buttonDisabled=false;
            }else{
              this.buttonDisabled=true;
            }
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
  },
  computed:{
    rules(){
      let rules = {};

      if(! this.edit){
        rules = {
          first_name: {required},
          last_name: {required},
          new_password: {required, minLength: minLength(6)},
          confirm_password: {required, sameAsPassword: sameAs('new_password')},
          email: {email,
            required: requiredIf(function (){
              return !this.user_parent.mobile;
            })
          },
          mobile: {
            required: requiredIf(function (){
              return !this.user_parent.email;
            })
          },


        }
      }
      if(this.edit){
        rules = {
          first_name: {required},
          last_name: {required},
          email: {email,
            required: requiredIf(function (){
              return !this.user_parent.mobile;
            })
          },
          mobile: {
            required: requiredIf(function (){
              return !this.user_parent.email;
            })
          },
        }
      }
      return rules;
    }
  }
}
</script>
