<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Notes
                </h4>
              </div>
              <div class="breadcrumb-right">
                <v-btn @click="openDialog()" class="mt-4 btn btn-primary  mr-1" dark>
                  <i class="fa fa-plus"></i>
                  Add new
                </v-btn>

              </div>
            </div>
          </div>
          <div class="card-body ">
            <v-skeleton-loader type="table-thead" v-if="loading">
              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
            </v-skeleton-loader>
            <div class="" v-if="!loading">
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-3" style="max-width: 350px !important; white-space: pre-wrap;">Title</th>
                    <th class="px-3" style="max-width: 350px !important; white-space: pre-wrap;">Type</th>
                    <th class="px-3 wrap-column">Added by</th>
                    <th class="px-3 wrap-column">Date</th>
                    <th class="px-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="notes.length > 0" v-for="(item, i) in notes" :key="i">
                    <td style="max-width: 350px !important; white-space: pre-wrap;">
                      <a @click="openNoteSummary(item)"
                        class="text-dark-75 text-hover-primary mb-1 font-size-lg">{{ item.title }}</a>
                    </td>
                    <td class="px-3 wrap-column">
                      <span
                        class="text-dark-75  d-block font-size-lg"><strong>{{ item.type }}</strong></span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span
                        class="text-dark-75  d-block font-size-lg"><strong>{{ item.added_by_user.full_name }}</strong></span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="">{{ item.created_at }}</span>
                    </td>
                    <!--                      <td class="text-center">
                         <span class="cursor-pointer ml-3" @click="openDialog(item.id)">
                           <i class="fas fa-edit"></i>
                         </span>
                         <span class="cursor-pointer ml-3" @click="deleteNotes(item.id)">
                           <i class="fas fa-trash"></i>
                         </span>
                      </td>-->
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown" dropright no-caret>
                          <template v-slot:button-content>
                            <a href="#" class="" data-toggle="dropdown">
                              <!--              <span class="svg-icon svg-icon-success svg-icon-2x">-->
                              <!--begin::Svg Icon-->
                              <i class="flaticon-more-1"></i>
                              <!--end::Svg Icon-->
                              <!--              </span>-->
                            </a>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a href="#" class="navi-link" @click="openDialog(item.id)">
                                <span class="navi-icon">
                                  <i class="fa fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a href="#" class="navi-link" @click="deleteNotes(item.id)">
                                <span class="navi-icon">
                                  <i class="fa fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->

                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-show="notes.length == 0">
                    <td colspan="4 " class="text-center">
                      <strong>Data not available</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination class="pull-right mt-7" v-if="notes.length > 0" @input="getNotes" v-model="page"
                :total-rows="total" :per-page="perPage" first-number last-number></b-pagination>
            </div>

          </div>
        </div>
        <create-and-update ref="note" :additionalFee_id="additionalFee_id" @close_dialog="closeDialog"
          @refresh="getNotes"></create-and-update>
        <v-dialog v-model="note_summary_dialog" width="600px">

          <v-card v-if="note_summary">
            <v-toolbar dark>
              <v-card-title class="headline">
                {{ note_summary.title }}
                <hr>
              </v-card-title>

              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeSummaryDialog">
                  <i class="fas fa-times"></i>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <p class="font-weight-medium h6" v-html="note_summary.description"></p>
              <div class="text--secondary">
                <v-row>
                  <v-col md="6">
                    <span class="subtitle-1 font-weight-bold" v-if="note_summary.added_by_user">
                      Added by : {{ note_summary.added_by_user.full_name }}
                    </span>
                  </v-col>
                  <v-col md="6">
                    <span class="subtitle-1 font-weight-bold">
                      Date : {{ note_summary.created_at }}
                    </span>
                  </v-col>
                </v-row>


              </div>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="btn btn-standard cancel-btn" depressed @click="closeSummaryDialog">Close
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </v-app>
</template>

<script>
import NotesService from "@/core/services/user/student/notes/NotesService";
import CreateAndUpdate from "./CreateAndUpdate";

const notesService = new NotesService();

export default {
  name: "notes",
  props: ['user'],
  components: {
    CreateAndUpdate
  },
  data() {
    return {
      notes: [],
      additionalFee_id: null,
      page: null,
      total: null,
      perPage: null,
      loading: true,
      note_summary_dialog: false,
      note_summary: null,
    };
  },
  computed: {
    userId() {
      return this.$route.params.id ? this.$route.params.id : false;
    }
  },
  mounted() {
    this.getNotes();
  },
  methods: {
    getNotes() {
      this.loading = true
      notesService.paginate(this.userId).then(response => {
        this.notes = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      }).finally(() => {
        this.loading = false;
      });
    },
    openDialog(id) {
      this.additionalFee_id = id;
      this.$refs["note"].showModal(id, this.user);
    },
    closeDialog(id) {
      this.additionalFee_id = id;
      this.$refs["note"].hideModal();
      this.getNotes();
      this.additionalFee_id = null;
    },
    deleteNotes(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            notesService.delete(this.userId, id).then(response => {
              this.$snotify.success("Information deleted");
              this.getNotes();
            });
          }
        }
      });
    },
    closeSummaryDialog() {
      this.note_summary_dialog = false;
      this.note_summary = null;
    },
    openNoteSummary(item) {
      this.note_summary = item;
      if (this.note_summary) {
        this.note_summary_dialog = true;
      }


    },
  }
};
</script>
