<template>
  <v-app>
    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header px-5 py-5">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">Settings</h3>
        </div>
        <div class="card-toolbar">
          <v-btn @click="createOrUpdate" depressed class="btn btn-primary text-white mr-2">Save</v-btn>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Form-->
      <form class="form">
        <div class="card-body">
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-left"
            >Secondary email</label
            >
            <div class="col-lg-9 col-xl-6">
              <v-text-field
                  outlined
                  dense
                  class="form-control"
                  v-model="userSetting.secondary_email"
                  label="Secondary Email"
              ></v-text-field>
            </div>
          </div>
          <div class="form-group row" v-if="isAccessible('admin')">
            <label class="col-xl-3 col-lg-3 col-form-label text-left"
            >External ID</label
            >
            <div class="col-lg-9 col-xl-6">
              <v-text-field
                  outlined
                  dense
                  class="form-control"
                  v-model="userSetting.midas_id"
                  label="External ID"
              ></v-text-field>
            </div>
          </div>

<!--          <div class="form-group row">-->
<!--            <label class="col-xl-3 col-lg-3 col-form-label text-left"-->
<!--            >Academic Year</label-->
<!--            >-->
<!--            <div class="col-lg-9 col-xl-6">-->
<!--              <v-select-->
<!--                  outlined-->
<!--                  dense-->
<!--                  class="form-control"-->
<!--                  v-model="userSetting.academic_year_id"-->
<!--                  :items="academicYears"-->
<!--                  label="Class Name"-->
<!--                  item-value="id"-->
<!--                  item-text="year"-->

<!--              ></v-select>-->
<!--            </div>-->
<!--          </div>-->
          <div class="form-group row" v-if="isAccessible('admin')">
            <label class="col-xl-3 col-lg-3 col-form-label text-left">Date of joining</label>

            <div class="col-lg-9 col-xl-6">
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      outlined
                      dense
                      v-model="userSetting.join_date"
                      class="form-control"
                      label="Date of join"
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="userSetting.join_date"
                    no-title
                    scrollable :max="today"
                    @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
              <!--            <span class="form-text text-muted"-->
              <!--              >Email will not be publicly displayed.-->
              <!--              <a href="#" class="kt-link">Learn more</a>.</span-->
              <!--            >-->
            </div>
          </div>
          <div class="form-group row" v-if="isAccessible('admin')">
            <label class="col-xl-3 col-lg-3 col-form-label text-left">Date of birth (AD)</label>

            <div class="col-lg-9 col-xl-6">
              <v-menu
                  ref="menudob"
                  v-model="menudob"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      outlined
                      dense
                      v-model="userSetting.dob"
                      class="form-control"
                      label="Date of birth(AD)"
                      v-bind="attrs"
                      v-on="on"
                      @change="convertAdToBs"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="userSetting.dob"
                    no-title
                    scrollable
                    @input="menudob = false; convertAdToBs()"
                    :max="before_eighteen_year"
                >
                </v-date-picker>
              </v-menu>

            </div>
          </div>
          <div class="form-group row" v-if="isAccessible('admin')">
            <label class="col-xl-3 col-lg-3 col-form-label text-left">Date of birth (BS)</label>

            <div class="col-lg-9 col-xl-6">

              <v-text-field
                  outlined
                  dense
                  @blur="convertBsToAd"
                  v-model="userSetting.dob_bs"
                  class="form-control"
                  label="Date of Birth BS"
              ></v-text-field>
            </div>
          </div>
<!--          <div class="form-group row">-->
<!--            <label class="col-xl-3 col-lg-3 col-form-label text-left"-->
<!--            >Level</label-->
<!--            >-->
<!--            <div class="col-lg-9 col-xl-6">-->
<!--              <v-select-->
<!--                  outlined-->
<!--                  dense-->
<!--                  class="form-control"-->
<!--                  v-model="userSetting.level_id"-->
<!--                  :items="levels"-->
<!--                  label="Levels"-->
<!--                  item-value="id"-->
<!--                  item-text="title"-->
<!--                  @change="getPrograms"-->
<!--              ></v-select>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="form-group row">-->
<!--            <label class="col-xl-3 col-lg-3 col-form-label text-left"-->
<!--            >Program</label-->
<!--            >-->
<!--            <div class="col-lg-9 col-xl-6">-->
<!--              <v-select-->
<!--                  outlined-->
<!--                  dense-->
<!--                  class="form-control"-->
<!--                  v-model="userSetting.program_id"-->
<!--                  :items="programs"-->
<!--                  label="Programs"-->
<!--                  item-value="id"-->
<!--                  item-text="title"-->
<!--                  @change="getGrades"-->
<!--              ></v-select>-->
<!--            </div>-->
<!--          </div>-->


<!--          <div class="form-group row">-->
<!--            <label class="col-xl-3 col-lg-3 col-form-label text-left"-->
<!--            >Semester/Year</label-->
<!--            >-->
<!--            <div class="col-lg-9 col-xl-6">-->
<!--              <v-select-->
<!--                  outlined-->
<!--                  dense-->
<!--                  class="form-control"-->
<!--                  v-model="userSetting.grade_id"-->
<!--                  :items="gradesLevels"-->
<!--                  label="Semester/Year"-->
<!--                  item-value="id"-->
<!--                  item-text="title"-->
<!--                  @change="getAcademicClasses"-->
<!--              ></v-select>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="form-group row">-->
<!--            <label class="col-xl-3 col-lg-3 col-form-label text-left"-->
<!--            >Classroom</label-->
<!--            >-->
<!--            <div class="col-lg-9 col-xl-6">-->
<!--              <v-select-->
<!--                  outlined-->
<!--                  dense-->
<!--                  class="form-control"-->
<!--                  v-model="userSetting.class_id"-->
<!--                  :items="academic_classes"-->
<!--                  label="Class Name"-->
<!--                  item-value="id"-->
<!--                  item-text="title"-->
<!--              ></v-select>-->
<!--            </div>-->
<!--          </div>-->

          <div class="form-group row" v-if="isAccessible('admin')">
            <label class="col-xl-3 col-lg-3 col-form-label text-left"
            >Activate hold</label
            >
            <div class="col-lg-9 col-xl-6">
              <v-switch
                  v-model="userSetting.on_hold"
                  class="form-control"
                  label="Account on Hold">
              </v-switch>

            </div>
          </div>
          <div class="form-group row" v-if="isAccessible('admin')">
            <label class="col-xl-3 col-lg-3 col-form-label text-left"
            >Blood group</label
            >
            <div class="col-lg-9 col-xl-6">
              <v-select :items="blood_groups"
                  outlined dense item-value="value" item-text="text"
                  v-model="userSetting.blood_group"
                  class="form-control"
                  label="Blood Group">
              </v-select>

            </div>
          </div>
          <div class="form-group row" v-if="isAccessible('admin')">
            <label class="col-xl-3 col-lg-3 col-form-label text-left"
            >Job title</label
            >
            <div class="col-lg-9 col-xl-6">
              <v-text-field
                  outlined dense
                  v-model="userSetting.job_title"
                  class="form-control"
                  label="Job Title">
              </v-text-field>

            </div>
          </div>
          <div class="form-group row" v-if="isAccessible('admin')">
            <label class="col-xl-3 col-lg-3 col-form-label text-left"
            >Citizenship no.</label
            >
            <div class="col-lg-9 col-xl-6">
              <v-text-field
                  outlined dense
                  v-model="userSetting.citizenship_no"
                  class="form-control"
                  label="Citizenship No">
              </v-text-field>

            </div>
          </div>
          <div class="form-group row" v-if="isAccessible('admin')">
            <label class="col-xl-3 col-lg-3 col-form-label text-left"
            >ID card address</label
            >
            <div class="col-lg-9 col-xl-6">
              <v-text-field
                  outlined dense
                  v-model="userSetting.id_card_address"
                  class="form-control"
                  label="ID Card Address">
              </v-text-field>

            </div>

          </div>
          <div class="form-group row" v-if="isAccessible('admin')">
            <label class="col-xl-3 col-lg-3 col-form-label text-left"
            >Account type</label
            >
            <div class="col-lg-9 col-xl-6">
                <v-select
                    outlined
                    dense
                    label="Account Type"
                    class="form-control"
                    v-model="user.account_type"
                    :items="account_types"
                    placeholder="Role"
                    item-value="value"
                    item-text="title"
                ></v-select>
            </div>

          </div>

        </div>
      </form>
      <!--end::Form-->
    </div>
  </v-app>

</template>

<script>
import {required} from "vuelidate/lib/validators";
import UserService from "@/core/services/user/UserService";
import JobPositionService from "@/core/services/job-position/JobPositionService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import StudentSettingService from "@/core/services/user/student/StudentSettingService";
import UserSettingService from "@/core/services/user/UserSetting/UserSettingService";


const userSettingService = new UserSettingService();
const studentSettingService = new StudentSettingService();
const userService = new UserService();
const positionService = new JobPositionService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService()
const enrollmentService = new UserEnrollmentService();
import ConfigService from "@/core/services/config/ConfigService";
const configService = new ConfigService();

export default {
  name: "UserSettings",
  props: ["user","userId", 'userSetting'],
  validations() {
    return {
      user: this.rules,
    };
  },
  data() {
    return {
      isBusy: false,
      sizeExceed: false,
      job_types: ["admin", "student", "coordinator", "principle"],
      account_types: [
        {title: "Portal", value: "admin"},
        {title: "Teacher", value: "teacher"},
        {title: "Student", value: "student"},
        {title: "Library", value: "library"}
      ],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      academic_classes: [],
      levels: [],
      levelId: null,
      menudob: false,
      menu2: false,
      menu3: false,
      menu: false,
      date: new Date().toISOString().substr(0, 10),
      blood_groups:[
        {text:'A+',value:'A+'},
        {text:'A-',value:'A-'},
        {text:'B',value:'B'},
        {text:'B-',value:'B-'},
        {text:'O+',value:'O+'},
        {text:'O-',value:'O-'},
        {text:'AB+',value:'AB+'},
        {text:'AB-',value:'AB-'},

      ],
    };
  },

  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : false;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    rules() {
      let rule = {
        title: {required},
        first_name: {required},
        last_name: {required},
        type: {required}
      };
      if (!this.id) {
        rule.email = {required};
        rule.phone = {required};
      }
      return rule;
    },
    today(){
      return this.$moment(new Date).format('YYYY-MM-DD');
    },
    before_eighteen_year(){
      return this.$moment(new Date).subtract(18, "year").format('YYYY-MM-DD');
    }
  },
  methods: {
    isAccessible(roles) {
      let flag = [];
      let type = this.currentUser.type;
      let userRoles = configService.getRoles(type);
      if(roles!=null && roles!=undefined && userRoles!=null && userRoles!=undefined){
        roles = roles.split(",");
        for (let t of roles) {
          t = t.trim();
          if (userRoles.includes(t)) {
            flag.push(true);
          } else {
            flag.push(false);
          }
        }
        if (flag.includes(true)) {
          return true;
        }
      }
      return false;
    },
    getUser(id) {
      userService.show(id).then(response => {
        this.user = response.data.user;
        this.user.is_active ? (this.user.is_active = true) : false;
        this.user.is_email_verified
            ? (this.user.is_email_verified = true)
            : false;
        if (this.user.setting) {
          this.studentSetting = this.user.setting
          this.edit = true;
          this.getPrograms();
          this.getGrades();
          this.getAcademicClasses();
        }
        this.getLevels();
        this.getAcademicYears();

      });
    },
    getSetting() {
      userSettingService.paginate(this.id).then(response => {
        if (response.data.data[0])
          this.userSetting = response.data.data[0];
        if (this.studentSetting) {
          this.edit = true;
          this.getPrograms();
          this.getGrades();
          this.getAcademicClasses();
        }
        this.getLevels();
        this.getAcademicYears();

      });
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;
        if (this.studentSetting?.id && this.userSetting?.level_id) {
          this.getPrograms();
        }
      });
    },
    getPrograms() {
      programService.getAllByLevelId(this.userSetting.level_id).then(response => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academicYears = response.data;

      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.userSetting.program_id).then(response => {
        this.gradesLevels = response.data;

      });
    },
    getAcademicClasses() {
      academicClassService.getByProgramLevel(this.userSetting.academic_year_id, this.userSetting.program_id, this.userSetting.grade_id).then(response => {
        this.academic_classes = response.data;
      });
    },

    convertAdToBs() {
      let data = {
        dob: this.userSetting.dob
      };
      enrollmentService
          .convertAdToBs(data)
          .then(response => {
            this.userSetting.dob_bs = response.data.dob;
          })
          .catch(error => {
          });
    },
    convertBsToAd() {
      let data = {
        dob: this.userSetting.dob_bs
      };
      enrollmentService
          .convertBsToAd(data)
          .then(response => {
            if(response.data.dob_bs) {
              this.userSetting.dob = response.data.dob_bs;
            } else {
              this.userSetting.dob = '';
            }
          })
          .catch(() => {});
    },

    redirectTo() {
      this.$router.push({name: "user"});
    },

    createOrUpdate() {
      this.userSetting.user_id= this.id;
      if (this.userSetting.id) {
        this.updateSetting();
      } else {
        this.saveStudent();
      }
    },
    saveStudent() {
      this.isBusy = true;
      userSettingService
          .store(this.user.id, this.userSetting)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("User setting has been created");
            this.$emit('refresh')
            // this.$router.push({name: "students"});
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
          });
    },

    updateSetting() {
      this.isBusy = true;
      userSettingService
            .update(this.user.id, this.userSetting.id, this.userSetting)
            .then(response => {
              this.isBusy = false;
              this.$snotify.success("User setting has been updated");
            this.updateUser();
              // this.$router.push({name: "students"});
            })
            .catch(error => {
              this.isBusy = false;
              this.$snotify.error("Something Went Wrong");
            });

    },
    updateUser() {
      this.isBusy = true;
      userService
            .update(this.user.id, this.user)
            .then(response => {
              this.isBusy = false;
              this.$emit('refresh')
              // this.$router.push({name: "students"});
            })
            .catch(error => {
              this.isBusy = false;
              this.$snotify.error("Something Went Wrong");
            });

    },


  }
};
</script>
<style scoped>
.form-control {
  border: none !important;
}
</style>
