<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        {{ `${edit ? 'Update' : "Add"} health record` }}
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="resetForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                v-model="studentHealthRecord.title"
                                outlined
                                dense
                                :error="$v.studentHealthRecord.title.$error"
                            >
                                <template v-slot:label>
                                    Title <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.studentHealthRecord.title.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="12">
                            <label>
                                Critical
                            </label>
                            <v-switch v-model="studentHealthRecord.is_critical" :label="studentHealthRecord.is_critical ? 'Yes' : 'No'"></v-switch>
                        </v-col>

                        <v-col cols="12">
                            <label>
                                Description <span class="text-danger"></span>
                            </label>
                            <ckeditor v-model="studentHealthRecord.description" :config="editorConfig"></ckeditor>
                            <span class="text-danger" v-if="$v.studentHealthRecord.description.$error">This information is required</span>
                        </v-col>


                        <v-col cols="12" md="12">
                            <v-select
                                outlined
                                dense
                                :items="types"
                                v-model="studentHealthRecord.type"
                                item-text="name"
                                item-value="value"
                            >
                                <template v-slot:label>
                                    Type <span class="text-danger">*</span>
                                </template>
                            </v-select>
                            <span class="text-danger" v-if="$v.studentHealthRecord.type.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="12" v-if="studentHealthRecord.type == 'incident'">
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="true"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="studentHealthRecord.date_of_incident"
                                    :error="$v.studentHealthRecord.date_of_incident.$error"
                                    readonly dense outlined  prepend-inner-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <template
                                        v-slot:label
                                    >
                                    Date of incident <span class="text-danger">*</span>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="studentHealthRecord.date_of_incident"
                                @input="menu = false"
                            ></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" md="12" v-if="studentHealthRecord.type == 'incident'">
                            <label>
                                Feedback <span class="text-danger">*</span>
                            </label>
                            <ckeditor v-model="studentHealthRecord.feedback" :config="editorConfig1"></ckeditor>
                            <span class="text-danger" v-if="$v.studentHealthRecord.feedback.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn
                                    depressed
                                    @click="resetForm"
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>

                                <v-btn
                                    depressed
                                    :loading="isBusy"
                                    @click="createOrUpdate"
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import StudentHealthRecordService from "@/core/services/user/student/student-health-record/StudentHealthRecordService";

const studentHealthRecord = new StudentHealthRecordService();

export default {
    validations: {
        studentHealthRecord: {
            title: { required },
            description: {},
            type: {required},
            date_of_incident: {
                required:requiredIf(function(nestedModal){
                    return (this.studentHealthRecord.type == 'incident')
                })
            },
            notified_to_parent: {},
            feedback: {
                required:requiredIf(function(nestedModal){
                    return (this.studentHealthRecord.type == 'incident')
                })
            },
        }
    },
    data() {
        return {
            dialog: false,
            edit: false,
            isBusy: false,
            editorConfig: {
              versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
            editorConfig1: {
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
            studentHealthRecord: {
                title: '',
                description: '',
                is_critical: false,
                user_id: null,
                type: '',
                date_of_incident: '',
                notified_to_parent: false,
                feedback: '',
            },
            types: [
                {
                    name: 'Record',
                    value: 'record'
                },
                {
                    name: 'Incident',
                    value: 'incident'
                }
            ],
            menu:false
        };
    },
    methods: {
        showModal(userId, id = null) {
            if (id) {
                this.edit = true;
                this.getStudentHealthRecord(id);
            }else{
                this.studentHealthRecord = {
                    title: '',
                    description: '',
                    is_critical: false,
                };
                this.edit = false;
            }
            this.studentHealthRecord.user_id = userId;
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        },
        getStudentHealthRecord(id) {
            studentHealthRecord
            .show(id)
            .then(response => {
                this.studentHealthRecord = response.data.studentHealthRecord;
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
        createOrUpdate() {
            this.$v.studentHealthRecord.$touch();
            if (this.$v.studentHealthRecord.$error) {
                setTimeout(() => {
                    this.$v.studentHealthRecord.$reset();
                }, 3000);
            } else {
                if (this.edit) {
                    this.updateStudentHealthRecord();
                } else {
                    this.createStudentHealthRecord(this.studentHealthRecord);
                }
            }
        },
        updateStudentHealthRecord() {
            this.isBusy = true;

            studentHealthRecord
            .update(this.studentHealthRecord.id, this.studentHealthRecord)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.$emit('refresh');
                this.resetForm();
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        createStudentHealthRecord() {
            this.isBusy = true;

            studentHealthRecord
            .store(this.studentHealthRecord)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.$emit('refresh');
                this.$snotify.success("Information added");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.studentHealthRecord.$reset();
            this.edit = false;
            this.studentHealthRecord = {
                title: '',
                description: '',
                is_critical: false,
                type: '',
                date_of_incident: '',
                notified_to_parent: false,
                feedback: '',
            };
            this.hideModal();
            this.isBusy = false;
        }
    }
}
</script>
