<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-header px-4 py-4">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            Information logs
          </h3>
        </div>
      </div>
      <div class="card-body">

        <v-skeleton-loader
            type="table-thead"
            v-if="loading"
        >
        </v-skeleton-loader>

        <v-skeleton-loader
            v-if="loading"
            type="table-row-divider@25"
        >
        </v-skeleton-loader>

        <div class="contents__box contents__box--left w-100">
          <div v-if="logs.length > 0">
            <ul>
              <li class="mb-2 py-1" v-for="(item, index) in logs" :key="index">
                <code style="font-size: 14px;">
                  {{item.title}}
                </code>
                <span class="ml-2" style="font-size:14px;" v-if="item.added_by.length > 0">
                  by {{item.added_by[0].first_name}} {{item.added_by[0].last_name}} on
                </span>
                <code style="font-size:14px;">{{item.formatted_log_date}}</code>
                <a @click="showLogDetail(item)" style="font-size:14px;" class="fw-bold" target="_blank">Preview</a>.
              </li>
            </ul>
          </div>
          <b-pagination
              v-if="logs.length > 0"
              class="pull-right mt-7"
              @input="getAllLogs"
              v-model="page"
              :total-rows="total"
              :per-page="perPage"
              first-number
              :disabled="loading"
              last-number
          ></b-pagination>
          <div v-else class="text-center card-body">
            <h5>Data not available</h5>
          </div>

          <LogDetail ref="show-log-detail"></LogDetail>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import LogDetail from "./LogDetail";
import LogService from "@/core/services/log/LogService";

const log = new LogService()
export default {
  name: "InformationLog",
  components: {LogDetail},
  data(){
    return{
      logs : [],
      userId: "",
      total: null,
      perPage: null,
      page: null,
      loading: false,
      menu: false,
      search: {
        title: "",
        date_sent: "",
        table_by: [],
        user_id: '',
        table_id: '',
      }
    }
  },
  mounted() {
    this.search.user_id = this.$route.params.id;
    this.getAllLogs();
  },
  methods: {
    getAllLogs(){
      this.loading = true;
      log
      .paginate(this.search)
      .then((response) => {
        this.page = response.data.logs.current_page;
        this.total = response.data.logs.total;
        this.perPage = response.data.logs.per_page;
        this.logs = response.data.logs.data;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
      });
    },
    showLogDetail(log){
      this.$refs['show-log-detail'].showLogDetail(log);
    }
  }
}
</script>
